import React from "react"
import {DraggableAttributes, useDraggable, useDroppable} from "@dnd-kit/core"
import {SyntheticListenerMap} from "@dnd-kit/core/dist/hooks/utilities"
import {
  autoUpdate,
  FloatingPortal,
  offset,
  safePolygon,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react"
import {twMerge} from "tailwind-merge"

import {combineRef} from "../../utils/ref.ts"
import {useResizeRef} from "../../utils/useResizeRef.ts"
import {TColumnMeta, TColumns, TOrderBy, TypedTableContext} from "./utils/shared.ts"
import {ColumnSizeContext, useColumnX} from "./utils/useColumnSizes.ts"
import {FilterButton} from "./FilterButton.tsx"
import {OrderButton} from "./OrderButton.tsx"
import {PinButton} from "./PinButton.tsx"

export type THeaderCellValueProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
}

export type THeaderCellWrapperProps<
  TCol extends TColumns,
  TRowData extends Record<string, any>,
> = THeaderCellSharedProps<TCol, TRowData> & {
  isDropDisabled?: boolean
  isDragDisabled?: boolean
  onTogglePin: (column: TCol) => void
}

export type THeaderCellProps<TCol extends TColumns, TRowData extends Record<string, any>> = THeaderCellSharedProps<
  TCol,
  TRowData
> & {
  ref?: React.Ref<HTMLElement>
  dropLeftRef?: React.Ref<HTMLElement>
  dropRightRef?: React.Ref<HTMLElement>
  isDraggable?: boolean
  attributes?: DraggableAttributes
  listeners?: SyntheticListenerMap
  isDragOverlay?: boolean
  pinWrapperRef?: React.Ref<HTMLElement>
  pinWrapperProps?: React.HTMLAttributes<HTMLElement>
}

export type THeaderCellSharedProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  onOrder?: () => void
  colIndex: number
  orderDirection: NonNullable<TOrderBy<any>>["direction"] | null
  isPinned: boolean
}

function DefaultHeaderCellValue<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
}: THeaderCellValueProps<TCol, TRowData>): React.ReactNode {
  return columnMeta.column
}

export function HeaderCellWrapper<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  isDropDisabled,
  isDragDisabled,
  onTogglePin,
  isPinned,
  ...restProps
}: THeaderCellWrapperProps<TCol, TRowData>): React.ReactNode {
  const {setNodeRef: droppableNodeLeftRef} = useDroppable({
    id: `${columnMeta.column}-left`,
    disabled: isDropDisabled,
    data: {column: columnMeta.column, position: "left", isPinned},
  })
  const {setNodeRef: droppableNodeRightRef} = useDroppable({
    id: `${columnMeta.column}-right`,
    disabled: isDropDisabled,
    data: {column: columnMeta.column, position: "right", isPinned},
  })
  const {
    setNodeRef: draggableNodeRef,
    attributes,
    listeners,
  } = useDraggable({id: columnMeta.column, disabled: isDragDisabled})

  const {setColumnSize} = ColumnSizeContext.useContext()
  const handleSizeChange = React.useCallback(
    (element: HTMLElement) => {
      setColumnSize(columnMeta.column, calculateElementWidth(element))
    },
    [columnMeta.column, setColumnSize]
  )
  const sizingRef = useResizeRef(handleSizeChange)

  const HeaderRenderer = columnMeta.HeaderCell ?? DefaultHeaderCell

  const [isPinVisible, setIsPinVisible] = React.useState(false)
  const {context, floatingStyles, refs} = useFloating({
    open: isPinVisible,
    onOpenChange: setIsPinVisible,
    placement: "top-end",
    middleware: [offset({mainAxis: -16, crossAxis: 5})],
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {handleClose: safePolygon()})
  const {styles: transitionStyles} = useTransitionStyles(context, {
    duration: {open: 100, close: 75},
    initial: {opacity: 0, transform: "scale(0.95)"},
    open: {opacity: 1, transform: "scale(1)"},
  })

  const {getReferenceProps, getFloatingProps} = useInteractions([hover])

  // This is a bit of a hack. When the column is pinned / unpinned and moves in the table,
  // floating-ui does not register a mouseleave event and doesn't remove the floating element.
  React.useEffect(() => {
    setIsPinVisible(false)
  }, [isPinned])

  return (
    <>
      <HeaderRenderer
        columnMeta={columnMeta}
        {...restProps}
        isPinned={isPinned}
        attributes={attributes}
        listeners={listeners}
        ref={combineRef(draggableNodeRef, sizingRef)}
        dropLeftRef={droppableNodeLeftRef}
        dropRightRef={droppableNodeRightRef}
        isDraggable={!isDragDisabled}
        pinWrapperRef={refs.setReference}
        pinWrapperProps={getReferenceProps()}
      />
      <FloatingPortal>
        {isPinVisible && (
          <div className={"z-100"} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <div style={transitionStyles}>
              <PinButton columnMeta={columnMeta} isPinned={isPinned} onTogglePin={onTogglePin} />
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

export function DefaultHeaderCell<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  onOrder,
  ref,
  dropLeftRef,
  dropRightRef,
  isDraggable,
  listeners,
  attributes,
  isDragOverlay,
  colIndex,
  pinWrapperRef,
  pinWrapperProps,
  orderDirection,
  isPinned,
}: THeaderCellProps<TCol, TRowData>): React.ReactNode {
  const {columnsMeta} = TypedTableContext<TCol, TRowData>().useContext()
  const columnX = useColumnX(columnMeta.column)

  const Value = columnMeta.HeaderCellValue ?? DefaultHeaderCellValue

  const align =
    typeof columnMeta.align === "function" ? columnMeta.align({columnsMeta, columnMeta}) : (columnMeta.align ?? "left")

  return (
    <div
      className={twMerge(
        "flex",
        isDraggable ? "cursor-grab" : "cursor-default",
        isDragOverlay && "cursor-grabbing rounded-sm border border-cr-blue-light cr-shadow",
        "[--z:15]",
        isPinned ? `sticky z-[calc(var(--z)+10)]` : "z-[var(--z)]",
        "sticky top-0 transition-all select-none",
        "bg-cr-white group-[.ghost]:bg-cr-white",
        columnMeta.headerCellClassName
      )}
      style={{
        left: isPinned ? `${columnX}px` : undefined,
        gridRow: 1,
        gridColumn: colIndex == null ? undefined : colIndex + 1,
      }}
      {...attributes}
      {...listeners}
      ref={combineRef(ref)}
    >
      <div className={"pointer-events-none absolute inset-0 grid grid-cols-2"}>
        <div ref={combineRef(dropLeftRef)} />
        <div ref={combineRef(dropRightRef)} />
      </div>
      <div
        className={twMerge(
          "flex grow items-center justify-between gap-2",
          "min-h-10 px-2 py-2.5 md:px-6 md:py-3",
          "text-sm font-bold text-cr-blue-dark"
        )}
        ref={combineRef(pinWrapperRef)}
        {...pinWrapperProps}
      >
        <div
          className={twMerge(
            "grow whitespace-nowrap",
            "flex items-center gap-2 empty:px-0",
            align === "left" && "justify-start",
            align === "center" && "justify-center",
            align === "right" && "justify-end"
          )}
        >
          <Value columnMeta={columnMeta} />
          {!!onOrder && (
            <div className={"shrink-0"}>
              <OrderButton direction={orderDirection} onOrder={onOrder} />
            </div>
          )}
        </div>

        {columnMeta.FilterContent && (
          <div className={"shrink-0"}>
            <FilterButton columnMeta={columnMeta} />
          </div>
        )}
      </div>
    </div>
  )
}

function calculateElementWidth(element: HTMLElement | null) {
  return element?.getBoundingClientRect().width ?? 0
}

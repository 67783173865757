import React from "react"
import {useTranslation} from "react-i18next"
import {PencilSquareIcon, StarIcon as StarIconOutline} from "@heroicons/react/24/outline"
import {StarIcon as StarIconSolid} from "@heroicons/react/24/solid"

import {IconButton} from "../../../../components/Button.tsx"
import {Link} from "../../../../components/Link"
import {Table} from "../../../../components/Table/Table.tsx"
import {TableNoRows} from "../../../../components/Table/TableNoRows.tsx"
import {TableRow} from "../../../../components/Table/TableRow.tsx"
import {getColumnsFromMeta} from "../../../../components/Table/utils/columns.ts"
import {TColumnsMeta} from "../../../../components/Table/utils/shared.ts"
import {useLSTableColumnsState} from "../../../../components/Table/utils/useLSTableColumnsState.tsx"
import {Tooltip} from "../../../../components/Tooltip.tsx"
import {
  useDeprioritizeProspectMutation,
  useDisapproveProspectMutation,
  usePrioritizeProspectMutation,
} from "../../../../queries/prospects"
import requestError from "../../../../services/requestError.tsx"
import {ACompanyUserProspect, AProspectApprovalStatuses} from "../../../../services/types.generated"
import {addHttpToURL} from "../../../../utils"
import {enumTranslKey} from "../../../../utils/i18n"
import {useAutoUpdateRef} from "../../../../utils/ref.ts"
import {RejectContext} from "../../shared/context.ts"
import {RejectModal, TRejectSubmitParams} from "../../shared/RejectModal"
import {TCompanyTableColumn, TTableProps} from "../types"
import {RejectButton} from "./RejectButton"

const tableId = "prospects/waiting-for-approval-table"

export const WaitingForApprovalTable: React.FC<TTableProps> = ({
  data,
  isLoading,
  setOrderBy,
  orderBy,
  refetch,
  assignmentId,
}) => {
  const {t} = useTranslation()

  const {prospects: rejectingProspects, setProspects} = RejectContext.useContext()

  const disapproveProspectMutation = useDisapproveProspectMutation()
  const prioritizeMutation = usePrioritizeProspectMutation()
  const deprioritizeMutation = useDeprioritizeProspectMutation()

  const handleTogglePriority = React.useCallback(
    async (prospect: ACompanyUserProspect) => {
      try {
        await (prospect.prioritized ? deprioritizeMutation : prioritizeMutation).mutateAsync(prospect.id)
        refetch()
      } catch (e) {
        requestError(e)
      }
    },
    [deprioritizeMutation, prioritizeMutation, refetch]
  )
  const handleTogglePriorityRef = useAutoUpdateRef(handleTogglePriority)

  const columnsMeta = React.useMemo<TColumnsMeta<TCompanyTableColumn, ACompanyUserProspect>>(
    () => [
      {
        column: "actions",
        size: "min-content",
        HeaderCellValue: () => t("Prospects_Table_Actions"),
        CellValue: ({row}) => {
          return (
            <div className={"flex items-center gap-4"}>
              <Tooltip
                buttonNode={
                  <IconButton
                    className={"text-cr-black hover:text-cr-blue"}
                    noEdges
                    onClick={() => handleTogglePriorityRef.current(row)}
                  >
                    {row.prioritized ? (
                      <StarIconSolid className={"size-4 text-cr-blue"} />
                    ) : (
                      <StarIconOutline className={"size-4"} />
                    )}
                  </IconButton>
                }
                openDelay={200}
              >
                <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                  {row.prioritized ? t("Prospects_Table_RemovePriorityMark") : t("Prospects_Table_MarkAsPriority")}
                </div>
              </Tooltip>
              <RejectButton prospect={row} />
            </div>
          )
        },
      },
      {
        column: "rejected reason",
        size: "min-content",
        HeaderCellValue: () => null,
        CellValue: ({row}) => {
          if (row.approval_status !== AProspectApprovalStatuses.Disapproved) {
            return null
          }

          return (
            <Tooltip
              buttonNode={
                <IconButton className={"text-cr-black hover:text-cr-blue"} noEdges onClick={() => setProspects([row])}>
                  <PencilSquareIcon className={"size-4"} />
                </IconButton>
              }
              openDelay={200}
            >
              <div className={"rounded-lg bg-cr-black px-4 py-2 text-sm text-cr-white"}>
                {t("Prospects_Table_EditRejectionReason")}
              </div>
            </Tooltip>
          )
        },
      },
      {
        column: "company",
        sortFn: true,
        HeaderCellValue: () => t("Prospects_Table_Company"),
        CellValue: ({row}) => <span>{row.organization_name ?? "-"}</span>,
      },
      {
        column: "segment",
        HeaderCellValue: () => t("Prospects_Table_Segment"),
        CellValue: ({row}) => (
          <span>{row.segment?.map(segment => t(enumTranslKey("Segment", segment), segment)).join(", ") ?? "-"}</span>
        ),
      },
      {
        column: "city",
        sortFn: true,
        HeaderCellValue: () => t("Prospects_Table_City"),
        CellValue: ({row}) => <span>{[row.city, row.country?.name].filter(Boolean).join(", ") || "-"}</span>,
      },
      {
        column: "website",
        HeaderCellValue: () => t("Prospects_Table_Website"),
        CellValue: ({row}) =>
          row.website ? (
            <Link to={addHttpToURL(row.website)} variant={"neutral"} target={"_blank"} rel={"noreferrer"}>
              {row.website}
            </Link>
          ) : (
            "-"
          ),
      },
    ],
    [handleTogglePriorityRef, setProspects, t]
  )

  const handleRejectSubmit = React.useCallback(
    (params: TRejectSubmitParams) => {
      return disapproveProspectMutation.mutateAsync({
        assignmentId,
        prospect: {
          id: params.prospects[0].id,
          disapprove_reason: params.disapprove_reason,
          disapprove_reason_text: params.disapprove_reason_text || undefined,
        },
      })
    },
    [assignmentId, disapproveProspectMutation]
  )

  return (
    <>
      {/*
      React hook form is having trouble registering the fields if we control the modal
      with the isOpen prop, resulting in weird behavior, such as requiring two clicks on a radio button
      (rhf sets incorrect refs the first time around).

      This is a workaround I have found to work, but we lose the nice modal opening transition.

      See also: https://github.com/orgs/react-hook-form/discussions/9250#discussioncomment-3922607
      */}
      {rejectingProspects && <RejectModal onSubmit={handleRejectSubmit} />}

      <Table<TCompanyTableColumn, ACompanyUserProspect>
        {...useLSTableColumnsState(tableId, {columnsOrder: getColumnsFromMeta(columnsMeta), pinnedColumn: "company"})}
        data={data}
        loading={isLoading}
        orderBy={orderBy}
        onOrder={setOrderBy}
        columnsMeta={columnsMeta}
      >
        {({data, pinnedColumn}) => (
          <>
            {data.length ? (
              data.map((row, index) => <TableRow key={row.id} row={row} rowIndex={index} pinnedColumn={pinnedColumn} />)
            ) : (
              <TableNoRows>{t("Prospects_NoProspects_TableCaption")}</TableNoRows>
            )}
          </>
        )}
      </Table>
    </>
  )
}

import React from "react"
import {Slide, ToastContainer} from "react-toastify"
import {FloatingPortal} from "@floating-ui/react"
import {Provider as RollbarProvider} from "@rollbar/react"
import {QueryClientProvider} from "@tanstack/react-query"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"

import {NotificationIcon} from "./utils/notify/NotificationCenterToast"
import Router from "./Router"
// eslint-disable-next-line no-restricted-imports
import {queryClient, rollbar, ServicesUserSetup} from "./services"

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RollbarProvider instance={rollbar}>
        <ServicesUserSetup />

        <Router />
      </RollbarProvider>

      <FloatingPortal>
        <ToastContainer
          icon={props => <NotificationIcon type={props.type} />}
          transition={Slide}
          toastClassName={"sora rounded-lg! shadow-lg! ring-1! ring-black/5! text-sm!"}
          progressClassName={"h-0.5!"}
        />
      </FloatingPortal>
      <FloatingPortal>
        <ReactQueryDevtools initialIsOpen={false} />
      </FloatingPortal>
    </QueryClientProvider>
  )
}

export default App

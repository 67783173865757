import React from "react"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import Button from "../../../../../components/Button.tsx"
import {TFilterProperty} from "../../../../../utils/filters.ts"

export function FilterBadge<Property extends Pick<TFilterProperty<unknown>, "activeNum" | "clear" | "isActive">>({
  property,
}: {
  property: Property
}): React.ReactNode {
  const handleClear = (e: React.MouseEvent) => {
    property.clear()
    e.stopPropagation()
  }

  return (
    <Button
      onClickCapture={handleClear}
      className={"flex gap-0.5 py-0.5 pr-1 pl-2 text-xs"}
      shape={"round"}
      wrapperClassName={twMerge(property.isActive ? "opacity-100" : "pointer-events-none opacity-0")}
    >
      {property.activeNum}
      <XMarkIcon className={"size-4"} />
    </Button>
  )
}

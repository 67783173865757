import React from "react"
import {useNavigate} from "react-router"
import isEqual from "fast-deep-equal"

import {TFilterOption} from "../../components/AutocompleteFilter"
import {TOption} from "../../components/formElements/Dropdown/types"
import {TOrderBy} from "../../components/Table/utils/shared.ts"
import {TReportsMetric, useReportsAssignmentsQuery, useReportsSalesCycleQuery} from "../../queries/reports"
import {
  ACompanyUserSalesCycleProspects,
  AProspectLastChangeRanges,
  ASalesCycleFilterStages,
  ASalesCycleProspectStatuses,
} from "../../services/types.generated"
import {createSimpleContext, createStateContext, TStateContext} from "../../utils/context.tsx"
import {useDebouncedValue, useNumParam, useParam} from "../../utils/hooks"
import {buildUrl} from "./utils"

export type TProspectsTableColumn = keyof ACompanyUserSalesCycleProspects | "action"
export type TProspectsTableMetadata = {stageOptions: TStageOption[]}

export type TLastChangeOption = TOption<AProspectLastChangeRanges>

export type TStageOption = TOption<ASalesCycleFilterStages>

export type TStatusOption = TOption<ASalesCycleProspectStatuses>

export type TCurrentSelectionState = {
  salesCycleId: number
  iterationId: number | null
  salespersonId: number | null
  metric: TReportsMetric
}

export const useCurrentSelectionState = (): TStateContext<TCurrentSelectionState> => {
  const navigate = useNavigate()

  const iterationId = useNumParam("iterationId", true) ?? null
  const salespersonId = useNumParam("salespersonId", true) ?? null
  const salesCycleId = useNumParam("salesCycleId")
  const metric = useParam("metric", true) as TReportsMetric | undefined

  const value = React.useMemo(
    () => ({iterationId, salespersonId, salesCycleId, metric: metric ?? "activities"}),
    [iterationId, metric, salesCycleId, salespersonId]
  )

  const setValue = React.useCallback<React.Dispatch<React.SetStateAction<TCurrentSelectionState>>>(
    arg => {
      const handleChange = (newValue: TCurrentSelectionState) => {
        if (isEqual(value, newValue)) {
          return
        }

        navigate(buildUrl(newValue))
      }

      handleChange(typeof arg === "function" ? arg(value) : arg)
    },
    [navigate, value]
  )

  return React.useMemo(() => ({value, setValue}), [setValue, value])
}

export const useCurrentAssignmentId = (): string | null => {
  const {
    value: {salespersonId, salesCycleId, iterationId},
  } = useCurrentSelectionState()

  const {data} = useReportsAssignmentsQuery(salesCycleId, iterationId)

  return data?.find(assignment => assignment.sales_person?.id === salespersonId)?.group_id ?? null
}

export const useAreMeetingsDisabled = () => {
  const {
    value: {salesCycleId},
  } = useCurrentSelectionState()

  const {data} = useReportsSalesCycleQuery(salesCycleId)

  return data?.hide_meeting_activities ?? true
}

export function useProspectsFiltering() {
  const [orderBy, setOrderBy] = React.useState<TOrderBy<TProspectsTableColumn>>(undefined)

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [lastUpdateFilter, setLastUpdateFilter] = React.useState<TLastChangeOption | null>(null)
  const lastUpdateFilterDebounced = useDebouncedValue(lastUpdateFilter)

  const [segmentFilter, setSegmentFilter] = React.useState<TFilterOption[]>([])
  const segmentFilterDebounced = useDebouncedValue(segmentFilter)

  const [stageFilter, setStageFilter] = React.useState<TStageOption[]>([])
  const stageFilterDebounced = useDebouncedValue(stageFilter)

  const [statusFilter, setStatusFilter] = React.useState<TStatusOption[]>([])
  const statusFilterDebounced = useDebouncedValue(statusFilter)

  const resetAllFilters = React.useCallback(() => {
    setOrderBy(undefined)
    setSearchString("")
    setLastUpdateFilter(null)
    setSegmentFilter([])
    setStageFilter([])
    setStatusFilter([])
  }, [])

  return {
    orderBy,
    setOrderBy,
    searchString,
    searchStringDebounced,
    setSearchString,
    lastUpdateFilter,
    lastUpdateFilterDebounced,
    setLastUpdateFilter,
    segmentFilter,
    segmentFilterDebounced,
    setSegmentFilter,
    stageFilter,
    stageFilterDebounced,
    setStageFilter,
    statusFilter,
    statusFilterDebounced,
    setStatusFilter,
    resetAllFilters,
  }
}

export const ProspectsFilteringContext =
  createSimpleContext<ReturnType<typeof useProspectsFiltering>>("prospects filtering")

export const ActivityFeedContext = createStateContext<ACompanyUserSalesCycleProspects | null>("activity feed")

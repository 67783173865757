import React from "react"

import Modal from "../../../../components/Modal.tsx"
import {IsUploadingContext, ProspectsContext} from "../context.ts"
import {FinishedSingleStep} from "./FinishedSingleStep.tsx"
import {FinishedXLSStep} from "./FinishedXLSStep.tsx"
import {InitialStep} from "./InitialStep.tsx"
import {LeadsStep} from "./LeadsStep.tsx"
import {SingleStep} from "./SingleStep.tsx"
import {EFeature, EStep, featureToStep, modalPropsByStep} from "./utils.ts"
import {XLSStep} from "./XLSStep.tsx"

export const AddProspectsModal: React.FC<{enabledFeatures: EFeature[]}> = ({enabledFeatures}) => {
  const {value: isVisible, setValue: setIsOpen} = IsUploadingContext.useContext()
  const {assignmentId} = ProspectsContext.useContext()

  const isOnlyOneFeature = enabledFeatures.length === 1

  const initialStep = isOnlyOneFeature ? featureToStep[enabledFeatures[0]] : EStep.Initial
  const [step, setStep] = React.useState(initialStep)

  const onClose = React.useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  React.useEffect(() => {
    if (!isVisible) {
      return
    }

    setStep(initialStep)
  }, [isVisible, initialStep])

  if (assignmentId == null || step == null) {
    return null
  }

  return (
    <Modal disableClickOutsideClose {...modalPropsByStep[step]} isOpen={isVisible} onClose={onClose}>
      {step === EStep.Initial && (
        <InitialStep onClose={onClose} onStepPick={setStep} enabledFeatures={enabledFeatures} />
      )}
      {step === EStep.Single && <SingleStep onClose={onClose} onSuccess={() => setStep(EStep.FinishedSingle)} />}
      {step === EStep.Leads && <LeadsStep onClose={onClose} />}
      {step === EStep.XLS && (
        <XLSStep assignmentId={assignmentId} onClose={onClose} onSuccess={() => setStep(EStep.FinishedXLS)} />
      )}
      {step === EStep.FinishedXLS && <FinishedXLSStep onClose={onClose} />}
      {step === EStep.FinishedSingle && <FinishedSingleStep onClose={onClose} />}
    </Modal>
  )
}

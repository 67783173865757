import React from "react"
import {Control, useFormState} from "react-hook-form"
import {LinkProps} from "react-router"
import {twMerge} from "tailwind-merge"

import {ErrorMessage} from "./fields/components"
import {UnstyledLink} from "./Link"
import {Loading} from "./Loading"

const classNameByColor = {
  contained: {
    blue: "text-cr-white bg-cr-blue hover:bg-cr-blue-dark focus:ring-cr-blue",
    cyan: "text-cr-black bg-cr-cyan hover:bg-cr-cyan-dark focus:ring-cr-cyan",
    red: "text-cr-white bg-cr-red hover:bg-cr-red-dark focus:ring-cr-red",
    yellow: "text-cr-white bg-cr-yellow hover:bg-cr-yellow-dark focus:ring-cr-yellow",
    gray: "text-cr-black bg-cr-grey-30 hover:bg-cr-grey-50 focus:ring-cr-grey-30",
  },
  light: {
    blue: "text-cr-blue bg-cr-blue-light hover:bg-cr-blue-mid-light focus:ring-cr-blue-mid",
    cyan: "text-cr-blue-dark bg-cr-cyan-light hover:bg-cr-cyan-mid-light focus:ring-cr-cyan",
    red: "text-cr-red bg-cr-red-light hover:bg-cr-red-mid-light focus:ring-cr-red",
    yellow: "text-cr-yellow bg-cr-yellow-light hover:bg-cr-yellow-mid-light focus:ring-cr-yellow",
    gray: "text-cr-black bg-cr-grey-5 hover:bg-cr-grey-15 focus:ring-cr-grey-30",
  },
  outlined: {
    blue: "text-cr-blue bg-cr-white border-cr-blue hover:bg-cr-blue-light focus:ring-cr-blue",
    cyan: "text-cr-blue-dark bg-cr-white border-cr-cyan hover:bg-cr-cyan-light focus:ring-cr-cyan",
    red: "text-cr-red bg-cr-white border-cr-red hover:bg-cr-red-light focus:ring-cr-red",
    yellow: "text-cr-yellow bg-cr-white border-cr-yellow hover:bg-cr-yellow-light focus:ring-cr-yellow",
    gray: "text-cr-black bg-cr-white border-cr-grey-30 hover:bg-cr-grey-5 focus:ring-cr-grey-30",
  },
} as const satisfies {
  [key in NonNullable<TButtonProps["variant"]>]: {[key in NonNullable<TButtonProps["color"]>]: string}
}

const classNameBySize = {
  xs: "px-3 py-2 text-xs group-[.circular-shape]:size-8",
  sm: "px-3 py-2 text-sm group-[.circular-shape]:size-9",
  base: "px-4 py-2 text-sm group-[.circular-shape]:size-10",
  lg: "px-4 py-2 text-base group-[.circular-shape]:size-11",
  xl: "px-6 py-3 text-base group-[.circular-shape]:size-12",
} as const satisfies {[key in NonNullable<TButtonProps["size"]>]: string}

const iconClassBySize = {
  xs: "h-4 w-4",
  sm: "h-4 w-4",
  base: "h-5 w-5",
  lg: "h-5 w-5",
  xl: "h-5 w-5",
} as const satisfies {[key in NonNullable<TButtonProps["size"]>]: string}

const classNameByShape = {
  base: "rounded-md",
  round: "rounded-full",
  circular: "rounded-full px-0 py-0",
} as const satisfies {[key in NonNullable<TButtonProps["shape"]>]: string}

const iconButtonClassNameBySize = {
  xs: noEdges => twMerge("p-0.5", noEdges && `-m-0.5`),
  sm: noEdges => twMerge("p-1", noEdges && `-m-1`),
  base: noEdges => twMerge("p-1.5", noEdges && `-m-1.5`),
  lg: noEdges => twMerge("p-2", noEdges && `-m-2`),
  xl: noEdges => twMerge("p-3", noEdges && `-m-3`),
} as const satisfies {[key in NonNullable<TButtonProps["size"]>]: (noEdges: boolean) => string}

export type TButtonProps = React.ButtonHTMLAttributes<unknown> & {
  isLoading?: boolean
  color?: "blue" | "cyan" | "gray" | "red" | "yellow"
  variant?: "contained" | "light" | "outlined"
  shape?: "base" | "round" | "circular"
  size?: "xs" | "sm" | "base" | "lg" | "xl"
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  fullWidth?: boolean
  wrapperClassName?: string
  readOnly?: boolean
}

type TExtractedLinkProps = {to?: LinkProps["to"]} & Pick<LinkProps, "target" | "rel" | "download" | "className">
type TButtonLinkProps = TButtonProps &
  (TExtractedLinkProps & {linkProps?: Omit<LinkProps, keyof TExtractedLinkProps>; buttonClassName?: string})

export const ButtonLink: React.FC<TButtonLinkProps> = props => {
  const {linkProps, className, buttonClassName, isLoading, to, target, rel, download, disabled, ...buttonProps} = props

  const isDisabled = isLoading || disabled

  return (
    <UnstyledLink
      to={to}
      target={target}
      rel={rel}
      download={download}
      disabled={isDisabled}
      className={twMerge(["inline-block", className])}
      {...linkProps}
    >
      <Button disabled={disabled} isLoading={isLoading} className={buttonClassName} {...buttonProps} />
    </UnstyledLink>
  )
}

const Button = React.forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      className,
      wrapperClassName,
      isLoading,
      disabled,
      children,
      color = "blue",
      variant = "contained",
      shape = "base",
      size = "base",
      iconLeft,
      iconRight,
      readOnly,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const handleClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
      e => {
        if (disabled || readOnly) {
          return false
        }

        return props.onClick?.(e)
      },
      [disabled, props, readOnly]
    )

    const isDisabled = disabled || isLoading
    return (
      <div
        className={twMerge([
          "relative inline-block cursor-pointer",
          fullWidth && "w-full",
          isDisabled && "cursor-not-allowed",
          readOnly && "cursor-default",
          shape === "circular" && "circular-shape group",
          wrapperClassName,
        ])}
      >
        <button
          className={twMerge([
            "relative flex items-center justify-center gap-2",
            "cursor-[inherit] border border-transparent font-semibold transition-colors select-none",
            "disabled:border-1 disabled:border-cr-grey-15 disabled:bg-cr-grey-5 disabled:text-cr-grey-30",
            "focus:ring-2 focus:ring-offset-2",
            "w-full",
            classNameByColor[variant][color],
            classNameBySize[size],
            classNameByShape[shape],
            className,
          ])}
          disabled={isDisabled}
          type={"button"}
          ref={ref}
          onClick={handleClick}
          {...props}
        >
          {isLoading && <Loading size={"sm"} color={"gray"} containerClassName={"absolute inset-0"} />}
          {iconLeft && (
            <div aria-hidden={"true"} className={twMerge(["flex items-center justify-center", iconClassBySize[size]])}>
              {iconLeft}
            </div>
          )}
          {children}
          {iconRight && (
            <div aria-hidden={"true"} className={twMerge(["flex items-center justify-center", iconClassBySize[size]])}>
              {iconRight}
            </div>
          )}
        </button>
      </div>
    )
  }
)
Button.displayName = "Button"

export const ButtonLoading: React.FC<Omit<TButtonProps, "onClick"> & {onClick: (e: any) => Promise<unknown>}> = ({
  onClick,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClick: typeof onClick = e => {
    setIsLoading(true)
    return onClick(e).finally(() => setIsLoading(false))
  }

  return <Button {...props} onClick={handleClick} isLoading={isLoading || props.isLoading} />
}

interface IButtonForm extends TButtonProps {
  control?: Control<any>
}

export const ButtonForm: React.FC<IButtonForm> = ({control, disabled, isLoading: isLoadingProp, ...props}) => {
  const {
    errors: {root, ...errorsOther},
    isLoading,
    isSubmitting,
  } = useFormState({control})

  return (
    <>
      <Button
        type={"submit"}
        {...props}
        disabled={!!Object.keys(errorsOther).length || disabled}
        isLoading={isLoading || isSubmitting || isLoadingProp}
      />
      <ErrorMessage error={root} testId={"root"} />
    </>
  )
}

type TIconButtonProps = Pick<TButtonProps, "size"> & {noEdges?: boolean} & React.ButtonHTMLAttributes<unknown>

export const IconButton = React.forwardRef<HTMLButtonElement, TIconButtonProps>(
  ({className, children, size = "base", noEdges = false, disabled, ...props}, ref) => {
    return (
      <button
        className={twMerge(
          "block rounded-full bg-transparent p-2 transition-all",
          !disabled && "hover:bg-cr-grey-5",
          iconButtonClassNameBySize[size](noEdges),
          className
        )}
        disabled={disabled}
        ref={ref}
        type={"button"}
        {...props}
      >
        {children}
      </button>
    )
  }
)

export default Button

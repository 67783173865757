import {FC, ReactNode} from "react"

const Layout: FC<{
  heading: ReactNode
  paragraph: ReactNode
  children: ReactNode
}> = ({heading, paragraph, children}) => (
  <div className={"pb-12"}>
    <div className={"bg-cr-blue-super-light px-8 py-8 sm:px-10 sm:py-10 xl:py-14"}>
      <div className={"mx-auto max-w-(--breakpoint-2xl)"}>
        <h1 className={"mb-8 text-3xl font-bold text-cr-blue sm:text-4xl xl:text-5xl"}>{heading}</h1>
        <p>{paragraph}</p>
      </div>
    </div>
    {children}
  </div>
)

export default Layout

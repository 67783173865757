import {useMutation} from "@tanstack/react-query"

import api from "../services"

export function useVerifySaaSCompanyMutation() {
  return useMutation({
    mutationFn: (data: Parameters<typeof api.invitationAcceptances.invitationAcceptancesCreate>[0]) =>
      api.invitationAcceptances.invitationAcceptancesCreate(data),
  })
}

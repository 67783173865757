import {i18n} from "../../i18n.ts"
import {AUsersListParamsRoleEnum, AUsersStatsListData} from "../../services/types.generated.ts"

export type TTab = "all" | "sales" | "admins"
export const tabDefinitions = {
  all: {
    roles: [],
    title: i18n.t("SaaSUserManagement_Tab_All"),
    getCount: counts => (counts ? counts.admin + counts.owner + counts.sales_person : 0),
  },
  admins: {
    roles: [AUsersListParamsRoleEnum.Admin, AUsersListParamsRoleEnum.Owner],
    title: i18n.t("SaaSUserManagement_Tab_Admins"),
    getCount: counts => (counts ? counts.admin + counts.owner : 0),
  },
  sales: {
    roles: [AUsersListParamsRoleEnum.SalesPerson],
    title: i18n.t("SaaSUserManagement_Tab_Salespeople"),
    getCount: counts => (counts ? counts.sales_person : 0),
  },
} satisfies {
  [tab in TTab]: {
    roles: AUsersListParamsRoleEnum[]
    title: string
    getCount: (counts?: AUsersStatsListData["counts"]) => number
  }
}

export const tabsOrder = ["all", "sales", "admins"] as const satisfies TTab[]

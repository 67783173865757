import React from "react"
import {Trans} from "react-i18next"

import {getCurrentLanguage} from "../../../i18n.ts"
import {ASaasCompanyUserListItem} from "../../../services/types.generated.ts"
import {getFullName} from "../../../utils"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {commonTransComponents} from "../../../utils/i18n.tsx"

export const JoinedAtCell: React.FC<{row: ASaasCompanyUserListItem}> = ({row}) => {
  const invitedBy = getFullName(row.invited_by) || row.invited_by?.email

  return (
    <div className={"flex flex-col gap-2"}>
      <span>{apiDateToJS(row.joined_at).toLocaleString(getCurrentLanguage(), {dateStyle: "short"})}</span>
      {invitedBy && (
        <span className={"text-xs text-cr-grey-50"}>
          <Trans
            i18nKey={"SaaSUserManagement_Table_InvitedBy"}
            components={{...commonTransComponents, name: <span className={"text-cr-black"} />}}
            values={{name: invitedBy}}
          />
        </span>
      )}
    </div>
  )
}

import React from "react"
import {DefaultValues, FormProvider, SubmitHandler, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {zodResolver} from "@hookform/resolvers/zod"

import {ButtonForm} from "../../components/Button"
import {CheckboxConnected} from "../../components/fields/Checkbox.tsx"
import {FileInputField} from "../../components/fields/File"
import {TextareaField} from "../../components/fields/Textarea"
import {DropdownField} from "../../components/formElements/Dropdown/Dropdown.tsx"
import {useFinalizeSalesCycleKeyDetailsMutation, useUpdateSalesCycleMutation} from "../../queries/salesCycles"
import {ASalesCycleStatuses} from "../../services/types.generated"
import {useNumParam} from "../../utils/hooks"
import {enumToOptions} from "../../utils/i18n"
import {setFormErrorsFromAxios} from "../../utils/validation"
import {stepWeb} from "../SalesStrategy/stepsConfig.ts"
import {Commissions} from "./Commissions"
import {TSalesFormData} from "./types"
import {validationSchema} from "./utils"

const customerProfileOptions = enumToOptions("Profile")
const segmentOptions = enumToOptions("Segment")
const makerPersonaOptions = enumToOptions("Persona")

export const Form: React.FC<{formData: DefaultValues<TSalesFormData>}> = ({formData}) => {
  const {t} = useTranslation()
  const salesCycleId = useNumParam("salesCycleId")
  const isEditing = salesCycleId != null

  const methods = useForm<TSalesFormData>({
    mode: "onTouched",
    resolver: zodResolver(validationSchema(!!formData.price_list_url)),
    defaultValues: formData,
  })
  const {getValues, setError} = methods

  const navigate = useNavigate()
  const updateSalesCycleMutation = useUpdateSalesCycleMutation()
  const finalizeMutation = useFinalizeSalesCycleKeyDetailsMutation()

  const handleSubmit: SubmitHandler<TSalesFormData> = async () => {
    try {
      await updateSalesCycleMutation.mutateAsync({id: salesCycleId, data: getValues()})
      await finalizeMutation.mutateAsync({id: salesCycleId})

      return navigate(`/sales-strategy/${salesCycleId}/${stepWeb.href}`)
    } catch (e) {
      return setFormErrorsFromAxios(e, setError)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <h2 className={"mb-10 text-4xl font-semibold text-cr-blue"}>{t("SalesStrategy_Define the target audience")}</h2>

        <DropdownField
          required
          name={"segment"}
          label={t("T_Segment")}
          legend={t("SalesStrategy_Specify the segment targeted companies belong to")}
          options={segmentOptions}
        />

        <TextareaField name={"segment_note"} label={t("SalesStrategy_NoteForSegment")} />

        <DropdownField
          required
          name={"ideal_customer_profile"}
          label={t("SalesStrategy_Ideal customer profile")}
          legend={t("SalesStrategy_Preferred size of companies to target")}
          options={customerProfileOptions}
        />

        <DropdownField
          required
          multiple
          addCustom
          name={"decision_maker_persona"}
          label={t("T_Decision maker")}
          legend={t("SalesStrategy_Who is the person to contact within targeted companies")}
          options={makerPersonaOptions}
        />

        <h2 className={"mt-6 mb-10 text-4xl font-semibold text-cr-blue"}>{t("SalesStrategy_Product specification")}</h2>

        <FileInputField
          required={!formData.price_list_url}
          name={"price_list"}
          label={t("T_Price list")}
          legend={t("SalesStrategy_Upload the full list of all products alongside their prices.")}
        />

        <h2 className={"mt-6 mb-10 text-4xl font-semibold text-cr-blue"}>{t("SalesStrategy_Commissions")}</h2>

        <Commissions />

        <h2 className={"mt-6 mb-10 text-4xl font-semibold text-cr-blue"}>
          {t("SalesStrategy_SalespersonRequirements_SectionTitle")}
        </h2>

        <div className={"pb-5"}>
          <CheckboxConnected name={"sales_person_online_required"}>
            {t("SalesStrategy_SalespersonRequirements_InPersonCheckbox")}
          </CheckboxConnected>
        </div>

        <div className={"pb-5"}>
          <CheckboxConnected name={"sales_person_offline_required"}>
            {t("SalesStrategy_SalespersonRequirements_OnlineCheckbox")}
          </CheckboxConnected>
        </div>

        <TextareaField
          name={"sales_person_requirements_note"}
          label={t("SalesStrategy_SalespersonRequirements_NoteInput")}
        />

        <div className={"ml-auto w-52"}>
          <ButtonForm fullWidth disabled={formData.status === ASalesCycleStatuses.Ongoing}>
            {isEditing ? t("T_Continue") : t("SalesStrategy_Finish the setup")}
          </ButtonForm>
        </div>
      </form>
    </FormProvider>
  )
}

import React from "react"
import {useTranslation} from "react-i18next"
import {EnvelopeIcon, PhoneIcon, RocketLaunchIcon, UsersIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {LinearProgress} from "../../../../components/LinearProgress.tsx"
import {ProfileImage} from "../../../../components/ProfileImage.tsx"
import {ACompanyUserSalesCycleIteration} from "../../../../services/types.generated.ts"
import {TIconComponent} from "../../../../types.ts"
import {getFullName} from "../../../../utils"

export const AssignmentProgress: React.FC<{
  assignment: ACompanyUserSalesCycleIteration["assignments"][number]
  hideMeetings?: boolean
}> = ({assignment, hideMeetings}) => {
  const {t} = useTranslation()

  const fullName = getFullName(assignment.sales_person, t("SellingDetails_News_Progress_UnassignedSalesperson"))

  return (
    <div className={"flex shrink gap-6"} data-testid={"AssignmentProgress"}>
      <ProfileImage
        src={assignment.sales_person?.profile_picture_thumbnail_url ?? undefined}
        alt={fullName}
        className={"size-20"}
      />
      <div className={"flex grow flex-col gap-4"}>
        <div className={"flex flex-wrap justify-between gap-2"}>
          <h4 className={"text-lg font-bold"}>{fullName}</h4>
          <div className={"flex gap-4"}>
            <Metric Icon={EnvelopeIcon} amount={assignment.statistics?.emails_sent ?? 0} />
            <Metric Icon={PhoneIcon} amount={assignment.statistics?.calls_made ?? 0} />
            {!hideMeetings && <Metric Icon={UsersIcon} amount={assignment.statistics?.meetings_done ?? 0} />}
            <Metric Icon={RocketLaunchIcon} amount={assignment.statistics?.won ?? 0} highlighted />
          </div>
        </div>
        <div className={"h-[4px] w-full"}>
          <LinearProgress
            progress={((assignment.outreach ?? 0) / (assignment.reaches ?? 1)) * 100}
            color={"var(--color-cr-cyan)"}
            withCircle
          />
        </div>
        <div className={"text-sm text-cr-blue"}>
          {t("SellingDetails_News_Progress_Caption")}
          <span className={"ml-3 font-semibold"}>
            {assignment.outreach} / {assignment.reaches}
          </span>
        </div>
      </div>
    </div>
  )
}

const Metric: React.FC<{
  Icon: TIconComponent
  highlighted?: boolean
  amount: number
}> = ({Icon, amount, highlighted}) => {
  return (
    <div className={twMerge("flex items-center gap-1 text-sm", highlighted && "font-semibold text-cr-blue")}>
      <Icon className={"size-4"} />
      <span>{amount}</span>
    </div>
  )
}

import React from "react"
import {Controller, FieldPath, FieldValues} from "react-hook-form"
import {ControllerRenderProps} from "react-hook-form/dist/types/controller"
import {twMerge} from "tailwind-merge"

import {pickProps} from "../../utils/types.ts"
import {useResizeRef} from "../../utils/useResizeRef.ts"
import {getOptionKey, TOption} from "../formElements/Dropdown/types.ts"
import {UnstyledLink} from "../Link.tsx"
import {TBaseSharedProps, TConnectedField, useGetFieldVisibleError} from "./components.tsx"
import {FieldLabel, pickFieldLabelProps, TFieldLabelProps} from "./FieldLabel.tsx"

type TOptionsToggleSharedProps<T> = TBaseSharedProps & {
  options: ReadonlyArray<TOption<T>>
}

type TOptionsToggleBaseProps<T> = TOptionsToggleSharedProps<T> &
  Partial<Omit<ControllerRenderProps, "onChange" | "value">> & {
    onChange: (newValue: T) => void
    value: T | null
  }

type TOptionsToggleConnectedProps<V, T extends FieldValues, N extends FieldPath<T>> = TOptionsToggleSharedProps<V> &
  Omit<TConnectedField<T, N>, "options">

export const OptionsToggleBase: <T>(props: TOptionsToggleBaseProps<T>) => React.ReactNode = React.forwardRef<
  HTMLDivElement,
  TOptionsToggleBaseProps<any>
>(function OptionsToggleBase<T>(
  {options, value, onChange, readOnly, disabled, hasError}: TOptionsToggleBaseProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
): React.ReactNode {
  const [higlighterTransform, setHiglighterTransform] = React.useState<{x: number; width: number}>({x: 0, width: 0})

  const resizeRef = useResizeRef((element: HTMLElement) => {
    setHiglighterTransform({
      width: element.clientWidth,
      x: element.offsetLeft,
    })
  })

  return (
    <div
      className={twMerge(
        "relative inline-flex rounded-full border border-cr-grey-15 bg-white p-1",
        hasError && "border-cr-red"
      )}
      role={"radiogroup"}
      ref={ref}
    >
      <div
        className={twMerge(
          "absolute left-0 h-[calc(100%-0.5rem)] self-center rounded-full bg-cr-blue opacity-0 transition-all",
          value && "opacity-100",
          disabled && "bg-cr-grey-50"
        )}
        style={{width: higlighterTransform.width, transform: `translate(${higlighterTransform.x}px)`}}
      />

      {options.map(opt => {
        const isChangeDisabled = opt.value === value || !onChange || readOnly || disabled

        return (
          <UnstyledLink
            key={getOptionKey(opt)}
            onClick={isChangeDisabled ? undefined : () => onChange(opt.value)}
            className={twMerge(
              "z-10 flex cursor-pointer items-center justify-center rounded-full text-xs select-none",
              opt.value === value && "text-cr-white",
              readOnly && "cursor-default",
              disabled && "cursor-not-allowed"
            )}
            data-testid={`option-toggle-${getOptionKey(opt)}`}
            role={"radio"}
            aria-checked={opt.value === value}
            ref={opt.value === value ? resizeRef : undefined}
          >
            <div className={twMerge(opt.value !== value && "pointer-events-none")}>{opt.title}</div>
          </UnstyledLink>
        )
      })}
    </div>
  )
})
;(OptionsToggleBase as React.ComponentType<unknown>).displayName = "OptionsToggleBase"

export function OptionsToggleConnected<V, T extends FieldValues, N extends FieldPath<T>>({
  name,
  ...props
}: TOptionsToggleConnectedProps<V, T, N>) {
  const hasError = !!useGetFieldVisibleError(name)

  return (
    <Controller name={name} render={({field}) => <OptionsToggleBase {...field} hasError={hasError} {...props} />} />
  )
}

export function OptionsToggleField<T, V extends FieldValues = any, P extends FieldPath<V> = any>(
  props: TFieldLabelProps & TOptionsToggleConnectedProps<T, V, P>
) {
  const fieldLabelProps = pickFieldLabelProps(props)
  const optionToggleProps = pickOptionsToggleConnectedProps(props)

  return (
    <FieldLabel {...fieldLabelProps}>
      <OptionsToggleConnected {...optionToggleProps} />
    </FieldLabel>
  )
}

function pickOptionsToggleConnectedProps<T, V extends FieldValues, P extends FieldPath<V>>(
  props: React.ComponentProps<typeof OptionsToggleField<T, V, P>>
) {
  return pickProps<TOptionsToggleConnectedProps<T, V, P>>({
    name: true,
    readOnly: true,
    hasError: true,
    disabled: true,
    options: true,
  })(props)
}

import React from "react"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"
import {ArrowPathIcon} from "@heroicons/react/20/solid"
import {useQueryClient} from "@tanstack/react-query"

import {GenericErrorAlert} from "../../../components/Alert"
import {Badge, TBadgeColor} from "../../../components/Badge"
import {Link, UnstyledLink} from "../../../components/Link"
import {Loading} from "../../../components/Loading"
import Modal from "../../../components/Modal"
import Pagination, {DEFAULT_PAGE_SIZE} from "../../../components/Pagination"
import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {Table} from "../../../components/Table/Table.tsx"
import {getColumnsFromMeta} from "../../../components/Table/utils/columns.ts"
import {TColumnsMetaWithEmpty} from "../../../components/Table/utils/shared.ts"
import {useLSTableColumnsState} from "../../../components/Table/utils/useLSTableColumnsState.tsx"
import {getCurrentLanguage} from "../../../i18n"
import {useLeadsImportersQuery, useRollbackLeadsUploadMutation} from "../../../queries/leads"
import {leadsGeneralTableKey, leadsImportersGeneralKey} from "../../../services"
import {AImporter, AImporterStatuses} from "../../../services/types.generated"
import {getFullName} from "../../../utils"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {enumTranslKey} from "../../../utils/i18n"

type TCol = "date" | "imported by" | "status" | "delete"

const pageSize = DEFAULT_PAGE_SIZE

const tableId = "leads/importers"

export const ImportsHistoryModal: React.FC<{isOpen: boolean; onClose: () => void}> = ({onClose, isOpen}) => {
  const queryClient = useQueryClient()
  const {t} = useTranslation()

  const {data, isFetching, pagination, error, refetch} = useLeadsImportersQuery({pageSize})

  const setPage = pagination.setPage
  React.useEffect(() => {
    if (!isOpen) {
      queryClient.invalidateQueries({queryKey: leadsGeneralTableKey})
      return
    }

    setPage(() => 1)
    queryClient.invalidateQueries({queryKey: leadsImportersGeneralKey})
  }, [isOpen, queryClient, setPage])

  const columnsMeta = React.useMemo<TColumnsMetaWithEmpty<TCol, AImporter>>(
    () => [
      {
        column: "date",
        HeaderCellValue: () => t("Leads_ImportsHistory_Date"),
        CellValue: ImportDateCell,
      },
      {
        column: "imported by",
        HeaderCellValue: () => t("Leads_ImportsHistory_ImportedBy"),
        CellValue: ImportedByCell,
      },
      {
        column: "status",
        HeaderCellValue: () => t("Leads_ImportsHistory_Status"),
        CellValue: StatusCell,
      },
      data?.data.importers.some(row => row.delete_permitted) && {
        column: "delete",
        align: "right",
        size: "max-content",
        HeaderCellValue: () => null,
        CellValue: DeleteCell,
      },
    ],
    [data?.data.importers, t]
  )

  const columnsState = useLSTableColumnsState(tableId, {
    columnsOrder: getColumnsFromMeta(columnsMeta),
    pinnedColumn: null,
  })

  return (
    <Modal
      disableClickOutsideClose
      size={"xl"}
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className={"flex items-center gap-4"}>
          {t("Leads_ImportsHistory_Title")}
          <UnstyledLink
            className={"cursor-pointer rounded-full bg-transparent p-2 transition-all hover:bg-cr-grey-5"}
            onClick={() => refetch()}
          >
            <ArrowPathIcon className={"size-5"} />
          </UnstyledLink>
        </div>
      }
    >
      {error ? (
        <GenericErrorAlert retry={refetch} />
      ) : data == null ? (
        <Loading />
      ) : (
        <div className={"flex flex-col gap-4"}>
          <Table<TCol, AImporter>
            ghost
            loading={isFetching}
            columnsMeta={columnsMeta}
            data={data.data.importers}
            {...columnsState}
          />

          <Pagination {...pagination} autoHide />
        </div>
      )}
    </Modal>
  )
}

export const ImportDateCell: React.FC<{row: AImporter}> = ({row}) => {
  return (
    <div className={"flex flex-col gap-1"}>
      <span>
        {apiDateToJS(row.created_at).toLocaleString(getCurrentLanguage(), {
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
        })}
      </span>
      <span className={"text-cr-grey-50"}>
        {apiDateToJS(row.created_at).toLocaleString(getCurrentLanguage(), {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </span>
    </div>
  )
}

export const ImportedByCell: React.FC<{row: AImporter}> = ({row}) => {
  const fullName = getFullName(row.created_by)

  return (
    <div className={"flex gap-4"}>
      <ProfileImage
        className={"size-10"}
        src={row.created_by?.profile_picture_thumbnail_url ?? undefined}
        alt={fullName}
      />

      <div className={"flex flex-col justify-center gap-1"}>
        {fullName && <span>{fullName}</span>}
        <span className={"text-cr-grey-50"}>{row.created_by?.email}</span>
      </div>
    </div>
  )
}

const badgeColorByStatus = {
  [AImporterStatuses.Done]: "green",
  [AImporterStatuses.DoneWithWarnings]: "green",
  [AImporterStatuses.Waiting]: "grey",
  [AImporterStatuses.InProgress]: "yellow",
  [AImporterStatuses.RolledBack]: "red",
  [AImporterStatuses.Failed]: "red",
} satisfies {[status in AImporterStatuses]: TBadgeColor}

export const StatusCell: React.FC<{row: AImporter}> = ({row}) => {
  const {t} = useTranslation()

  return (
    <Badge color={badgeColorByStatus[row.status]}>{t(enumTranslKey("ImporterStatus", row.status), row.status)}</Badge>
  )
}

export const DeleteCell: React.FC<{row: AImporter}> = ({row}) => {
  const {t} = useTranslation()

  const rollbackLeadsUploadMutation = useRollbackLeadsUploadMutation()

  const handleDelete = React.useCallback(async () => {
    try {
      rollbackLeadsUploadMutation.mutateAsync(row.id)
      toast.success(t("Leads_ImportsHistory_DeleteSuccessTooltip"))
    } catch {
      toast.error(t("Leads_ImportsHistory_DeleteFailTooltip"))
    }
  }, [rollbackLeadsUploadMutation, row.id, t])

  if (!row.delete_permitted) {
    return null
  }

  return (
    <Link variant={"error"} flipUnderline onClick={handleDelete} disabled={rollbackLeadsUploadMutation.isPending}>
      {t("Leads_ImportsHistory_Delete")}
    </Link>
  )
}

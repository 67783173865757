import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {useReportsAssignmentsQuery} from "../../../../queries/reports"
import {getFullName} from "../../../../utils"
import {useCurrentSelectionState} from "../../hooks"

export const MetricGraphCard: React.FC<{
  children: React.ReactNode
  className?: string
  title?: string
  total?: number | null
  hideAssignmentName?: boolean
}> = ({children, title, total, className, hideAssignmentName}) => {
  const {
    value: {salesCycleId, iterationId, salespersonId},
  } = useCurrentSelectionState()
  const {data: assignments} = useReportsAssignmentsQuery(salesCycleId, iterationId)

  const salespersonName = React.useMemo(() => {
    if (hideAssignmentName) {
      return null
    }

    return getFullName(assignments?.find(ass => ass.sales_person?.id === salespersonId)?.sales_person) || null
  }, [assignments, hideAssignmentName, salespersonId])

  const {t} = useTranslation()

  return (
    <div className={twMerge("flex h-96 min-w-[417px] grow flex-col gap-2 card-shadow p-6", className)}>
      <div className={"flex justify-between"}>
        <h2 className={"font-semibold"}>
          {title}
          {salespersonName ? ` - ${salespersonName}` : ""}
        </h2>
        {total != null && (
          <div className={"flex items-baseline gap-1"}>
            <span>{t("Reports_Chart_Total")} /</span>
            <span className={"text-2xl font-semibold"}>{total}</span>
          </div>
        )}
      </div>
      {children}
    </div>
  )
}

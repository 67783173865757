import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {toast} from "react-toastify"
import {zodResolver} from "@hookform/resolvers/zod"
import {useQueryClient} from "@tanstack/react-query"
import {z} from "zod"

import {GenericErrorAlert} from "../../components/Alert"
import {ButtonForm} from "../../components/Button"
import {InputConnected} from "../../components/fields/Input"
import {TextareaConnected} from "../../components/fields/Textarea"
import {DropdownConnected} from "../../components/formElements/Dropdown/Dropdown.tsx"
import {Loading} from "../../components/Loading"
import {TerseFormLayout} from "../../components/TerseFormLayout"
import {i18n} from "../../i18n"
import {
  useCompanyQuery,
  useUpdateCompanyMembersMutation,
  useUpdateCompanyProfileMutation,
} from "../../queries/companies"
import {useUserSettingsOrLogout} from "../../queries/user.ts"
import {queryKey} from "../../services"
import {ACompanyAttributes} from "../../services/types.generated"
import {useIsWelcomeScreenEnabled} from "../../utils"
import {useCountryOptions, useNumParam} from "../../utils/hooks"
import {enumToOptions} from "../../utils/i18n"
import {
  requiredFieldMessage,
  setFormErrorsFromAxios,
  validateNonemptyString,
  validatePhoneNumber,
  validateUrl,
} from "../../utils/validation"

const validationSchema = z.object({
  member: z.object({
    first_name: validateNonemptyString(),
    last_name: validateNonemptyString(),
    phone_number: validatePhoneNumber(),
  }),
  company: z.object({
    name: validateNonemptyString(),
    headline: validateNonemptyString(),
    website: validateUrl(),
    country_id: z.number({required_error: requiredFieldMessage}).positive(),
    industry: validateNonemptyString(),
    number_of_employees: z
      .number({invalid_type_error: requiredFieldMessage})
      .positive(i18n.t("Validation_RequiredPositive"))
      .int(i18n.t("Validation_RequiredInteger")),
    description: validateNonemptyString(),
    additional_billing_email: z.string(),
  }),
})

type TFormData = {
  member: NonNullable<ACompanyAttributes["members"]>[0]
  company: NonNullable<ACompanyAttributes["profile"]>
}

export const CompanyProfile: React.FC = () => {
  const companyId = useNumParam("companyId")

  const {error, data, refetch} = useCompanyQuery(companyId)

  const transformedData: TFormData = React.useMemo(
    () => ({
      member: {
        id: data?.members[0].id ?? Number.NaN,
        first_name: data?.members[0].first_name ?? undefined,
        last_name: data?.members[0].last_name ?? undefined,
        phone_number: data?.members[0].phone_number ?? undefined,
      },
      company: {
        name: data?.profile.name,
        website: data?.profile.website ?? undefined,
        headline: data?.profile.headline ?? undefined,
        description: data?.profile.description ?? undefined,
        number_of_employees: data?.profile.number_of_employees ?? undefined,
        country_id: data?.profile.country?.id ?? undefined,
        industry: data?.profile.industry,
        additional_billing_email: data?.profile.additional_billing_email ?? undefined,
      },
    }),
    [data]
  )

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <CompanyProfileLoaded data={transformedData} companyId={companyId} />
}

const CompanyProfileLoaded: React.FC<{data: TFormData; companyId: number}> = ({data, companyId}) => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const isWelcomeScreenEnabled = useIsWelcomeScreenEnabled()

  const countryOptions = useCountryOptions()
  const {
    user: {id: userId},
  } = useUserSettingsOrLogout()
  const updateCompanyMembersMutation = useUpdateCompanyMembersMutation()
  const updateCompanyProfileMutation = useUpdateCompanyProfileMutation()

  const methods = useForm<typeof data>({
    mode: "onTouched",
    resolver: zodResolver(validationSchema),
  })

  const {reset, getValues, setError} = methods

  React.useEffect(() => {
    reset(data)
  }, [data, reset])

  const handleSave = React.useCallback(async () => {
    const values = getValues()

    try {
      await Promise.all([
        updateCompanyMembersMutation.mutateAsync({companyId, members: values.member}),
        updateCompanyProfileMutation.mutateAsync({companyId, profile: values.company}),
      ])
      queryClient.refetchQueries({queryKey: queryKey.companyWelcomeScreen(userId), type: "all"})
      queryClient.refetchQueries({queryKey: queryKey.userSettings, type: "all"})

      toast.success(t("CompanyProfile_SaveSuccess"))

      if (isWelcomeScreenEnabled) {
        navigate("/")
      }
    } catch (e) {
      setFormErrorsFromAxios(e, setError, "company.profile")
    }
  }, [
    updateCompanyMembersMutation,
    companyId,
    updateCompanyProfileMutation,
    queryClient,
    userId,
    t,
    isWelcomeScreenEnabled,
    navigate,
    getValues,
    setError,
  ])

  const industryOptions = enumToOptions("Segment")

  return (
    <FormProvider {...methods}>
      <form className={"card"} onSubmit={methods.handleSubmit(handleSave)}>
        <TerseFormLayout>
          <TerseFormLayout.Header>{t("CompanyProfile_ContactInfo")}</TerseFormLayout.Header>

          <TerseFormLayout.FormField name={"member.first_name"} label={t("CompanyProfile_FirstName")} required>
            <InputConnected name={"member.first_name"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"member.last_name"} label={t("CompanyProfile_LastName")} required>
            <InputConnected name={"member.last_name"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"member.phone_number"} label={t("CompanyProfile_Phone")} required>
            <InputConnected name={"member.phone_number"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Header className={"mt-12"}>{t("CompanyProfile_CompanyInfo")}</TerseFormLayout.Header>

          <TerseFormLayout.FormField name={"company.name"} label={t("CompanyProfile_ProfileName")} required>
            <InputConnected name={"company.name"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"company.headline"} label={t("CompanyProfile_Headline")} required>
            <TextareaConnected name={"company.headline"} rows={2} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"company.website"} label={t("CompanyProfile_Website")} required>
            <InputConnected name={"company.website"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"company.country_id"} label={t("CompanyProfile_Country")} required>
            <DropdownConnected name={"company.country_id"} options={countryOptions} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"company.industry"} label={t("CompanyProfile_Industry")} required>
            <DropdownConnected name={"company.industry"} options={industryOptions} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField
            name={"company.number_of_employees"}
            label={t("CompanyProfile_NumEmployees")}
            required
          >
            <InputConnected name={"company.number_of_employees"} options={{valueAsNumber: true}} type={"number"} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Separator />

          <TerseFormLayout.FormField name={"company.description"} label={t("CompanyProfile_Description")} required>
            <TextareaConnected name={"company.description"} rows={5} />
          </TerseFormLayout.FormField>

          <TerseFormLayout.Header className={"mt-12"}>{t("CompanyProfile_Invoicing")}</TerseFormLayout.Header>

          <TerseFormLayout.FormField
            name={"company.additional_billing_email"}
            label={t("CompanyProfile_AdditionalBillingEmail")}
          >
            <InputConnected name={"company.additional_billing_email"} />
          </TerseFormLayout.FormField>

          <ButtonForm wrapperClassName={"col-start-2 justify-self-end mt-6"} disabled={!methods.formState.isDirty}>
            {t("CompanyProfile_SaveButton")}
          </ButtonForm>
        </TerseFormLayout>
      </form>
    </FormProvider>
  )
}

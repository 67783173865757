import React from "react"
import {FieldPath, FieldValues, useFormContext} from "react-hook-form"
import {UseFormRegisterReturn} from "react-hook-form/dist/types/form"
import {twMerge} from "tailwind-merge"

import Button from "../Button"
import {TBaseSharedProps, TConnectedField, useGetFieldVisibleError} from "./components"

export type TRadioValue = React.HTMLProps<HTMLInputElement>["value"]

export type TRadioRenderComponentProps = Omit<TRadioSharedProps, "RenderComponent"> & {checked?: boolean | null}

type TRadioSharedProps = TBaseSharedProps & {
  children?: React.ReactNode
  value?: TRadioValue
  RenderComponent?: React.ComponentType<TRadioRenderComponentProps>
}

type TRadioBaseProps = TRadioSharedProps &
  Partial<Omit<UseFormRegisterReturn, "onChange">> & {
    onChange: React.ChangeEventHandler<HTMLInputElement>
    checked?: boolean | null
  }

type TRadioConnectedProps<T extends FieldValues, N extends FieldPath<T>> = TRadioSharedProps & TConnectedField<T, N>

export const RadioBase = React.forwardRef<HTMLInputElement, TRadioBaseProps>(
  (
    {
      RenderComponent = DefaultRadioRenderComponent,
      checked,
      onChange,
      readOnly,
      disabled,
      hasError,
      children,
      ...props
    },
    ref
  ) => {
    const id = React.useId()

    const handleChange = React.useCallback<typeof onChange>(
      e => {
        if (readOnly || disabled) {
          return
        }

        onChange(e)
      },
      [disabled, onChange, readOnly]
    )

    return (
      <label
        className={twMerge([
          "group/radio relative cursor-pointer",
          readOnly && "cursor-default",
          disabled && "cursor-not-allowed",
        ])}
      >
        <input
          type={"radio"}
          className={"sr-only"}
          checked={!!checked}
          onChange={handleChange}
          readOnly={readOnly}
          disabled={disabled}
          id={id}
          {...props}
          ref={ref}
        />
        <RenderComponent checked={checked} disabled={disabled} hasError={hasError} readOnly={readOnly}>
          {children}
        </RenderComponent>
      </label>
    )
  }
)
RadioBase.displayName = "RadioBase"

export const DefaultRadioRenderComponent: React.FC<TRadioRenderComponentProps> = ({
  checked,
  children,
  hasError,
  disabled,
}) => {
  return (
    <div className={"flex items-center gap-3 select-none"}>
      <div
        className={twMerge([
          "relative flex items-center justify-center transition-all",
          "aspect-square h-4 rounded-full p-[3px]",
          "ring-transparent ring-offset-2 group-focus-within/radio:ring-2 group-focus-within/radio:ring-cr-blue",
          "border",
          checked ? "border-cr-blue bg-cr-blue" : "border-cr-grey-15 bg-cr-white",
          hasError && "ring-1 ring-cr-red group-focus-within/radio:ring-cr-red",
          disabled && "contrast-50 grayscale",
        ])}
      >
        <div
          className={twMerge(["aspect-square w-full rounded-full bg-cr-white transition-all", !checked && "scale-0"])}
        />
      </div>
      <div className={"text-sm text-cr-grey-80"}>{children}</div>
    </div>
  )
}

export const ButtonRadioRenderer: React.FC<TRadioRenderComponentProps> = ({
  checked,
  hasError,
  disabled,
  children,
  readOnly,
}) => {
  const handleClick = React.useCallback<React.MouseEventHandler<HTMLInputElement>>(e => {
    // Putting a button inside of a label doesn't work well, so we synthetically click the parent label as a workaround
    e.currentTarget.parentElement?.click()
    e.currentTarget.focus()
  }, [])

  return (
    <Button
      variant={checked ? "contained" : "outlined"}
      color={checked ? "blue" : "gray"}
      disabled={disabled}
      readOnly={readOnly}
      fullWidth={false}
      className={twMerge([hasError && "ring-1 ring-cr-red ring-offset-2 group-focus-within/radio:ring-cr-red"])}
      onClick={handleClick}
    >
      {children}
    </Button>
  )
}

export function RadioConnected<T extends FieldValues, N extends FieldPath<T>>({
  name,
  options,
  ...props
}: TRadioConnectedProps<T, N>) {
  const {register, watch} = useFormContext<T>()
  const hasError = !!useGetFieldVisibleError(name)
  const value = watch(name)
  const checked = value === props.value

  return <RadioBase {...register(name, options)} hasError={hasError} {...props} checked={!!checked} />
}

import React from "react"
// eslint-disable-next-line no-restricted-imports
import {Link as RouterLink, LinkProps} from "react-router"
import {twMerge} from "tailwind-merge"

import {TColor, TColorVariantClasses} from "../types"

export type TUnstyledLinkProps = {disabled?: boolean} & Omit<LinkProps, "to"> & {to?: LinkProps["to"]}

type TStyledProps = {
  variant?: TColor
  flipUnderline?: boolean
  noUnderline?: boolean
  loading?: boolean
} & TUnstyledLinkProps

const classNameByVariant = {
  primary: "text-cr-blue hover:text-cr-blue-dark",
  secondary: "text-cr-cyan hover:text-cr-cyan-dark",
  neutral: "text-cr-grey-50 hover:text-cr-grey-80",
  success: "text-cr-green hover:text-cr-green",
  warning: "text-cr-yellow hover:text-cr-yellow",
  error: "text-cr-red hover:text-cr-red",
} as const satisfies TColorVariantClasses

export const Link: React.FC<TStyledProps> = ({
  variant = "primary",
  className,
  disabled,
  loading,
  flipUnderline,
  noUnderline,
  children,
  ...restProps
}) => {
  const compiledClassName = React.useMemo(
    () =>
      twMerge(
        "underline transition-colors",
        restProps.to || restProps.onClick ? "cursor-pointer" : "cursor-default",
        (disabled || loading) && "cursor-not-allowed opacity-70 contrast-50",
        loading && "cursor-wait",
        !flipUnderline && "decoration-current hover:decoration-transparent",
        !flipUnderline && disabled && "hover:decoration-current",
        flipUnderline && "decoration-transparent hover:decoration-current",
        flipUnderline && disabled && "hover:decoration-transparent",
        noUnderline && "no-underline",
        classNameByVariant[variant],
        className
      ),
    [restProps.to, restProps.onClick, disabled, loading, flipUnderline, noUnderline, variant, className]
  )

  return (
    <UnstyledLink {...restProps} disabled={disabled || loading} className={compiledClassName}>
      {children}
    </UnstyledLink>
  )
}

export const UnstyledLink = React.forwardRef<HTMLAnchorElement, TUnstyledLinkProps>(
  ({className, disabled, children, ...restProps}, ref) => {
    if (restProps.to && !disabled) {
      return (
        <RouterLink to={restProps.to} {...restProps} className={className} ref={ref}>
          {children}
        </RouterLink>
      )
    }

    return (
      // eslint-disable-next-line react/forbid-elements
      <a
        role={"link"}
        {...restProps}
        className={className}
        ref={ref}
        onClick={disabled ? undefined : restProps.onClick}
      >
        {children}
      </a>
    )
  }
)
UnstyledLink.displayName = "UnstyledLink"

import React from "react"
import {twMerge} from "tailwind-merge"

import {useAutoUpdateRef} from "../../utils/ref.ts"
import {getColumnIndex} from "./utils/columns.ts"
import {TColumns, TypedTableContext} from "./utils/shared.ts"
import {ColumnSizeContext} from "./utils/useColumnSizes.ts"
import {DefaultCell} from "./Cell.tsx"

export type TTableRowOnClick<TRowData extends Record<string, any>> = (row: TRowData, event: React.MouseEvent) => void
export type TTableRowIsClickDisabled<TRowData extends Record<string, any>> = (row: TRowData) => boolean

export function TableRow<
  TCol extends TColumns,
  TRowData extends Record<string, any>,
  TMetadata extends Record<string, any> = Record<string, never>,
>({
  row,
  className,
  testId,
  onClick,
  rowIndex,
  pinnedColumn,
  isClickDisabled: isClickDisabledProp,
}: {
  row: TRowData
  className?: string
  testId?: string
  rowIndex: number
  pinnedColumn: TCol | null
} & (
  | {onClick: TTableRowOnClick<TRowData>; isClickDisabled?: TTableRowIsClickDisabled<TRowData> | boolean}
  | {onClick?: never; isClickDisabled?: never}
)): React.ReactNode {
  const {columnsMeta} = TypedTableContext<TCol, TRowData, TMetadata>().useContext()
  const ref = React.useRef<HTMLDivElement>(null)

  const rowRef = useAutoUpdateRef(row)
  const isClickDisabled =
    (typeof isClickDisabledProp === "function" ? isClickDisabledProp(row) : isClickDisabledProp) ?? false

  const handleClick = React.useMemo(() => {
    if (!onClick || isClickDisabled) {
      return undefined
    }

    return (e: React.MouseEvent) => {
      // Don't execute onClick if the click event comes from something else than the row itself
      let el = e.target as HTMLElement | null
      while (el !== ref.current) {
        if (el == null || el.onclick || ["a", "input", "button", "label"].includes(el.tagName.toLowerCase())) {
          return
        }

        el = el.parentElement
      }

      return onClick(rowRef.current, e)
    }
  }, [isClickDisabled, onClick, rowRef])

  const pinnedColumnIndex = getColumnIndex(columnsMeta, pinnedColumn) ?? -Infinity
  const {getColumnX} = ColumnSizeContext.useContext()

  return (
    <div
      onClick={handleClick}
      className={twMerge(["group/row contents", handleClick && "cursor-pointer"])}
      ref={ref}
      data-testid={testId}
    >
      {columnsMeta.map((columnMeta, colIndex) => {
        const CellRenderer = columnMeta.Cell ?? DefaultCell

        const isPinned = colIndex <= pinnedColumnIndex
        const pinX = isPinned ? getColumnX(columnMeta.column) : null

        return (
          <CellRenderer
            key={columnMeta.column}
            row={row}
            columnMeta={columnMeta}
            className={className}
            rowIndex={rowIndex}
            colIndex={colIndex}
            pinX={pinX}
          />
        )
      })}
    </div>
  )
}

import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {ChevronLeftIcon} from "@heroicons/react/20/solid"
import {useQueryClient} from "@tanstack/react-query"

import {ButtonLoading} from "../../../components/Button.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Link} from "../../../components/Link.tsx"
import {VideoModal} from "../../../components/VideoModal.tsx"
import {useCheckoutCreateMutationNew} from "../../../queries/checkout.ts"
import {queryKey} from "../../../services"
import requestError from "../../../services/requestError.tsx"
import {APackageWithPrice} from "../../../services/types.generated.ts"
import {useIsWelcomeScreenEnabled} from "../../../utils"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.tsx"
import {useNumParam} from "../../../utils/hooks.tsx"
import {commonTransComponents} from "../../../utils/i18n.tsx"
import {OnboardingLayout} from "../components/OnboardingLayout.tsx"
import {SalespersonPackagesCard} from "../components/SalespersonPackagesCard.tsx"
import {ECreateSalesCyclePage, getCreateSellingLink, isPlaceholderSalesperson, TSalesPerson} from "../utils.ts"

export const SalespeoplePackages: React.FC<{
  salesPeople: TSalesPerson[]
  onBack: () => void
}> = ({onBack, salesPeople}) => {
  const {t} = useTranslation()

  const isWelcomeScreenEnabled = useIsWelcomeScreenEnabled()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const salesCycleId = useNumParam("salesCycleId")

  const [currentVideoId, setCurrentVideoId] = React.useState<string | null>(null)
  const [selected, setSelected] = React.useState<{
    [salesPersonId: number]: {salesPerson: TSalesPerson; pkg: APackageWithPrice | null}
  }>({})

  React.useEffect(() => {
    setSelected(
      salesPeople.reduce(
        (ret, salesPerson) => ({...ret, [salesPerson.id]: {salesPerson, pkg: salesPerson.packages?.[0] ?? null}}),
        {} as typeof selected
      )
    )
  }, [salesPeople])

  const checkoutCreateMutation = useCheckoutCreateMutationNew()

  const handleSubmit = React.useCallback(async () => {
    try {
      const response = await checkoutCreateMutation.mutateAsync({
        salesCycleId,
        packages: Object.values(selected)
          .filter(entry => entry.pkg != null)
          .map(({pkg, salesPerson}) => ({
            packageId: pkg!.id,
            salesPersonId: isPlaceholderSalesperson(salesPerson) ? undefined : salesPerson.id,
          })),
      })

      isWelcomeScreenEnabled && emitGTMEvent({event: EGTMEvent.COMPANY_SALES_PERSON_REQUESTED})

      queryClient.resetQueries({queryKey: queryKey.checkoutPreview(response.data.order.id)})

      navigate(
        getCreateSellingLink({salesCycleId, page: ECreateSalesCyclePage.BENEFITS, orderId: response.data.order.id})
      )
    } catch (e) {
      requestError(e)
    }
  }, [checkoutCreateMutation, isWelcomeScreenEnabled, navigate, queryClient, salesCycleId, selected])

  const handleChangePackage = (salesPerson: TSalesPerson) => (pkg: APackageWithPrice) => {
    setSelected(prev => ({...prev, [salesPerson.id]: {salesPerson, pkg}}))
  }

  const isNoPackagePicked = Object.values(selected).every(pkg => pkg === null)

  return (
    <OnboardingLayout
      title={t("CreateSalesCycle_Salespeople_Packages_Title")}
      subtitle={t("CreateSalesCycle_Salespeople_Packages_Subtitle")}
      step={3}
      total={3}
      leftAction={
        <Link onClick={onBack} flipUnderline variant={"neutral"} className={"inline-flex items-center gap-1"}>
          <ChevronLeftIcon className={"size-5"} />
          {t("CreateSalesCycle_Back")}
        </Link>
      }
      rightAction={
        <ButtonLoading onClick={handleSubmit} disabled={isNoPackagePicked}>
          {t("CreateSalesCycle_Salespeople_Packages_SubmitButton")}
        </ButtonLoading>
      }
    >
      <LayoutBlock innerClassName={"flex flex-wrap gap-7 justify-center"}>
        {Object.values(selected).map(({salesPerson, pkg}) => {
          return (
            <SalespersonPackagesCard
              key={salesPerson.id}
              salesPerson={salesPerson}
              onOpenVideo={() => setCurrentVideoId(salesPerson.embed_video_id ?? null)}
              value={pkg}
              onChange={handleChangePackage(salesPerson)}
            />
          )
        })}
        <div className={"basis-full text-center text-sm text-cr-grey-50"}>
          <Trans i18nKey={"CreateSalesCycle_Salespeople_Packages_Disclaimer"} components={commonTransComponents} />
        </div>
      </LayoutBlock>
      <VideoModal onClose={() => setCurrentVideoId(null)} youtubeId={currentVideoId} />
    </OnboardingLayout>
  )
}

import React from "react"
import {useTranslation} from "react-i18next"
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react"
import {ChevronDownIcon, MagnifyingGlassIcon} from "@heroicons/react/16/solid"
import {UseQueryResult} from "@tanstack/react-query"

import {useDebouncedValue} from "../utils/hooks"
import {CheckboxBase} from "./fields/Checkbox"
import {InputBase} from "./fields/Input"
import {DefaultToken} from "./formElements/MultiStringInput.tsx"
import {Badge} from "./Badge"
import {UnstyledLink} from "./Link"
import {Loading} from "./Loading"

export type TFilterOption<T extends string | number = string> = {
  name: string
  value: T
}

export const AutocompleteFilter: React.FC<{
  title: React.ReactNode
  value: TFilterOption[]
  useAutocompleteQuery: (searchString: string) => UseQueryResult<TFilterOption[]>
  onChange: (newValue: TFilterOption[]) => void
}> = ({title, value, useAutocompleteQuery, onChange}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const {context, refs, floatingStyles} = useFloating({
    placement: "bottom-end",
    middleware: [flip(), offset(10), shift({padding: 16})],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss])
  const {styles} = useTransitionStyles(context, {
    duration: {open: 100, close: 75},
    initial: {opacity: 0, transform: "scale(0.95)"},
    open: {opacity: 1, transform: "scale(1)"},
    common: ({side}) => ({
      transformOrigin: {
        top: "bottom",
        bottom: "top",
        left: "right",
        right: "left",
      }[side],
    }),
  })

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <UnstyledLink className={"flex cursor-pointer items-center gap-2"}>
          <span>{title}</span>
          {value.length > 0 && (
            <Badge color={"blue"} className={"px-2.5 py-1 text-xs"}>
              {value.length}
            </Badge>
          )}
          <ChevronDownIcon className={"size-5"} />
        </UnstyledLink>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div className={"absolute z-100"} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <div style={{...styles}} className={"flex max-w-80 flex-col gap-5 floating-card"}>
              <AutocompleteFilterContent
                useAutocompleteQuery={useAutocompleteQuery}
                onChange={onChange}
                value={value}
              />
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export function AutocompleteFilterContent<T extends string | number = string>({
  useAutocompleteQuery,
  onChange,
  value,
  valueOnTop,
}: {
  useAutocompleteQuery: (searchString: string) => UseQueryResult<Array<TFilterOption<T>>>
  onChange: (newValue: Array<TFilterOption<T>>) => void
  value: Array<TFilterOption<T>>
  valueOnTop?: boolean
}): React.ReactNode {
  const {t} = useTranslation()

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const {data, isFetching} = useAutocompleteQuery(searchStringDebounced)

  const handleChangeOption = React.useCallback(
    (option: TFilterOption<T>) => () => {
      const valueWithoutOption = value.filter(valueOption => valueOption.value !== option.value)

      if (valueWithoutOption.length === value.length) {
        return onChange([...value, option])
      }

      return onChange(valueWithoutOption)
    },
    [onChange, value]
  )

  return (
    <>
      {valueOnTop && value.length > 0 && (
        <div className={"flex flex-wrap gap-2"}>
          {value.map(valueOption => (
            <DefaultToken key={valueOption.value} value={valueOption.name} onRemove={handleChangeOption(valueOption)} />
          ))}
        </div>
      )}
      <InputBase
        value={searchString}
        onChange={e => setSearchString(e.target.value)}
        Icon={isFetching ? "loading" : MagnifyingGlassIcon}
      />
      {!valueOnTop && value.length > 0 && (
        <div className={"flex flex-wrap gap-2"}>
          {value.map(valueOption => (
            <DefaultToken key={valueOption.value} value={valueOption.name} onRemove={handleChangeOption(valueOption)} />
          ))}
        </div>
      )}
      {isFetching && <Loading size={"sm"} color={"gray"} />}
      {!isFetching && data && data.length > 0 && (
        <div className={"flex flex-col gap-2"}>
          {data.map(opt => (
            <CheckboxBase
              key={opt.value}
              onChange={handleChangeOption(opt)}
              checked={value.some(val => val.value === opt.value)}
            >
              <span className={"line-clamp-1 break-all"}>{opt.name}</span>
            </CheckboxBase>
          ))}
        </div>
      )}
      {!isFetching && data?.length === 0 && searchStringDebounced.length > 0 && (
        <div className={"text-center text-sm"}>{t("NoResults")}</div>
      )}
    </>
  )
}

import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {FaceSmileIcon} from "@heroicons/react/24/outline"

import {Alert} from "../../components/Alert"
import {Loading} from "../../components/Loading"
import {ASalesPersonDashboardSalesCyclesDetail} from "../../services/types.generated"
import {Selling} from "./Selling"
import {useSalesPersonDashboardQuery} from "./utils"

const SellingsLoaded: FC<{
  salesCycles?: ASalesPersonDashboardSalesCyclesDetail
}> = ({salesCycles}) => {
  const {t} = useTranslation()

  if (!salesCycles?.length) {
    return (
      <div className={"flex flex-col items-center gap-8"}>
        <FaceSmileIcon className={"h-12 w-12 text-cr-grey-50"} />

        <h3 className={"text-2xl font-bold text-cr-grey-80"}>
          {t("SalespersonDashboard_Here will be your sales plans")}
        </h3>
        <p className={"text-md text-cr-grey-50"}>
          {t("SalespersonDashboard_After the start you will see all information about your sales progress here.")}
        </p>
      </div>
    )
  }

  return (
    <>
      {salesCycles.map(salesCycle => (
        <Selling key={salesCycle.id} salesCycle={salesCycle} />
      ))}
    </>
  )
}

export const Sellings: FC = () => {
  const {t} = useTranslation()

  const {data, isPending, isError} = useSalesPersonDashboardQuery()
  const salesCycles = data?.data.dashboard?.sales_cycles

  return (
    <div className={"flex justify-center px-6 py-16 lg:px-8"}>
      <section className={"flex w-full max-w-(--breakpoint-2xl) flex-col gap-10"}>
        <h1 className={"mr-8 text-3xl font-semibold text-cr-blue"}>{t("SalespersonDashboard_ActiveSales_Header")}</h1>

        {isPending ? (
          <Loading size={"xl"} containerClassName={"h-[200px]"} />
        ) : isError ? (
          <Alert title={t("SalespersonDashboard_ActiveSales_LoadingError_Title")} variant={"error"}>
            {t("GenericError_Text")}
          </Alert>
        ) : (
          <SellingsLoaded salesCycles={salesCycles} />
        )}
      </section>
    </div>
  )
}

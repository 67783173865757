import React from "react"
import {useTranslation} from "react-i18next"
import {PieTooltipProps, ResponsivePie} from "@nivo/pie"
import {twMerge} from "tailwind-merge"

import {Loading} from "../../../../components/Loading"
import {useReportsMetricsQuery} from "../../../../queries/reports"
import {useCurrentAssignmentId, useCurrentSelectionState} from "../../hooks"
import {DataLegend, TChartDataItem} from "../DataLegend"
import {MetricGraphCard} from "./MetricGraphCard"

export const WonLostGraphCard: React.FC = () => {
  const {t} = useTranslation()

  const {
    value: {salesCycleId, iterationId},
  } = useCurrentSelectionState()
  const assignmentId = useCurrentAssignmentId()

  const {data, isFetching, isLoading} = useReportsMetricsQuery(salesCycleId, iterationId, assignmentId)

  const graphData = React.useMemo(() => {
    return [
      {
        id: "won",
        label: t("Reports_WonLost_Won"),
        value: data?.counters.won ?? 0,
        color: "var(--color-cr-blue)",
      },
      {
        id: "opportunities",
        label: t("Reports_WonLost_Opportunities"),
        value: data?.counters.opportunities ?? 0,
        color: "var(--color-cr-indigo-mid)",
      },
      {
        id: "lost",
        label: t("Reports_WonLost_Lost"),
        value: data?.counters.lost ?? 0,
        color: "var(--color-cr-blue-light)",
      },
    ].filter(datum => datum.value !== 0) satisfies TChartDataItem[]
  }, [data?.counters.lost, data?.counters.opportunities, data?.counters.won, t])

  return (
    <MetricGraphCard
      className={twMerge(
        "flex shrink-0 grow-0 basis-[417px] flex-col gap-6 p-6",
        isFetching && "pointer-events-none animate-pulse"
      )}
      title={t("Reports_WonLost_Title")}
      hideAssignmentName
    >
      <DataLegend data={graphData} isPercentage precision={0} />

      <div className={"flex h-64 items-center justify-center"}>
        {isLoading ? (
          <Loading delayShow={false} />
        ) : graphData.length === 0 ? (
          <span className={"text-sm text-cr-grey-50"}>{t("Reports_NoData")}</span>
        ) : (
          <div className={"contents"} data-testid={"chart"}>
            <ResponsivePie
              data={graphData}
              padAngle={1}
              innerRadius={0.7}
              cornerRadius={0}
              colors={dataDef => dataDef.data.color}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              tooltip={Tooltip}
              motionConfig={"stiff"}
              margin={{top: 8, bottom: 16}}
            />
          </div>
        )}
      </div>
    </MetricGraphCard>
  )
}

export const Tooltip: React.FC<PieTooltipProps<{label: string; value: number; color: string}>> = ({datum}) => {
  return (
    <div className={"flex min-w-32 flex-col items-center gap-2 card-shadow px-4 py-2"}>
      <span className={"text-sm font-semibold"}>{datum.label}</span>
      <span className={"text-2xl font-bold"}>{datum.value}</span>
    </div>
  )
}

import {TColumnMeta, TColumns, TColumnsMeta, TColumnsMetaWithEmpty} from "./shared.ts"

export function getColumnMeta<TCol extends TColumns, TRowData extends Record<string, any>>(
  columnsMeta: TColumnsMeta<TCol, TRowData>,
  column: TCol | null | undefined
): TColumnMeta<TCol, TRowData> | undefined {
  return columnsMeta.find(colMeta => colMeta && colMeta.column === column)
}

export function getColumnIndex<TCol extends TColumns, TRowData extends Record<string, any>>(
  columnsMeta: TColumnsMeta<TCol, TRowData>,
  column: TCol | undefined | null
) {
  if (!column) {
    return null
  }

  const index = columnsMeta.findIndex(meta => meta.column === column)
  return index === -1 ? null : index
}

export function isLastColumn<TCol extends TColumns, TRowData extends Record<string, any>>(
  columnsMeta: TColumnsMeta<TCol, TRowData>,
  column: TCol | undefined
): boolean {
  return getColumnIndex(columnsMeta, column) === columnsMeta.length - 1
}

export function isFirstColumn<TCol extends TColumns, TRowData extends Record<string, any>>(
  columnsMeta: TColumnsMeta<TCol, TRowData>,
  column: TCol | undefined
): boolean {
  return getColumnIndex(columnsMeta, column) === 0
}

export function getColumnsFromMeta<TCol extends TColumns, TRowData extends Record<string, any>>(
  columnsMeta: TColumnsMetaWithEmpty<TCol, TRowData>
): TCol[] {
  return columnsMeta.filter(meta => !!meta).map(col => col.column)
}

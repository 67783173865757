import React from "react"
import {useTranslation} from "react-i18next"
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react"
import {XMarkIcon} from "@heroicons/react/20/solid"
import {FunnelIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {IconButton} from "../Button.tsx"
import {Link} from "../Link.tsx"
import {TColumnMeta, TColumns} from "./utils/shared.ts"

export type TFilterContentProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
}

type TTableFilterButtonProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
}

export function FilterButton<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
}: TTableFilterButtonProps<TCol, TRowData>): React.ReactNode {
  const {t} = useTranslation()

  const isFiltered = !!columnMeta.isFiltered?.(columnMeta)

  const [isOpen, setIsOpen] = React.useState(false)

  const {context, refs, floatingStyles} = useFloating({
    placement: "bottom-end",
    middleware: [flip(), offset(10), shift({padding: 16})],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss])
  const {styles} = useTransitionStyles(context, {
    duration: {open: 100, close: 75},
    initial: {opacity: 0, transform: "scale(0.95)"},
    open: {opacity: 1, transform: "scale(1)"},
    common: ({side}) => ({
      transformOrigin: {
        top: "bottom",
        bottom: "top",
        left: "right",
        right: "left",
      }[side],
    }),
  })

  if (!columnMeta.FilterContent) {
    return null
  }

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <IconButton className={twMerge("size-7", isFiltered ? "bg-cr-blue-light! text-cr-blue" : "text-cr-grey-65")}>
          <FunnelIcon className={"size-4"} />
        </IconButton>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div className={"absolute z-100"} ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <div style={{...styles}} className={"flex max-w-80 flex-col gap-4 floating-card"}>
              <columnMeta.FilterContent columnMeta={columnMeta} />

              {!!columnMeta.clearFilter && columnMeta.isFiltered?.(columnMeta) && (
                <Link
                  onClick={() => columnMeta.clearFilter?.(columnMeta)}
                  variant={"error"}
                  className={"flex items-center border-t border-t-cr-grey-15 pt-3 text-xs"}
                  flipUnderline
                >
                  <span className={"grow text-right"}>{t("ClearFilter")}</span>
                  <XMarkIcon className={"size-4"} />
                </Link>
              )}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

import React from "react"
import {Trans} from "react-i18next"

import {i18n} from "../../i18n.ts"
import {AGoals} from "../../services/types.generated.ts"
import {BulletedTransList} from "../../utils/i18n.tsx"
import {transComponents} from "./utils.tsx"

export const SalaryTooltip: React.FC<{
  goal: AGoals
  reaches: number | undefined | null
  minimumMeetings: number | undefined | null
}> = ({goal, reaches, minimumMeetings}) => {
  return (
    <div className={"flex w-80 flex-col gap-4 text-sm whitespace-break-spaces"}>
      <span className={"font-bold"}>{salaryTooltipByGoal[goal].title}</span>
      <BulletedTransList>{salaryTooltipByGoal[goal].text(reaches ?? 0, minimumMeetings ?? 0)}</BulletedTransList>
    </div>
  )
}

const salaryTooltipByGoal = {
  [AGoals.CloseDeal]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Salary_CloseDeal_Title"),
    text: (reaches: number, minimumMeetings: number) => (
      <Trans
        components={transComponents}
        i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Salary_CloseDeal_Text"}
        values={{reaches, minimumMeetings}}
      />
    ),
  },
  [AGoals.SetupMeeting]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Salary_SetupMeeting_Title"),
    text: (reaches: number, minimumMeetings: number) => (
      <Trans
        components={transComponents}
        i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Salary_SetupMeeting_Text"}
        values={{reaches, minimumMeetings}}
      />
    ),
  },
  [AGoals.ContractConclusion]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Salary_ContractConclusion_Title"),
    text: (reaches: number, minimumMeetings: number) => (
      <Trans
        components={transComponents}
        i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Salary_ContractConclusion_Text"}
        values={{reaches, minimumMeetings}}
      />
    ),
  },
  [AGoals.Registration]: {
    title: i18n.t("SalespersonDashboard_NewOffer_Tooltip_Salary_Registration_Title"),
    text: (reaches: number, minimumMeetings: number) => (
      <Trans
        components={transComponents}
        i18nKey={"SalespersonDashboard_NewOffer_Tooltip_Salary_Registration_Text"}
        values={{reaches, minimumMeetings}}
      />
    ),
  },
} satisfies {[goal in AGoals]: {title: string; text: (reaches: number, minimumMeetings: number) => React.ReactNode}}

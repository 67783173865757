import React from "react"

import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {ASaasCompanyUserListItem} from "../../../services/types.generated.ts"
import {getFullName} from "../../../utils"

export const UserCell: React.FC<{row: ASaasCompanyUserListItem}> = ({row}) => {
  const fullName = getFullName(row)

  return (
    <div className={"flex items-center gap-4"}>
      <ProfileImage src={row.profile_picture_thumbnail_url ?? undefined} className={"size-10 shrink-0"} />
      <div className={"flex flex-col text-sm"}>
        {fullName && <span className={"font-semibold"}>{fullName}</span>}
        <span className={"text-cr-grey-50"}>{row.email}</span>{" "}
      </div>
    </div>
  )
}

import React from "react"
import {useTranslation} from "react-i18next"

import {Alert, GenericErrorAlert} from "../../../components/Alert"
import {CheckboxBase} from "../../../components/fields/Checkbox.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock"
import {Loading} from "../../../components/Loading"
import {EOrderDirection} from "../../../components/Table/utils/shared.ts"
import {sortDate} from "../../../components/Table/utils/sortFunctions.ts"
import {
  TAdminsProspectsAssignment,
  TAdminsProspectsIteration,
  useBulkDisapproveProspectsMutation,
  useProspectsSalesCycleQuery,
} from "../../../queries/prospects"
import {AAdminsProspectsSalesCycle} from "../../../services/types.generated"
import {CombineProviders} from "../../../utils/context.tsx"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam} from "../../../utils/hooks"
import {lifetimeSymbol, PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {AddProspectsModal} from "../shared/AddProspectsModal"
import {EFeature} from "../shared/AddProspectsModal/utils.ts"
import {
  DeletingContext,
  EditingContext,
  IsUploadingContext,
  ProspectsContext,
  RejectContext,
  TProspectContext,
  TRejectProspect,
  useProspectsContextValue,
  WarningsOnlyContext,
} from "../shared/context"
import {DeleteModal} from "../shared/DeleteModal.tsx"
import {EditingFlyout} from "../shared/EditingFlyout.tsx"
import {RejectModal, TRejectSubmitParams} from "../shared/RejectModal"
import {getActiveAssignment, salesCycleRemoveIterationsWithoutAssignments} from "../shared/utils.ts"
import {StatusBanner} from "./components/StatusBanner"
import {ProspectsIteration} from "./ProspectsIteration.tsx"
import {ProspectsLifetime} from "./ProspectsLifetime.tsx"

export const AdminProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {data, error, refetch} = useProspectsSalesCycleQuery(salesCycleId)

  const salesCycle = React.useMemo(() => {
    if (!data) {
      return null
    }

    return salesCycleRemoveIterationsWithoutAssignments(data)
  }, [data])

  if (error) {
    return <GenericErrorAlert retry={refetch} />
  }

  if (!data) {
    return <Loading size={"xl"} />
  }

  return <ProspectsLoaded salesCycle={salesCycle as AAdminsProspectsSalesCycle} />
}

export const ProspectsLoaded: React.FC<{
  salesCycle: AAdminsProspectsSalesCycle
}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const isUploadingContextValue = IsUploadingContext.useProviderValue(false)
  const editingContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const prospectsContextValue = ProspectsContext.useProviderValue(useProspectsContextValue(salesCycle))
  const {iteration, assignment, setValue: setProspectsContext} = prospectsContextValue

  const warningsOnlyContextValue = WarningsOnlyContext.useProviderValue(false)

  const activeIteration = iteration as TAdminsProspectsIteration | undefined
  const activeAssignment = assignment as TAdminsProspectsAssignment | undefined

  React.useEffect(() => {
    if ((activeAssignment && activeAssignment.prospects_with_warning_count > 0) || !warningsOnlyContextValue.value) {
      return
    }

    warningsOnlyContextValue.setValue(false)
  }, [activeAssignment, warningsOnlyContextValue])

  const [rejectingProspects, setRejectingProspects] = React.useState<TRejectProspect[] | null>(null)
  const rejectContextValue = React.useMemo<TProspectContext>(
    () => ({
      prospects: rejectingProspects,
      setProspects: setRejectingProspects,
      rejectionsLeft: null,
    }),
    [rejectingProspects]
  )

  const bulkDisapproveMutation = useBulkDisapproveProspectsMutation()

  const handleRejectSubmit = React.useCallback(
    (params: TRejectSubmitParams) => {
      return bulkDisapproveMutation.mutateAsync({
        ids: params.prospects.map(({id}) => id),
        disapprove_reason: params.disapprove_reason,
        disapprove_reason_text: params.disapprove_reason_text,
      })
    },
    [bulkDisapproveMutation]
  )

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(sortDate<TAdminsProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC))
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      const newIteration = salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate)

      setProspectsContext({
        salesCycleId: salesCycle.id,
        iterationId: newIteration?.id,
        assignmentId: newIteration && getActiveAssignment(newIteration, activeAssignment)?.id,
      })
    },
    [activeAssignment, salesCycle.id, salesCycle.sales_cycle_iterations, setProspectsContext]
  )

  return (
    <CombineProviders
      providers={[
        ProspectsContext.combined(prospectsContextValue),
        IsUploadingContext.combined(isUploadingContextValue),
        DeletingContext.combined(deleteContextValue),
        EditingContext.combined(editingContextValue),
        RejectContext.combined(rejectContextValue),
        WarningsOnlyContext.combined(warningsOnlyContextValue),
      ]}
    >
      <div className={"flex flex-col gap-9 py-16"}>
        <LayoutBlock innerClassName={"flex items-start justify-between"}>
          <PeriodToggle
            periods={iterationOptions}
            value={activeIteration?.start_date ?? lifetimeSymbol}
            onChange={handleChangeIteration}
          />

          {activeIteration && <StatusBanner iteration={activeIteration} />}
        </LayoutBlock>
        {activeAssignment && activeAssignment.prospects_with_warning_count > 0 && (
          <LayoutBlock>
            <Alert
              variant={"warning"}
              title={
                <div className={"flex flex-wrap justify-between gap-10"}>
                  <span>
                    {t("Prospects_WarningsAlert_Title", {
                      count: activeAssignment.prospects_with_warning_count,
                    })}
                  </span>
                  <CheckboxBase
                    onChange={e => warningsOnlyContextValue.setValue(e.target.checked)}
                    checked={warningsOnlyContextValue.value}
                    toggle
                  >
                    {t("Prospects_WarningsAlert_Text")}
                  </CheckboxBase>
                </div>
              }
            ></Alert>
          </LayoutBlock>
        )}

        {activeIteration ? (
          <ProspectsIteration salesCycle={salesCycle} />
        ) : (
          <ProspectsLifetime salesCycle={salesCycle} />
        )}
      </div>

      <EditingFlyout />
      <DeleteModal />
      <AddProspectsModal enabledFeatures={[EFeature.XLS, EFeature.Single, EFeature.Leads]} />
      {rejectingProspects && <RejectModal onSubmit={handleRejectSubmit} />}
    </CombineProviders>
  )
}

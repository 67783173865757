import React from "react"
import {useFormContext, useFormState} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {CheckIcon} from "@heroicons/react/20/solid"
import {useMutationState} from "@tanstack/react-query"
import {twMerge} from "tailwind-merge"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {ButtonForm} from "../../../components/Button.tsx"
import {CheckboxConnected} from "../../../components/fields/Checkbox.tsx"
import {FieldErrorMessage} from "../../../components/fields/components.tsx"
import {FieldLabel} from "../../../components/fields/FieldLabel.tsx"
import {RadioConnected} from "../../../components/fields/RadioButton.tsx"
import {InputAction} from "../../../components/InputAction.tsx"
import {Link} from "../../../components/Link.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {Tooltip} from "../../../components/Tooltip.tsx"
import {getCurrentLanguage, i18n} from "../../../i18n.ts"
import {useUpdateCheckoutMutation} from "../../../queries/checkout.ts"
import {queryKey} from "../../../services"
import {
  ACurrency,
  AOrder,
  AOrderItem,
  APaymentMethods,
  AVoucherCodeResultStatusEnum,
} from "../../../services/types.generated.ts"
import {renderPrice} from "../../../utils"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useNumParam} from "../../../utils/hooks.tsx"
import {BulletedTransList, commonTransComponents} from "../../../utils/i18n.tsx"

const paymentMethodOptions = [
  {value: APaymentMethods.Card, title: i18n.t("CreateSalesCycle_Checkout_Summary_Card")},
  {value: APaymentMethods.BankTransfer, title: i18n.t("CreateSalesCycle_Checkout_Summary_BankTransfer")},
]

export const CheckoutSummary: React.FC<{order: AOrder}> = ({order}) => {
  const {t} = useTranslation()

  const {clearErrors, setValue} = useFormContext()
  const {isValid} = useFormState()

  const orderId = useNumParam("orderId")
  const updateCheckoutMutation = useUpdateCheckoutMutation()

  const mutationStates = useMutationState({filters: {mutationKey: queryKey.checkoutUpdate}})
  const mutationState = mutationStates[mutationStates.length - 1]
  const isLoading = mutationState?.status === "pending"
  const isError = mutationState?.status === "error"

  if (isError) {
    return <GenericErrorAlert />
  }

  const handleApplyVoucher = (voucher: string) => {
    clearErrors("voucher_code_input")
    setValue("voucher_code_input", voucher, {
      shouldValidate: true,
    })
    return updateCheckoutMutation.mutateAsync({orderId, order: {voucher_code_input: voucher}})
  }

  const isVoucherValid = order.voucher_code_result?.status === AVoucherCodeResultStatusEnum.Valid
  const voucherMessage = order.voucher_code_result?.message

  const {currency} = order

  return (
    <div className={"relative isolate overflow-hidden card p-0"}>
      <div
        className={twMerge(
          "pointer-events-none absolute inset-0 z-10 opacity-0 transition-all",
          isLoading && "pointer-events-auto opacity-90"
        )}
      >
        <div className={"absolute inset-0 animate-pulse bg-cr-white"} />
      </div>
      <div className={"flex flex-col gap-10 border-b p-8"}>
        {order.order_items.map(item => (
          <SummaryItem
            key={item.id}
            item={item}
            currency={currency}
            tooltipContent={
              !!item.package?.reaches && (
                <div className={"flex flex-col gap-4"}>
                  <h3 className={"font-bold uppercase"}>{t("CreateSalesCycle_Checkout_WhatsIncluded_Title")}</h3>
                  <BulletedTransList>
                    <Trans
                      i18nKey={"CreateSalesCycle_Checkout_WhatsIncluded_OrderItemList"}
                      values={{reaches: item.package.reaches}}
                      components={{
                        ...commonTransComponents,
                        bullet: <CheckIcon className={"size-6 translate-y-1.5 text-cr-green"} />,
                      }}
                    />
                  </BulletedTransList>
                  <div>{t("CreateSalesCycle_Checkout_WhatsIncluded_MinimumDuration")}</div>
                </div>
              )
            }
          />
        ))}
      </div>
      <div className={"border-b p-8"}>
        {order.service_items.map(item => (
          <SummaryItem
            key={item.id}
            item={item}
            oneOff
            currency={currency}
            tooltipContent={
              <div className={"flex flex-col gap-4"}>
                <h3 className={"font-bold uppercase"}>{t("CreateSalesCycle_Checkout_WhatsIncluded_Title")}</h3>
                <BulletedTransList>
                  <Trans
                    i18nKey={"CreateSalesCycle_Checkout_WhatsIncluded_SummaryList"}
                    components={{
                      ...commonTransComponents,
                      bullet: <CheckIcon className={"size-6 translate-y-1.5 text-cr-green"} />,
                    }}
                  />
                </BulletedTransList>
              </div>
            }
          />
        ))}
      </div>
      <div className={"border-b p-8"}>
        <div className={"flex justify-between text-xl font-semibold"}>
          <h4>{t("CreateSalesCycle_Checkout_Summary_Total")}</h4>
          <p>{renderPrice(order.total_price, currency)}</p>
        </div>
      </div>
      <div className={"border-b p-8"}>
        <FieldLabel
          name={"voucher_code_input"}
          label={
            <div className={"flex items-baseline justify-between gap-4 pb-4"}>
              <div className={"text-base whitespace-nowrap"}>{t("CreateSalesCycle_Checkout_Summary_PromoCode")}</div>
              <div
                className={twMerge([
                  "text-right text-sm font-normal",
                  isVoucherValid ? "text-cr-green" : "text-cr-red",
                ])}
              >
                {updateCheckoutMutation.isPending ? (
                  <Loading size={"xs"} delayShow={false} containerClassName={"justify-end"} />
                ) : (
                  voucherMessage
                )}
              </div>
            </div>
          }
          errorMessageProps={{reserveSpace: false}}
        >
          <InputAction
            initialValue={order.voucher_code_input ?? ""}
            disableClearOnSubmit
            onAction={handleApplyVoucher}
            allowSubmitEmpty
            actionLabel={t("CreateSalesCycle_Checkout_Summary_ApplyVoucher")}
          />
        </FieldLabel>
      </div>
      <div className={"border-b p-8"}>
        <FieldLabel
          name={"payment_method"}
          label={<span className={"text-base"}>{t("CreateSalesCycle_Checkout_Summary_PaymentMethod")}</span>}
          errorMessageProps={{reserveSpace: false}}
        >
          <div className={"mt-2 flex gap-8"}>
            {paymentMethodOptions.map(paymentMethod => (
              <div key={paymentMethod.value} className={"grow"}>
                <RadioConnected name={"payment_method"} key={paymentMethod.value} value={paymentMethod.value}>
                  {paymentMethod.title}
                </RadioConnected>
              </div>
            ))}
          </div>
        </FieldLabel>
      </div>
      <div className={"flex flex-col gap-8 p-8"}>
        <div className={"flex flex-col gap-2"}>
          <CheckboxConnected name={"terms_of_service"}>
            <span className={"required-after text-xs"}>
              <Trans
                i18nKey={"CreateSalesCycle_Checkout_Summary_Terms"}
                components={{
                  ...commonTransComponents,
                  tacLink: <Link flipUnderline to={t("Url_TermsAndConditions_Company")} target={"_blank"} />,
                  privacyLink: <Link flipUnderline to={t("Url_PrivacyPolicy")} target={"_blank"} />,
                }}
              />
            </span>
          </CheckboxConnected>
          <FieldErrorMessage name={"terms_of_service"} reserveSpace />
        </div>

        <ButtonForm disabled={!isValid} fullWidth size={"xl"}>
          {t("CreateSalesCycle_Checkout_Summary_Submit")}
        </ButtonForm>

        <div className={"text-center text-xs text-cr-grey-50"}>
          {order.next_payment_date && order.next_payment_total_price && (
            <Trans
              i18nKey={"CreateSalesCycle_Checkout_Summary_NextPayment"}
              values={{
                date: apiDateToJS(order.next_payment_date).toLocaleString(getCurrentLanguage(), {dateStyle: "medium"}),
                price: renderPrice(order.next_payment_total_price, order.currency, {noDecimal: true}),
              }}
              components={commonTransComponents}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const SummaryItem: React.FC<{
  oneOff?: boolean
  item: AOrderItem
  currency: ACurrency
  tooltipContent?: React.ReactNode
}> = ({oneOff, item, currency, tooltipContent}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex gap-8"}>
      {!oneOff && (
        <ProfileImage
          src={item.sales_person?.profile_picture_thumbnail_url ?? undefined}
          className={twMerge("size-16", item.sales_person == null && "bg-cr-blue-mid text-cr-white")}
        />
      )}
      <div className={"grid grow grid-cols-[auto_min-content_min-content] items-center gap-x-6 gap-y-0"}>
        <h4 className={"font-bold whitespace-nowrap"}>{item.sales_person?.first_name ?? item.name}</h4>
        <div>
          {tooltipContent && (
            <Tooltip>
              <div className={"max-w-md card"}>{tooltipContent}</div>
            </Tooltip>
          )}
        </div>
        <div className={"text-right text-lg font-semibold"}>
          {renderPrice(item.total_price_before_discount_without_vat ?? item.total_price_before_discount, currency)}
        </div>

        <div className={"col-span-full"}>
          {oneOff ? (
            <span className={"text-xs text-cr-grey-50"}>{t("CreateSalesCycle_Checkout_Summary_OneOffPayment")}</span>
          ) : (
            item.package && (
              <Trans
                i18nKey={"CreateSalesCycle_Checkout_Summary_Reaches"}
                components={commonTransComponents}
                values={{count: item.package?.reaches}}
              />
            )
          )}
        </div>

        <SummaryItemSpacer />

        {(item.total_discount_price ?? 0) > 0 && (
          <>
            <h4 className={"font-semibold"}>{t("CreateSalesCycle_Checkout_Summary_Discount")}</h4>
            <p className={"col-span-2 text-right font-semibold whitespace-nowrap text-cr-green"}>
              {renderPrice(item.total_discount_price ?? 0, currency, {negative: true})}
            </p>
            <SummaryItemSpacer />
          </>
        )}

        <h4 className={"font-semibold"}>{t("CreateSalesCycle_Checkout_Summary_Taxes")}</h4>
        <p className={"col-span-2 text-right font-semibold whitespace-nowrap"}>
          {renderPrice(item.total_vat_price ?? 0, currency)}
        </p>
      </div>
    </div>
  )
}

const SummaryItemSpacer: React.FC = () => {
  return <div className={"col-span-full h-6"} />
}

import React from "react"
import {Trans, useTranslation} from "react-i18next"

import {CheckboxConnected} from "../../../../components/fields/Checkbox.tsx"
import {FieldErrorMessage} from "../../../../components/fields/components.tsx"
import {InputField} from "../../../../components/fields/Input.tsx"
import {Link} from "../../../../components/Link.tsx"

export const DetailsPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <>
      <InputField label={t("Signup_SaaSCompany_AccountCreatePage_FirstName")} name={"first_name"} variant={"medium"} />
      <InputField label={t("Signup_SaaSCompany_AccountCreatePage_LastName")} name={"last_name"} variant={"medium"} />

      <div className={"my-2"}>
        <CheckboxConnected name={"privacyPolicyAgreed"}>
          <Trans
            i18nKey={"Signup_SaaSCompany_AccountCreatePage_PrivacyPolicy"}
            components={{
              privacyLink: <Link flipUnderline to={t("Url_PrivacyPolicy")} target={"_blank"} />,
            }}
          />
        </CheckboxConnected>
        <FieldErrorMessage name={"privacyPolicyAgreed"} reserveSpace />
      </div>
    </>
  )
}

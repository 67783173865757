import React from "react"
import {ChevronUpIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "./Link.tsx"

export const Accordion: React.FC<
  {
    title: React.ReactNode
    children: React.ReactNode
    listClassName?: string
  } & (
    | {isOpen: boolean; onClick: (newIsOpen: boolean) => void; defaultOpen?: never}
    | {isOpen?: never; onClick?: never; defaultOpen?: boolean}
  )
> = ({isOpen: isOpenProp, title, onClick, children, defaultOpen, listClassName}) => {
  const [isOpen, setIsOpen] = React.useState(isOpenProp ?? !!defaultOpen)

  React.useEffect(() => {
    if (isOpenProp === undefined) {
      return
    }

    setIsOpen(isOpenProp)
  }, [isOpenProp])

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick(!isOpenProp)
      return
    }

    setIsOpen(v => !v)
  }, [isOpenProp, onClick])

  return (
    <div className={"flex flex-col rounded-lg border border-cr-blue-light"}>
      <UnstyledLink
        onClick={handleClick}
        className={"flex cursor-pointer items-center justify-between px-4 py-3 text-sm font-semibold select-none"}
      >
        <span className={"whitespace-nowrap"}>{title}</span>
        <ChevronUpIcon className={twMerge("size-5 shrink-0 transition-all", isOpen && "rotate-180")} />
      </UnstyledLink>

      <div className={twMerge("grid overflow-hidden transition-all", isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]")}>
        <div className={twMerge("min-h-0", isOpen ? "visible" : "invisible")}>
          <div className={twMerge("p-4 pt-1", listClassName)}>{children}</div>
        </div>
      </div>
    </div>
  )
}

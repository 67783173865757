import React from "react"
import {useTranslation} from "react-i18next"

import {ASaasCompanyUserListItem} from "../../../services/types.generated.ts"
import {enumTranslKey} from "../../../utils/i18n.tsx"

export const RoleCell: React.FC<{row: ASaasCompanyUserListItem}> = ({row: {role}}) => {
  const {t} = useTranslation()

  return t(enumTranslKey("SaaSUserRole", role))
}

import React from "react"
import {twMerge} from "tailwind-merge"

import {TColumnMeta, TColumns, TypedTableContext} from "./utils/shared.ts"

export type TCellValueProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  row: TRowData
}

export type TCellProps<TCol extends TColumns, TRowData extends Record<string, any>> = {
  columnMeta: TColumnMeta<TCol, TRowData>
  row: TRowData
  className?: string
  rowIndex: number
  colIndex: number
  pinX: number | null
}

function DefaultCellValue<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  row,
}: TCellValueProps<TCol, TRowData>): React.ReactNode {
  return row[columnMeta.column] ?? "-"
}

function DefaultCellComponent<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  row,
  className,
  rowIndex,
  colIndex,
  pinX,
}: TCellProps<TCol, TRowData>): React.ReactNode {
  const {columnsMeta} = TypedTableContext<TCol, TRowData>().useContext()

  const Value: React.ComponentType<TCellValueProps<TCol, TRowData>> = columnMeta.CellValue ?? DefaultCellValue
  Value.displayName = Value.displayName ?? "CustomValue"

  const align =
    typeof columnMeta.align === "function" ? columnMeta.align({columnsMeta, columnMeta}) : (columnMeta.align ?? "left")
  const verticalAlign = columnMeta.verticalAlign ?? "center"

  const isPinned = pinX !== null

  return (
    <div
      className={twMerge([
        `bg-cr-white transition-all group-hover/row:bg-cr-blue-super-light`,
        "px-2 py-2.5 text-sm font-normal empty:px-0 md:px-6 md:py-4",
        "border-b border-b-cr-blue-super-light group-last/row:border-b-0 group-first-of-type/row:border-t group-first-of-type/row:border-t-cr-blue-light",
        "relative flex empty:px-0",
        "[--z:10]",
        isPinned ? `sticky z-[calc(var(--z)+10)]` : "z-[var(--z)]",
        align === "left" && "justify-start",
        align === "center" && "justify-center",
        align === "right" && "justify-end",
        verticalAlign === "top" && "items-start",
        verticalAlign === "center" && "items-center",
        verticalAlign === "bottom" && "items-end",
        columnMeta.cellClassName,
        className,
      ])}
      style={{
        left: isPinned ? `${pinX}px` : undefined,
        gridRow: rowIndex == null ? undefined : rowIndex + 2,
        gridColumn: colIndex == null ? undefined : colIndex + 1,
      }}
    >
      <Value row={row} columnMeta={columnMeta} />
    </div>
  )
}
export const DefaultCell = React.memo(DefaultCellComponent) as typeof DefaultCellComponent

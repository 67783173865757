import React from "react"

import {EMPTY_ARRAY} from "../../../utils"
import {createSimpleContext} from "../../../utils/context.tsx"
import {CheckboxBase} from "../../fields/Checkbox.tsx"
import {TColumnMeta, TRowId} from "./shared.ts"

export type TRowCheckContext = {
  onCheckAll: () => void
  areAllChecked: boolean
  onCheck: (id: TRowId) => void
  checked: readonly TRowId[]
}

export const RowCheckContext = createSimpleContext<TRowCheckContext>("row checking")

export const CHECKBOX_NAME_PREFIX = "row-check-"

export const rowCheckColumn: TColumnMeta<any, any> = {
  column: "check" as const,
  size: "min-content",
  HeaderCellValue: () => {
    const {onCheckAll, areAllChecked} = RowCheckContext.useContext()

    return <CheckboxBase name={"bulkCheck"} onChange={onCheckAll} checked={areAllChecked} />
  },
  CellValue: ({row}) => {
    const {checked, onCheck} = RowCheckContext.useContext()

    return (
      <CheckboxBase
        checked={checked.includes(row.id)}
        onChange={() => onCheck(row.id)}
        name={`${CHECKBOX_NAME_PREFIX}${row.id}`}
      />
    )
  },
} satisfies TColumnMeta<any, Record<string, any> & {id: TRowId}>

export type TRowCheckingProps = {checkedRows: readonly TRowId[]; checkRow: (id: TRowId) => void}

export type TBasicRowCheckingProps = TRowCheckingProps & {
  clearRows: () => void
  setCheckedRows: (checkedRows: React.SetStateAction<readonly TRowId[]>) => void
}

export function useRowChecking(rows: ReadonlyArray<{id: TRowId}> = EMPTY_ARRAY): TRowCheckingProps {
  const {setCheckedRows, checkRow, checkedRows} = useBasicRowChecking()

  const allRowIds = React.useMemo(() => {
    return rows.map(row => row.id)
  }, [rows])

  React.useMemo(() => {
    setCheckedRows(currentCheckedIds => currentCheckedIds.filter(checkedId => allRowIds.includes(checkedId)))
  }, [allRowIds, setCheckedRows])

  return {checkedRows, checkRow}
}

export function useBasicRowChecking(): TBasicRowCheckingProps {
  const [checkedRows, setCheckedRows] = React.useState<readonly TRowId[]>([])

  const checkRow = React.useCallback((id: TRowId) => {
    setCheckedRows(currentCheckedIds => {
      const checkedRowIdIndex = currentCheckedIds.findIndex(rowId => rowId === id)

      if (checkedRowIdIndex === -1) {
        return [...currentCheckedIds, id]
      }

      return currentCheckedIds.filter(rowId => rowId !== id)
    })
  }, [])

  const clearRows = React.useCallback(() => {
    setCheckedRows(EMPTY_ARRAY)
  }, [])

  return {checkedRows, checkRow, clearRows, setCheckedRows}
}

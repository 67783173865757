import React from "react"

import {TIconComponent} from "../../../../types.ts"

export const MetricCard: React.FC<{
  title: string
  value: string | number
  Icon: TIconComponent
}> = ({title, value, Icon}) => {
  return (
    <div className={"relative flex basis-[230px] flex-col gap-2 overflow-hidden card-shadow p-6"}>
      <h3 className={"z-10 font-semibold"}>{title}</h3>
      <h4 className={"z-10 text-2xl font-bold"}>{value}</h4>
      <Icon className={"absolute -right-2 -bottom-6 z-0 size-28 stroke-1 text-cr-blue-light"} />
    </div>
  )
}

import React from "react"

import {useAutoUpdateRef} from "./ref.ts"

export function useControlledProp<T>(controlled: T | undefined, defaultValue: T): T {
  const [uncontrolled, setUncontrolled] = React.useState(controlled === undefined ? defaultValue : controlled)

  const defaultValueRef = useAutoUpdateRef(defaultValue)

  React.useEffect(() => {
    setUncontrolled(controlled === undefined ? defaultValueRef.current : controlled)
  }, [controlled, defaultValueRef])

  return controlled === undefined ? uncontrolled : controlled
}

export function useControlledGetSetProp<T>(
  controlledValue: T | undefined,
  controlledSetValue: ((value: T) => void) | undefined,
  defaultValue: T
): [T, (value: T) => void] {
  const [uncontrolled, setUncontrolled] = React.useState(controlledValue === undefined ? defaultValue : controlledValue)
  const defaultValueRef = useAutoUpdateRef(defaultValue)

  React.useEffect(() => {
    setUncontrolled(controlledValue === undefined ? defaultValueRef.current : controlledValue)
  }, [controlledValue, defaultValueRef])

  return React.useMemo(
    () => [
      controlledValue === undefined ? uncontrolled : controlledValue,
      controlledSetValue === undefined ? setUncontrolled : controlledSetValue,
    ],
    [controlledValue, uncontrolled, controlledSetValue]
  )
}

import {ComponentType, FC, ReactNode} from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import welcomeBg from "../assets/welcomeBg.jpg"
import {Link} from "./Link"

type TNode = {
  Icon: ComponentType<{className?: string}>
  Action?: ComponentType<{className: string}>
  title: string
  text: ReactNode
}

const WelcomeLayout: FC<{title: ReactNode; nodes: [TNode, TNode, TNode]}> = ({title, nodes}) => {
  const {t} = useTranslation()

  return (
    <section>
      <div className={"min-h-screen text-white"}>
        <div
          className={"absolute top-16 left-0 min-w-full bg-cr-blue-mid bg-contain bg-right-bottom bg-no-repeat"}
          style={{
            backgroundImage: `url("${welcomeBg}")`,
            height: "calc(100% - 64px - 192px)",
          }}
        ></div>
        <div
          className={
            "relative z-10 mx-auto -mt-16 flex min-h-screen max-w-(--breakpoint-2xl) px-8 pt-16 pb-96 text-center md:text-left xl:-mt-20 xl:px-0 xl:pt-20"
          }
        >
          <div className={"self-end"}>
            <h1 className={"mt-8 mb-4 max-w-3xl text-5xl font-bold drop-shadow-lg sm:text-7xl"}>{title}</h1>
            <p className={"max-w-3xl text-lg drop-shadow-md"}>{t("WelcomeScreen_Salesperson_Text")}</p>
          </div>
        </div>
      </div>
      <div className={"relative z-10 -mt-48"}>
        <div
          className={
            "mx-auto flex max-w-(--breakpoint-2xl) flex-col items-center gap-24 px-8 md:flex-row md:items-stretch md:gap-12 xl:px-0"
          }
        >
          {nodes.map(({Icon, Action, title, text}) => (
            <div key={title} className={"flex flex-col justify-between card p-0 first:-mt-32 md:-mt-32 md:w-1/3"}>
              <div className={"p-6"}>
                <Icon className={"-mt-12 h-14 w-14 rounded-xl bg-cr-blue p-3.5 text-white"} />
                <h3 className={"mt-6 mb-3 text-xl font-semibold"}>{title}</h3>
                <div className={"text-cr-grey-50"}>{text}</div>
              </div>
              <div
                className={twMerge(
                  "rounded-b-2xl bg-cr-blue-super-light px-6 py-4",
                  !Action && "hidden bg-white md:block"
                )}
              >
                {Action ? <Action className={"font-bold"} /> : <>&nbsp;</>}
              </div>
            </div>
          ))}
        </div>
        <div className={"mx-auto mt-16 mb-14 max-w-(--breakpoint-2xl) px-8 xl:px-0"}>
          <p>
            {t("T_Need help?")}{" "}
            <Link className={"font-bold"} to={t("Url_Contact")} target={"_blank"}>
              {t("T_Contact us")}
            </Link>
          </p>
        </div>
      </div>
    </section>
  )
}

export default WelcomeLayout

import React, {FC} from "react"
import {Trans, useTranslation} from "react-i18next"
import {EnvelopeIcon, PhoneIcon, StarIcon, UserGroupIcon} from "@heroicons/react/24/outline"
import {CheckIcon, EllipsisVerticalIcon} from "@heroicons/react/24/solid"
import {addDays, endOfDay, endOfMonth, startOfDay} from "date-fns"
import {twMerge} from "tailwind-merge"

import Button from "../../components/Button"
import {CircularProgress} from "../../components/CircularProgress"
import {Menu} from "../../components/Menu"
import {MenuItem} from "../../components/Menu/MenuItem"
import {SellingsBlockBadge, SellingsBlockCard} from "../../components/SellingsBlock"
import {Tooltip} from "../../components/Tooltip"
import {getCurrentLanguage, i18n} from "../../i18n"
import {rollbar} from "../../services"
import {
  ACommissionValueTypes,
  ADashboardStages,
  ASalesPersonDashboardSalesCyclesDetail,
} from "../../services/types.generated"
import {TIconComponent} from "../../types.ts"
import {renderPrice} from "../../utils"
import {apiDateToJS} from "../../utils/dateArithmetics.ts"
import {commonTransComponents, enumTranslKey} from "../../utils/i18n"
import {CommissionTooltip} from "./CommissionTooltip.tsx"
import {SalaryTooltip} from "./SalaryTooltip.tsx"
import {getCommissionValueTranslated} from "./utils.tsx"

const downloadLinkProps = {
  download: true,
  target: "_blank",
  rel: "noreferrer",
}

export const Selling: FC<{salesCycle: ASalesPersonDashboardSalesCyclesDetail[number]}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const startDate =
    (salesCycle.year && salesCycle.month && apiDateToJS(`${salesCycle.year}-${salesCycle.month}-01`)) || undefined
  const product = salesCycle.products?.[0]
  const goal = product?.goal

  if (!product || !goal) {
    rollbar.error(`Sales cycle #${salesCycle.id} doesn't have any products or goals.`)
    return null
  }

  return (
    <SellingsBlockCard detailLink={salesCycle.assigned_to_current_iteration ? `/sellings/${salesCycle.id}` : undefined}>
      <div className={"flex flex-col justify-between rounded-lg pb-12"}>
        <div className={"flex flex-col gap-12 px-14 py-12"}>
          <div className={"flex items-start justify-between gap-10"}>
            <div className={"flex flex-col gap-4"}>
              <h2 className={"text-3xl font-semibold"}>{salesCycle.name}</h2>
              <h3 className={"text-cr-grey-50"}>
                {t("SalespersonDashboard_Selling_Subtitle", {
                  product: product.name,
                  company: salesCycle.company_name,
                  country: salesCycle.country.name,
                })}
              </h3>
            </div>
            <div className={"flex items-center justify-end gap-4"}>
              <SellingsBlockBadge isActive={true} status={salesCycle.iteration_status} />
              <div className={"contents"} onClick={e => e.stopPropagation()}>
                <Menu
                  Button={({isOpen}) => (
                    <Button
                      variant={isOpen ? "light" : "outlined"}
                      color={isOpen ? "blue" : "gray"}
                      className={"relative border-transparent px-2"}
                    >
                      <EllipsisVerticalIcon className={"h-6 w-6"} />
                    </Button>
                  )}
                  items={[
                    salesCycle.sales_strategy_url && (
                      <MenuItem
                        key={"sales_strategy_url"}
                        to={salesCycle.sales_strategy_url}
                        variant={"neutral"}
                        ghost
                        linkProps={downloadLinkProps}
                      >
                        {t("T_Download strategy")}
                      </MenuItem>
                    ),
                    salesCycle.price_list_url && (
                      <MenuItem
                        key={"price_list_url"}
                        to={salesCycle.price_list_url}
                        variant={"neutral"}
                        ghost
                        linkProps={downloadLinkProps}
                      >
                        {t("T_Download price list")}
                      </MenuItem>
                    ),
                  ]}
                />
              </div>
            </div>
          </div>
          <div className={"flex flex-wrap justify-between gap-10"}>
            {salesCycle.statistics?.outreach != null && (
              <Section
                title={t("SalespersonDashboard_Selling_Outreach_Title")}
                value={salesCycle.statistics?.outreach}
                subtitle={t("SalespersonDashboard_Selling_Outreach_Subtitle", {count: salesCycle.reaches ?? 0})}
              />
            )}
            {salesCycle.reward_value != null && (
              // This arbitrary splitting the 100€ to bonus was explicitly requested by Stano.
              // Let it be known I was not happy.
              <Section
                title={t("SalespersonDashboard_Selling_Pay_Title")}
                value={renderPrice(salesCycle.reward_value - 100, salesCycle.default_currency ?? undefined, {
                  noDecimal: true,
                })}
                tooltip={
                  <SalaryTooltip
                    goal={goal}
                    reaches={salesCycle.reaches}
                    minimumMeetings={salesCycle.minimum_meetings}
                  />
                }
                subtitle={
                  <Trans
                    i18nKey={"SalespersonDashboard_Selling_Pay_Subtitle"}
                    components={commonTransComponents}
                    values={{
                      bonus: renderPrice(100, salesCycle.default_currency ?? undefined, {
                        noDecimal: true,
                      }),
                    }}
                  />
                }
              />
            )}
            <Section
              title={t(enumTranslKey("ProductGoal", goal))}
              value={salesCycle.statistics?.deals ?? 0}
              subtitle={t("SalespersonDashboard_Selling_GoalFulfilled_Subtitle", {count: salesCycle.reaches ?? 0})}
            />
            <Section
              title={t("SalespersonDashboard_Selling_Commission_Title")}
              value={getCommissionValueTranslated(
                product.commission_type,
                product.commission_value_type === ACommissionValueTypes.FixedPrice
                  ? renderPrice(product.sales_person_commission ?? 0, salesCycle.default_currency ?? undefined, {
                      noDecimal: true,
                    })
                  : `${product.sales_person_commission}%`
              )}
              tooltip={<CommissionTooltip goal={goal} />}
            />
          </div>
        </div>
        {startDate && (
          <div className={"flex flex-col gap-9 rounded-b-lg bg-cr-blue-super-light px-14 py-9"}>
            <h3 className={"text-lg font-semibold"}>{t("SalespersonDashboard_Selling_Progress_Title")}</h3>
            <div className={"flex flex-wrap justify-between gap-10"}>
              <Stage stage={ADashboardStages.FirstOutreach} Icon={EnvelopeIcon} startDate={startDate} />
              <Stage stage={ADashboardStages.SecondOutreach} Icon={PhoneIcon} startDate={startDate} />
              <Stage stage={ADashboardStages.PresentationDemo} Icon={UserGroupIcon} startDate={startDate} />
              <Stage stage={ADashboardStages.Closing} Icon={StarIcon} startDate={startDate} />
            </div>
          </div>
        )}
      </div>
    </SellingsBlockCard>
  )
}

export const stageOrder = [
  ADashboardStages.FirstOutreach,
  ADashboardStages.SecondOutreach,
  ADashboardStages.PresentationDemo,
  ADashboardStages.Closing,
] as const satisfies ADashboardStages[]
export const getStageTimings = (startDate: Date): Array<{stage: ADashboardStages; start: Date; end: Date}> => {
  return [
    {stage: ADashboardStages.FirstOutreach, start: startDate, end: endOfDay(addDays(startDate, 3))},
    {
      stage: ADashboardStages.SecondOutreach,
      start: startOfDay(addDays(startDate, 4)),
      end: endOfDay(addDays(startDate, 9)),
    },
    {
      stage: ADashboardStages.PresentationDemo,
      start: startOfDay(addDays(startDate, 10)),
      end: endOfDay(addDays(startDate, 19)),
    },
    {stage: ADashboardStages.Closing, start: startOfDay(addDays(startDate, 20)), end: endOfMonth(startDate)},
  ]
}
export type TStageTimings = ReturnType<typeof getStageTimings>

export const getStageIndex = (stageToFind: ADashboardStages | undefined) => {
  return stageOrder.findIndex(stage => stage === stageToFind)
}

export function getCurrentStage(
  startDate: Date
): {stage: ADashboardStages; progress: number; start: Date; end: Date} | null {
  const now = new Date()
  const stageTimings = getStageTimings(startDate)

  if (stageTimings[0].start > now) {
    return null
  }

  for (const {stage, start, end} of stageTimings) {
    if (now >= start && now <= end) {
      const stageDuration = end.getTime() - start.getTime()
      const currentTimeOfStage = now.getTime() - start.getTime()

      const progress = Math.floor((currentTimeOfStage / stageDuration) * 100)

      return {stage, progress, start, end}
    }
  }

  return null
}

const titleByStage = {
  [ADashboardStages.FirstOutreach]: i18n.t("SalespersonDashboard_Selling_Progress_FirstOutreach"),
  [ADashboardStages.SecondOutreach]: i18n.t("SalespersonDashboard_Selling_Progress_SecondOutreach"),
  [ADashboardStages.PresentationDemo]: i18n.t("SalespersonDashboard_Selling_Progress_PresentationDemo"),
  [ADashboardStages.Closing]: i18n.t("SalespersonDashboard_Selling_Progress_Closing"),
} satisfies {[stage in ADashboardStages]: string}

const Stage: React.FC<{
  stage: ADashboardStages
  startDate: Date
  Icon: TIconComponent
}> = ({Icon, stage, startDate}) => {
  const currentStage = getCurrentStage(startDate)
  const thisStage = getStageTimings(startDate).find(s => s.stage === stage)
  const isCurrentStage = stage === currentStage?.stage
  const isPastStage = getStageIndex(stage) < getStageIndex(currentStage?.stage)
  const progress = isCurrentStage ? currentStage.progress : isPastStage ? 100 : 0

  if (!thisStage) {
    return null
  }

  return (
    <div className={"flex gap-4"}>
      <div className={"h-12 w-12"}>
        <CircularProgress active={isCurrentStage} progress={progress}>
          {progress === 100 ? <CheckIcon className={"h-6 w-6"} /> : <Icon className={"h-6 w-6"} />}
        </CircularProgress>
      </div>
      <div className={"flex flex-col"}>
        <h4
          className={twMerge(["text-sm font-semibold uppercase", isCurrentStage ? "text-cr-blue" : "text-cr-grey-50"])}
        >
          {titleByStage[stage]}
        </h4>
        <div className={"text-sm text-cr-grey-50"}>
          {thisStage.start.toLocaleString(getCurrentLanguage(), {month: "short", day: "numeric"})} -{" "}
          {thisStage.end.toLocaleString(getCurrentLanguage(), {month: "short", day: "numeric"})}
        </div>
      </div>
    </div>
  )
}

const Section: React.FC<{
  title: React.ReactNode
  value: React.ReactNode
  subtitle?: React.ReactNode
  tooltip?: React.ReactNode
}> = ({title, tooltip, value, subtitle}) => {
  return (
    <div className={"flex flex-col gap-4"}>
      <div className={"flex gap-2 whitespace-nowrap"}>
        <h4 className={"font-bold whitespace-nowrap text-cr-grey-50"}>{title}</h4>
        {tooltip && (
          <Tooltip iconClassName={"text-cr-grey-50 w-5 h-5"} buttonClassName={"data-open:outline-cr-grey-50"}>
            <div className={"card px-6 py-4"}>{tooltip}</div>
          </Tooltip>
        )}
      </div>
      <div className={"flex flex-col gap-1"}>
        <h5 className={"text-3xl font-semibold text-cr-blue"}>{value}</h5>
        {subtitle && <div className={"text-sm text-cr-grey-50"}>{subtitle}</div>}
      </div>
    </div>
  )
}

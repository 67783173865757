import React from "react"
import {useTranslation} from "react-i18next"

import {ButtonLink} from "../../../components/Button.tsx"
import {ACompanyUserDashboardFinishedSalesCycle} from "../../../services/types.generated.ts"
import {SellingHeader} from "./SellingHeader.tsx"

export const SimpleSelling: React.FC<{salesCycle: ACompanyUserDashboardFinishedSalesCycle; badge: React.ReactNode}> = ({
  salesCycle,
  badge,
}) => {
  const {t} = useTranslation()

  return (
    <div className={"flex flex-col card-shadow"} data-testid={"salesCycle"}>
      <div className={"flex flex-col gap-6 px-12 py-8"}>
        <SellingHeader salesCycle={salesCycle}>
          {badge}
          <ButtonLink variant={"light"} size={"sm"} to={`/sellings/${salesCycle.id}`}>
            {t("Dashboard_Company_SimpleSellingDetailsButton")}
          </ButtonLink>
        </SellingHeader>
      </div>
    </div>
  )
}

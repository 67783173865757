import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js"

import stripeLogo from "../../../assets/stripe.svg"
import Button, {ButtonLoading} from "../../../components/Button.tsx"
import Modal from "../../../components/Modal.tsx"
import {useUpdateCheckoutQueryCache} from "../../../queries/checkout.ts"
import {useStripeQuery} from "../../../queries/stripe.ts"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import requestError from "../../../services/requestError.tsx"
import {AOrder, AOrderStatuses} from "../../../services/types.generated.ts"
import {renderPrice, useIsWelcomeScreenEnabled} from "../../../utils"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.tsx"
import {commonTransComponents} from "../../../utils/i18n.tsx"

export const StripeModal: React.FC<{order: AOrder; isOpen: boolean; clientSecret?: string; onClose: () => void}> = ({
  clientSecret,
  isOpen,
  onClose,
  order,
}) => {
  const stripe = useStripeQuery().data ?? null

  return (
    <Modal disableClickOutsideClose isOpen={isOpen && !!clientSecret} onClose={onClose} size={"lg"}>
      <Elements stripe={stripe} options={{clientSecret}}>
        <StripeModalInner onClose={onClose} order={order} />
      </Elements>
    </Modal>
  )
}

export const StripeModalInner: React.FC<{order: AOrder; onClose: () => void}> = ({onClose, order}) => {
  const {t} = useTranslation()

  const stripe = useStripe()
  const elements = useElements()
  const isWelcomeScreenEnabled = useIsWelcomeScreenEnabled()
  const {user} = useUserSettingsOrLogout()

  const updateCheckoutQueryCache = useUpdateCheckoutQueryCache()

  const handleSubmit = React.useCallback(async () => {
    if (!stripe || !elements) {
      return
    }

    const successPath = `/checkout/${order.id}/finished/success`

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + successPath,
      },
      redirect: "if_required",
    })

    if (result.error) {
      requestError(result.error.message ?? result.error.type)
      return
    }

    isWelcomeScreenEnabled &&
      emitGTMEvent({
        event: EGTMEvent.COMPANY_CHECKOUT_SEND,
        email: user.email,
        phone: user.phone_number ?? "",
        value: order.total_price,
      })

    updateCheckoutQueryCache({...order, status: AOrderStatuses.Paid})

    onClose()
  }, [
    elements,
    isWelcomeScreenEnabled,
    onClose,
    order,
    stripe,
    updateCheckoutQueryCache,
    user.email,
    user.phone_number,
  ])

  return (
    <div className={"flex flex-col gap-10 text-cr-black"}>
      <div className={"flex gap-10"}>
        <img className={"hidden sm:block"} src={stripeLogo} alt={"Stripe"} />

        <div className={"flex flex-col gap-4"}>
          <h1 className={"text-2xl font-bold"}>{t("CreateSalesCycle_Checkout_Card_Title")}</h1>
          <div className={"text-sm text-cr-grey-50"}>
            <Trans i18nKey={"CreateSalesCycle_Checkout_Card_Text"} components={commonTransComponents} />
          </div>
          <div className={"font-semibold"}>
            <Trans
              i18nKey={"CreateSalesCycle_Checkout_Card_Total"}
              components={{...commonTransComponents, price: <span className={"text-cr-blue"} />}}
              values={{price: renderPrice(order.total_price, order.currency)}}
            />
          </div>
        </div>
      </div>

      {elements && <PaymentElement />}

      <div className={"flex justify-end gap-4"}>
        <Button onClick={onClose} variant={"outlined"} color={"gray"}>
          {t("CreateSalesCycle_Checkout_Card_Cancel")}
        </Button>
        <ButtonLoading onClick={handleSubmit}>{t("CreateSalesCycle_Checkout_Card_Submit")}</ButtonLoading>
      </div>
    </div>
  )
}

import React from "react"
import {twMerge} from "tailwind-merge"

import Button from "../components/Button.tsx"
import {EFeature, useEnabledFeatures, useIsFeatureEnabled} from "../utils/featureFlags.ts"
import {setLSItem} from "../utils/localStorage"

export const Flags: React.FC = () => {
  const allFeatures = Object.values(EFeature) as EFeature[]
  const enabledFeatures = useEnabledFeatures()

  const handleToggle = React.useCallback(
    (feature: EFeature) => {
      const featureIndex = enabledFeatures.indexOf(feature)
      const newFeatures =
        featureIndex === -1 ? [...enabledFeatures, feature] : enabledFeatures.toSpliced(featureIndex, 1)

      setLSItem("features", newFeatures)
    },
    [enabledFeatures]
  )

  return (
    <div className={"grid max-w-(--breakpoint-sm) grid-cols-[auto_min-content] items-center gap-x-10 gap-y-4 p-10"}>
      {allFeatures.map(feature => (
        <Flag feature={feature} key={feature} onToggle={() => handleToggle(feature)} />
      ))}
    </div>
  )
}

export const Flag: React.FC<{feature: EFeature; onToggle: () => void}> = ({feature, onToggle}) => {
  const isEnabled = useIsFeatureEnabled(feature)

  return (
    <>
      <span className={twMerge("font-bold", isEnabled && "text-cr-blue")}>{feature}</span>
      <Button variant={isEnabled ? "contained" : "outlined"} onClick={onToggle}>
        {isEnabled ? "Disable" : "Enable"}
      </Button>
    </>
  )
}

import React from "react"

import {TFilterOption} from "../../components/AutocompleteFilter"
import {TOption} from "../../components/formElements/Dropdown/types"
import {useBasicRowChecking} from "../../components/Table/utils/rowChecking.tsx"
import {TOrderBy} from "../../components/Table/utils/shared.ts"
import {ACompanySizesFilters} from "../../services/types.generated"
import {
  useArrayFilterProperty,
  useBooleanFilterProperty,
  useFilterProperties,
  useStringFilterProperty,
} from "../../utils/filters.ts"
import {TLeadsTableColumn} from "./DataTable"

export type TLeadsFiltering = ReturnType<typeof useLeadsFiltering>

export type TCompanySizeOption = TOption<ACompanySizesFilters>

export function useLeadsFiltering() {
  const [orderBy, setOrderBy] = React.useState<TOrderBy<TLeadsTableColumn>>(undefined)
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(true)

  const rowChecking = useBasicRowChecking()
  const {clearRows} = rowChecking

  const filterProperties = useFilterProperties({
    searchString: useStringFilterProperty(),
    positions: useArrayFilterProperty<TFilterOption[]>(),
    segments: useArrayFilterProperty<TFilterOption[]>(),
    countries: useArrayFilterProperty<Array<TFilterOption<number>>>(),
    companySizes: useArrayFilterProperty<TCompanySizeOption[]>(),
    isSelectedOnly: useBooleanFilterProperty(),
    name: useStringFilterProperty(),
    phone: useBooleanFilterProperty(),
    email: useBooleanFilterProperty(),
    companyName: useStringFilterProperty(),
    contactedByCompany: useBooleanFilterProperty(),
    cities: useArrayFilterProperty<TFilterOption[]>(),
  })

  const setIsSelectedOnly = filterProperties.isSelectedOnly.setValue
  React.useEffect(() => {
    if (rowChecking.checkedRows.length === 0) {
      setIsSelectedOnly(false)
    }
  }, [rowChecking.checkedRows, setIsSelectedOnly])

  React.useEffect(() => {
    clearRows()
  }, [
    filterProperties.positions.value,
    filterProperties.segments.value,
    filterProperties.searchString.value,
    filterProperties.countries.value,
    filterProperties.companySizes.value,
    filterProperties.name.value,
    filterProperties.phone.value,
    filterProperties.email.value,
    filterProperties.companyName.value,
    filterProperties.contactedByCompany.value,
    filterProperties.cities.value,
    clearRows,
  ])

  const checkedRowsForQuery = React.useMemo(() => {
    if (!filterProperties.isSelectedOnly.value) {
      return undefined
    }
    return rowChecking.checkedRows as number[]
  }, [filterProperties.isSelectedOnly.value, rowChecking.checkedRows])

  return React.useMemo(
    () => ({
      ...filterProperties,
      orderBy,
      setOrderBy,
      isFiltersOpen,
      setIsFiltersOpen,
      ...rowChecking,
      checkedRowsForQuery,
    }),
    [checkedRowsForQuery, filterProperties, isFiltersOpen, orderBy, rowChecking]
  )
}

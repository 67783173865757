import React from "react"
import {useDndContext} from "@dnd-kit/core"
import {twMerge} from "tailwind-merge"

import {getColumnIndex, isFirstColumn, isLastColumn} from "./utils/columns.ts"
import {TColumns, TColumnsMeta} from "./utils/shared.ts"
import {ColumnSizeContext} from "./utils/useColumnSizes.ts"

export const PinMarker: React.FC<{columnIndex: number | null; pinnedColumn: string | null}> = ({
  columnIndex,
  pinnedColumn,
}) => {
  const {getColumnX} = ColumnSizeContext.useContext()

  const x = pinnedColumn ? getColumnX(pinnedColumn) : -Infinity

  if (columnIndex == null) {
    return null
  }

  return (
    <div
      className={"pointer-events-none sticky z-18 row-span-full flex justify-end"}
      style={{gridColumn: columnIndex + 1, left: x}}
    >
      <div className={"-mr-1.5 h-full w-1.5 bg-linear-to-r from-cr-blue-dark/5 to-cr-blue-dark/0"} />
    </div>
  )
}

const dropMarkerWidth = 4

export function DropMarker<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnsMeta,
}: {
  columnsMeta: TColumnsMeta<TCol, TRowData>
}): React.ReactNode {
  const {over} = useDndContext()
  const {getColumnX} = ColumnSizeContext.useContext()

  const overColumn = over?.data.current?.column as TCol | undefined
  const position = over?.data.current?.position as "left" | "right" | undefined

  const colIndex = getColumnIndex(columnsMeta, overColumn)

  const isFirstCol = isFirstColumn(columnsMeta, overColumn)
  const isLastCol = isLastColumn(columnsMeta, overColumn)

  const [positionX, setPositionX] = React.useState(0)
  const ref = (el: HTMLDivElement | null) => {
    if (!el) {
      return
    }

    const grid = el.closest("[role='table']")

    if (!grid) {
      return
    }

    const scrollX = grid.scrollLeft
    const gridLeft = grid.getBoundingClientRect().left

    if (!over) {
      setPositionX(0)
      return
    }

    setPositionX(
      Math.min(
        over.rect.left - gridLeft + scrollX + (position === "right" ? over.rect.width : 0),
        grid.scrollWidth - dropMarkerWidth
      )
    )
  }

  const isOverPinned = over?.data.current?.isPinned ?? false

  return isOverPinned ? (
    <div
      className={twMerge(
        "pointer-events-none sticky z-30 row-span-full flex opacity-0",
        over && "opacity-100",
        position === "left" ? "justify-start" : "justify-end"
      )}
      style={{gridColumn: (colIndex ?? 0) + 1, left: `${getColumnX(overColumn)}px`}}
    >
      <div
        className={twMerge(
          "h-full w-[4px] bg-cr-blue-mid-light",
          position === "left" ? "-translate-x-1/2" : "translate-x-1/2",
          ((isFirstCol && position === "left") || (isLastCol && position === "right")) && "translate-x-0"
        )}
      />
    </div>
  ) : (
    <div
      className={twMerge("pointer-events-none absolute z-30 flex opacity-0", over && "opacity-100")}
      style={{
        left: `${positionX}px`,
        top: 0,
        bottom: 0,
        width: dropMarkerWidth,
      }}
      ref={ref}
    >
      <div className={twMerge("h-full w-full bg-cr-blue-light")} />
    </div>
  )
}

import {z} from "zod"

import {requiredFieldMessage, validateNonemptyString, validatePassword} from "../../../utils/validation.ts"

export const steps = [
  {
    validationSchema: z.object({
      first_name: validateNonemptyString(),
      last_name: validateNonemptyString(),
      privacyPolicyAgreed: z.literal(true, {
        errorMap: () => ({message: requiredFieldMessage}),
      }),
    }),
  },
  {
    validationSchema: z.object({
      password: validatePassword(),
    }),
  },
] as const satisfies Array<{validationSchema: z.ZodObject<z.ZodRawShape>}>

export enum EPage {
  DETAILS,
  PASSWORD,
}

export const mergedValidationSchema = steps[EPage.DETAILS].validationSchema.merge(
  steps[EPage.PASSWORD].validationSchema
)

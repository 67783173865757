import React from "react"

export const TableNoRows: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div className={"col-span-full row-[2/-1] flex items-center justify-start py-14"}>
      <div className={"sticky left-1/2 z-40 -translate-x-1/2 rounded-lg bg-cr-white px-8 py-4 text-sm cr-shadow"}>
        {children}
      </div>
    </div>
  )
}

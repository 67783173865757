import {TColumns, TColumnsMeta, TOrderBy, TSortFn} from "./shared.ts"

type TDataGetter<TValue, TRowData extends Record<string, any>> = (row: TRowData) => TValue | undefined

type TFetchingSortFunction<TValue, TRowData extends Record<string, any>> = (
  dataGetter: TDataGetter<TValue, TRowData>
) => TSortFn<TRowData>

export function sortNumber<TRowData extends Record<string, any>>(
  dataGetter: TDataGetter<number, TRowData>
): TSortFn<TRowData> {
  return direction => (A, B) => {
    const valueA = dataGetter(A) ?? Number.NEGATIVE_INFINITY
    const valueB = dataGetter(B) ?? Number.NEGATIVE_INFINITY

    return (valueA - valueB) * direction
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TypeGuard_sortNumber: TFetchingSortFunction<number, any> = sortNumber

export function sortString<TRowData extends Record<string, any>>(
  dataGetter: TDataGetter<string, TRowData>
): TSortFn<TRowData> {
  return direction => (A, B) => {
    const valueA = dataGetter(A) ?? ""
    const valueB = dataGetter(B) ?? ""

    return valueA.localeCompare(valueB) * direction
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TypeGuard_sortString: TFetchingSortFunction<string, any> = sortString

export function sortDate<TRowData extends Record<string, any>>(
  dataGetter: TDataGetter<Date, TRowData>
): TSortFn<TRowData> {
  return direction => (A, B) => {
    const valueA = dataGetter(A)?.getTime() ?? Number.NEGATIVE_INFINITY
    const valueB = dataGetter(B)?.getTime() ?? Number.NEGATIVE_INFINITY

    return (valueA - valueB) * direction
  }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TypeGuard_sortDate: TFetchingSortFunction<Date, any> = sortDate

export function orderData<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnsMeta,
  orderBy,
  data,
}: {
  columnsMeta: TColumnsMeta<TCol, TRowData>
  orderBy: TOrderBy<TCol>
  data?: TRowData[]
}) {
  const sortFn = columnsMeta.find(columnMeta => columnMeta.column === orderBy?.column)?.sortFn

  if (!orderBy || !data || !sortFn || typeof sortFn !== "function") {
    return data ?? []
  }

  return [...data].sort(sortFn(orderBy.direction))
}

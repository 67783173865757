import React from "react"
import isEqual from "fast-deep-equal/es6"

import {getLSItem, setLSItem, useLSValue} from "../../../utils/localStorage"
import {useAutoUpdateRef} from "../../../utils/ref.ts"
import {TColumns} from "./shared.ts"

export function useLSTableColumnsState<TCol extends TColumns>(
  tableName: string,
  defaults: {columnsOrder: TCol[]; pinnedColumn: TCol | null}
) {
  const lsValues = useLSValue("tableColumns")?.[tableName]

  const columnsOrder = React.useMemo<readonly TCol[]>(() => {
    if (!lsValues || !isEqual(new Set(defaults.columnsOrder), new Set(lsValues.columnsOrder))) {
      return defaults.columnsOrder
    }
    return lsValues.columnsOrder as TCol[]
  }, [defaults.columnsOrder, lsValues])
  const pinnedColumn = React.useMemo<TCol | null>(() => {
    if (!lsValues || (lsValues.pinnedColumn && !columnsOrder.includes(lsValues.pinnedColumn as TCol))) {
      return defaults.pinnedColumn
    }

    return lsValues.pinnedColumn as TCol | null
  }, [columnsOrder, defaults.pinnedColumn, lsValues])

  const columnsOrderRef = useAutoUpdateRef(columnsOrder)
  const pinnedColumnRef = useAutoUpdateRef(pinnedColumn)

  const handleChangeColumnsOrder = React.useCallback(
    (newColumnsOrder: readonly TCol[]) => {
      pinnedColumnRef.current = newColumnsOrder.includes(pinnedColumnRef.current as TCol)
        ? pinnedColumnRef.current
        : null
      columnsOrderRef.current = newColumnsOrder

      setLSItem("tableColumns", {
        ...(getLSItem("tableColumns")?.data ?? {}),
        [tableName]: {
          pinnedColumn: pinnedColumnRef.current,
          columnsOrder: newColumnsOrder,
        },
      })
    },
    [columnsOrderRef, pinnedColumnRef, tableName]
  )
  const handleChangePinnedColumn = React.useCallback(
    (newPinnedColumn: TCol | null) => {
      pinnedColumnRef.current =
        newPinnedColumn === null || !columnsOrderRef.current.includes(newPinnedColumn) ? null : newPinnedColumn

      setLSItem("tableColumns", {
        ...(getLSItem("tableColumns")?.data ?? {}),
        [tableName]: {
          pinnedColumn: pinnedColumnRef.current,
          columnsOrder: columnsOrderRef.current,
        },
      })
    },
    [columnsOrderRef, pinnedColumnRef, tableName]
  )

  return React.useMemo(
    () => ({
      pinnedColumn,
      onChangePinnedColumn: handleChangePinnedColumn,
      columnsOrder,
      onColumnsOrderChange: handleChangeColumnsOrder,
    }),
    [columnsOrder, handleChangeColumnsOrder, handleChangePinnedColumn, pinnedColumn]
  )
}

import React from "react"

import {createSimpleContext} from "../../../utils/context.tsx"
import {TColumnsMeta} from "./shared.ts"

type TColumnSizeContext = {
  getColumnX: (column: string | undefined | null) => number
  setColumnSize: (column: string, size: number) => void
  getColumnSize: (column: string | undefined | null) => number
}

export const ColumnSizeContext = createSimpleContext<TColumnSizeContext>("column size")

export function useColumnSizes(columnsMeta: TColumnsMeta<any, any>): TColumnSizeContext {
  const [columnSizes, setColumnSizes] = React.useState<{[column: string]: number}>({})

  const getColumnX = React.useCallback<TColumnSizeContext["getColumnX"]>(
    (column: string | undefined | null) => {
      if (column == null) {
        return 0
      }

      let total = 0
      for (const columnMeta of columnsMeta) {
        if (columnMeta.column === column) {
          break
        }

        total += columnSizes[columnMeta.column] ?? 0
      }

      return total
    },
    [columnSizes, columnsMeta]
  )

  const setColumnSize = React.useCallback<TColumnSizeContext["setColumnSize"]>((column, size) => {
    setColumnSizes(sizes => {
      if (sizes[column] === size) {
        return sizes
      }
      return {...sizes, [column]: size}
    })
  }, [])

  const getColumnSize = React.useCallback(
    (column: string | undefined | null) => (column == null ? 0 : (columnSizes[column] ?? 0)),
    [columnSizes]
  )

  return React.useMemo(() => ({setColumnSize, getColumnX, getColumnSize}), [getColumnSize, getColumnX, setColumnSize])
}

export function useColumnX(column: string) {
  const {getColumnX} = ColumnSizeContext.useContext()

  return getColumnX(column)
}

import React from "react"
import {CheckIcon, XMarkIcon} from "@heroicons/react/20/solid"
import {twMerge} from "tailwind-merge"

import {UnstyledLink} from "../../components/Link"
import {Loading} from "../../components/Loading"
import {useNumParam} from "../../utils/hooks"
import {EStepStatus, TStep} from "./types"
import {makeLink} from "./utils"

export const Step: React.FC<{
  step: TStep
  stepStatus: EStepStatus
}> = ({step, stepStatus}) => {
  const salesCycleId = useNumParam("salesCycleId")

  return (
    <li key={step.name} className={`group relative status-${stepStatus} pb-10 last:pb-0`}>
      <div
        className={twMerge(
          "group-last:hidden",
          "absolute top-4 left-4 mt-0.5 -ml-px h-full w-0.5",
          "bg-cr-grey-15",
          "group-[.status-error]:bg-cr-red",
          "group-[.status-completed]:bg-cr-blue",
          "group-[.status-current]:bg-cr-grey-15"
        )}
        aria-hidden={"true"}
      />
      <UnstyledLink
        to={makeLink(step.href, salesCycleId)}
        className={"group relative flex"}
        aria-current={stepStatus === EStepStatus.current ? "step" : undefined}
      >
        <span className={"flex h-9 items-center"} aria-hidden={"true"}>
          <Icon stepStatus={stepStatus} />
        </span>
        <div className={"ml-4 flex min-w-0 items-center"}>
          <span
            className={twMerge(
              "text-sm font-medium",
              "group-[.status-error]:text-cr-red",
              "group-[.status-error:hover]:text-cr-red",
              "group-[.status-current]:text-cr-blue",
              "group-[.status-current:hover]:text-cr-blue-dark",
              "group-[.status-upcoming]:text-cr-grey-50"
            )}
          >
            {step.name}
          </span>
        </div>
      </UnstyledLink>
    </li>
  )
}

const Icon: React.FC<{stepStatus: EStepStatus}> = ({stepStatus}) => {
  switch (stepStatus) {
    case EStepStatus.current: {
      return (
        <span
          className={
            "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-cr-blue bg-white"
          }
        >
          <span className={"h-2.5 w-2.5 rounded-full bg-cr-blue"} />
        </span>
      )
    }
    case EStepStatus.completed: {
      return (
        <span
          className={
            "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-cr-blue transition-colors group-hover:bg-cr-blue-dark"
          }
        >
          <CheckIcon className={"h-5 w-5 text-white"} aria-hidden={"true"} />
        </span>
      )
    }
    case EStepStatus.error: {
      return (
        <span
          className={"relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-cr-red transition-colors"}
        >
          <XMarkIcon className={"h-5 w-5 text-white"} aria-hidden={"true"} />
        </span>
      )
    }
    case EStepStatus.upcoming: {
      return (
        <span
          className={
            "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-cr-grey-15 bg-white transition-colors group-hover:border-cr-grey-50"
          }
        >
          <span className={"h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-cr-grey-15"} />
        </span>
      )
    }
    case EStepStatus.loading: {
      return (
        <span
          className={
            "pointer-events-none relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-transparent bg-white"
          }
        >
          <Loading delayShow={false} fullSpace={false} />
        </span>
      )
    }
  }
}

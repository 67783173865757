import React, {ChangeEventHandler} from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert"
import {InputBase} from "../../../components/fields/Input"
import {DropdownBase} from "../../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../../components/formElements/Dropdown/types"
import {Loading} from "../../../components/Loading"
import Pagination from "../../../components/Pagination"
import {RowCountPickerLS, usePageSize} from "../../../components/RowCountPicker"
import {useRowChecking} from "../../../components/Table/utils/rowChecking.tsx"
import {TOrderBy} from "../../../components/Table/utils/shared.ts"
import {i18n} from "../../../i18n"
import {useSaasCompanyFinishedTableQuery} from "../../../queries/prospects"
import {ASaasCompanyUserProspect} from "../../../services/types.generated"
import {EMPTY_ARRAY} from "../../../utils"
import {useDebouncedValue} from "../../../utils/hooks"
import {DeletingContext} from "../shared/context"
import {CRMButton} from "../shared/CRMButton.tsx"
import {DataTable, TAdminTableColumn} from "../shared/DataTable.tsx"
import {UploadButton} from "../shared/UploadButton.tsx"

enum EBulkAction {
  DELETE,
}

const bulkActionOptions: Array<TOption<EBulkAction>> = [
  {title: i18n.t("Prospects_BulkAction_Delete"), value: EBulkAction.DELETE},
]

type TProps<TQuery extends typeof useSaasCompanyFinishedTableQuery> = {
  salesCycleId: number
  assignmentId: number
  isAddButtonVisible?: boolean
  isCRMButtonVisible?: (data: ReturnType<TQuery>["data"]) => boolean
  listQuery: TQuery
  title: React.ReactNode
  paginationKey: string
}

export function Section<TQuery extends typeof useSaasCompanyFinishedTableQuery>({
  assignmentId,
  salesCycleId,
  isAddButtonVisible,
  isCRMButtonVisible,
  listQuery,
  title,
  paginationKey,
}: TProps<TQuery>): React.ReactNode {
  const {t} = useTranslation()

  const [searchString, setSearchString] = React.useState("")
  const searchStringDebounced = useDebouncedValue(searchString)

  const [orderBy, setOrderBy] = React.useState<TOrderBy<TAdminTableColumn>>(undefined)

  const handleChangeSearchString = React.useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setSearchString(e.target.value)
  }, [])

  const {setValue: setDeletingProspects} = DeletingContext.useContext()

  const {data, error, isFetching, isLoading, refetch, pagination} = listQuery({
    assignmentId,
    searchString: searchStringDebounced,
    salesCycleId,
    orderBy,
    pageSize: usePageSize(paginationKey),
  })
  const prospects = (data?.data.prospects ?? EMPTY_ARRAY) as ASaasCompanyUserProspect[]

  const {checkedRows, checkRow} = useRowChecking(prospects)

  const handleBulkAction = React.useCallback(
    async (action: EBulkAction | undefined) => {
      if (action === EBulkAction.DELETE) {
        setDeletingProspects(checkedRows as number[])
        return
      }
    },
    [checkedRows, setDeletingProspects]
  )

  return (
    <div className={"flex flex-col gap-4"}>
      <h3 className={"font-bold"}>{title}</h3>
      <div className={"flex flex-wrap-reverse justify-between gap-10"}>
        <div className={"flex flex-wrap items-center gap-4"}>
          <div className={"min-w-[150px]"}>
            <DropdownBase
              options={bulkActionOptions}
              placeholder={t("Prospects_BulkAction_Placeholder", {count: checkedRows.length})}
              value={undefined}
              onChange={handleBulkAction}
              disabled={checkedRows.length === 0}
            />
          </div>
        </div>
        <div className={"flex flex-wrap items-center gap-4"}>
          {isAddButtonVisible && <UploadButton variant={"light"} />}
          {isCRMButtonVisible?.(data) && <CRMButton assignmentId={assignmentId} />}
          <InputBase value={searchString} onChange={handleChangeSearchString} placeholder={t("SearchPlaceholder")} />
        </div>
      </div>

      {error ? (
        <GenericErrorAlert />
      ) : isLoading ? (
        <Loading containerClassName={"my-10"} />
      ) : (
        <>
          <DataTable
            data={prospects}
            isLoading={isFetching}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            checkedRows={checkedRows}
            checkRow={checkRow}
            refetch={refetch}
          />
          <RowCountPickerLS total={pagination.total} autoHide paginationKey={paginationKey} />
          <Pagination {...pagination} autoHide />
        </>
      )}
    </div>
  )
}

import React from "react"
import {useTranslation} from "react-i18next"
import {useNavigate} from "react-router"

import {assignmentToSalesperson, Salespeople} from "../../../components/Salespeople"
import {Table as LayoutTable} from "../../../components/Table/Table.tsx"
import {TableRow, TTableRowOnClick} from "../../../components/Table/TableRow.tsx"
import {getColumnsFromMeta} from "../../../components/Table/utils/columns.ts"
import {TColumnsMeta} from "../../../components/Table/utils/shared.ts"
import {useLSTableColumnsState} from "../../../components/Table/utils/useLSTableColumnsState.tsx"
import {getCurrentLanguage} from "../../../i18n"
import {ASalesCycleIteration, ASalesCycleIterationsListData} from "../../../services/types.generated"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"

type TCol = "salesPlanName" | "company_name" | "month" | "country" | "salesTalent"
const tableId = "admin/matching"

const Table: React.FC<{
  rows: ASalesCycleIterationsListData["sales_cycle_iterations"]
  loading?: boolean
  rowNavigation: boolean
}> = ({rows, loading, rowNavigation}) => {
  const {t} = useTranslation()

  const navigate = useNavigate()

  const columnsMeta = React.useMemo<TColumnsMeta<TCol, ASalesCycleIteration>>(
    () => [
      {
        column: "salesPlanName",
        HeaderCellValue: () => t("T_Sales plan name"),
        CellValue: ({row}) => <span className={"font-medium"}>{row.sales_cycle.name}</span>,
      },
      {
        column: "company_name",
        HeaderCellValue: () => t("T_Company name"),
      },
      {
        column: "month",
        HeaderCellValue: () => t("T_Month"),
        CellValue: ({row}) =>
          apiDateToJS(row.start_date).toLocaleString(getCurrentLanguage(), {month: "short", year: "numeric"}),
      },
      {
        column: "country",
        HeaderCellValue: () => t("T_Country"),
        CellValue: ({row}) => row.sales_cycle.country.name,
      },
      {
        column: "salesTalent",
        HeaderCellValue: () => t("T_Salespeople"),
        CellValue: ({row}) => (
          <Salespeople small max={10} showReaches salesPeople={row.assignments.map(assignmentToSalesperson)} />
        ),
      },
    ],
    [t]
  )

  const handleClick = React.useCallback<TTableRowOnClick<ASalesCycleIteration>>(
    row => {
      navigate(`detail/${row.id}`, {state: {iteration: row}})
    },
    [navigate]
  )

  return (
    <LayoutTable<TCol, ASalesCycleIteration>
      loading={loading}
      className={"my-8"}
      columnsMeta={columnsMeta}
      data={rows}
      {...useLSTableColumnsState(tableId, {columnsOrder: getColumnsFromMeta(columnsMeta), pinnedColumn: null})}
    >
      {({data, pinnedColumn}) =>
        data.map((row, index) => (
          <TableRow
            key={row.id}
            onClick={handleClick}
            isClickDisabled={!rowNavigation}
            row={row}
            rowIndex={index}
            pinnedColumn={pinnedColumn}
          />
        ))
      }
    </LayoutTable>
  )
}

export default Table

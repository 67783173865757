import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import Button from "../../../components/Button.tsx"
import {Flyout} from "../../../components/Flyout.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {useProspectActivitiesQuery} from "../../../queries/prospects.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {Activity} from "../../SellingDetails/shared/ActivityFeed/components/Activity.tsx"
import {ActivityDayTitle} from "../../SellingDetails/shared/ActivityFeed/components/ActivityDayTitle.tsx"
import {groupActivities} from "../../SellingDetails/shared/ActivityFeed/utils.ts"
import {ActivityFeedContext, useCurrentSelectionState} from "../hooks.ts"

export const ActivityFeedFlyout: React.FC<{isAboveModal: boolean}> = ({isAboveModal}) => {
  const {t} = useTranslation()

  const {value, setValue} = ActivityFeedContext.useContext()

  const {
    value: {iterationId},
  } = useCurrentSelectionState()

  const {data, isPending, error, refetch} = useProspectActivitiesQuery({
    prospectId: value?.id ?? undefined,
    iterationId: iterationId ?? undefined,
  })
  const activities = data?.data.activities
  const groupedActivities = React.useMemo(() => {
    return groupActivities(activities)
  }, [activities])

  const handleClose = React.useCallback(() => {
    setValue(null)
  }, [setValue])

  const isOpen = value != null

  return (
    <Flyout
      title={
        <div className={"flex flex-col gap-2 font-normal"}>
          <span className={"font-bold"}>{value?.organization_name}</span>
          <span className={"text-xs"}>#{value?.id}</span>
        </div>
      }
      buttons={
        <div className={"flex w-full justify-end"}>
          <Button variant={"outlined"} onClick={handleClose}>
            {t("Reports_Prospects_ActivityFeed_Cancel")}
          </Button>
        </div>
      }
      isOpen={isOpen}
      onClose={handleClose}
      isAboveModal={isAboveModal}
    >
      <div className={"grow p-6"}>
        {error ? (
          <GenericErrorAlert retry={refetch} />
        ) : isPending && isOpen ? (
          <Loading />
        ) : (activities ?? []).length === 0 ? (
          <div className={"flex grow items-center justify-center text-sm"}>
            {t("Reports_Prospects_ActivityFeed_NoActivities")}
          </div>
        ) : (
          <div className={"relative flex flex-col justify-between"}>
            <div role={"list"}>
              {groupedActivities.map(({date, activities}) => (
                <div key={date} role={"group"}>
                  <ActivityDayTitle date={apiDateToJS(date)} />

                  <div className={"divide-y divide-cr-blue-light"}>
                    {activities.map(activity => (
                      <Activity key={activity.id} activity={activity} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Flyout>
  )
}

import React from "react"
import {twMerge} from "tailwind-merge"

export const LayoutBlock: React.FC<React.PropsWithChildren<{outerClassName?: string; innerClassName?: string}>> = ({
  innerClassName,
  outerClassName,
  children,
}) => {
  return (
    <div className={twMerge(["flex justify-center px-6 lg:px-8", outerClassName])}>
      <div className={twMerge(["w-full max-w-(--breakpoint-2xl)", innerClassName])}>{children}</div>
    </div>
  )
}

import React from "react"
import {useTranslation} from "react-i18next"

import {useReportsClientsReachedChartQuery} from "../../../../queries/reports"
import {useAreMeetingsDisabled} from "../../hooks"
import {SimpleBarGraphCard} from "./SimpleBarGraphCard"

export const ClientsReachedGraphCard: React.FC = () => {
  const {t} = useTranslation()

  const areMeetingsDisabled = useAreMeetingsDisabled()

  return (
    <SimpleBarGraphCard
      metric={"clients_reached"}
      query={useReportsClientsReachedChartQuery}
      config={[
        {key: "emails" as const, label: t("Reports_Chart_Activities_Emails"), color: "var(--color-cr-blue-mid)"},
        {
          key: "calls" as const,
          label: t("Reports_Chart_Activities_Calls"),
          color: "var(--color-cr-cyan)",
        },
        ...(areMeetingsDisabled
          ? []
          : [
              {
                key: "meetings" as const,
                label: t("Reports_Chart_Activities_Meetings"),
                color: "var(--color-cr-blue)",
              },
            ]),
      ]}
    />
  )
}

import React from "react"
import {useTranslation} from "react-i18next"

import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {EOrderDirection} from "../../../components/Table/utils/shared.ts"
import {sortDate} from "../../../components/Table/utils/sortFunctions.ts"
import {
  TSaasCompanyProspectsAssignment,
  TSaasCompanyProspectsIteration,
  useProspectsSalesCycleQuery,
  useSaasCompanyFinishedTableQuery,
} from "../../../queries/prospects.ts"
import {ASaasCompanyProspectsSalesCycle} from "../../../services/types.generated.ts"
import {CombineProviders} from "../../../utils/context.tsx"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"
import {useDocumentTitle, useNumParam} from "../../../utils/hooks.tsx"
import {PeriodToggle, TPeriodToggleValue} from "../../Reports/components/PeriodToggle.tsx"
import {AddProspectsModal} from "../shared/AddProspectsModal"
import {EFeature} from "../shared/AddProspectsModal/utils.ts"
import {
  DeletingContext,
  EditingContext,
  IsUploadingContext,
  ProspectsContext,
  useProspectsContextValue,
} from "../shared/context.ts"
import {DeleteModal} from "../shared/DeleteModal.tsx"
import {EditingFlyout} from "../shared/EditingFlyout.tsx"
import {NoProspects} from "../shared/NoProspects.tsx"
import {getActiveAssignment, salesCycleRemoveIterationsWithoutAssignments} from "../shared/utils.ts"
import {Section} from "./Section.tsx"

export const SaasSalespersonProspects: React.FC = () => {
  const {t} = useTranslation()

  useDocumentTitle(t("T_Prospects"))

  const salesCycleId = useNumParam("salesCycleId")
  const {
    data: salesCycleData,
    error: salesCycleError,
    isPending: isSalesCycleLoading,
  } = useProspectsSalesCycleQuery(salesCycleId)

  const salesCycle = React.useMemo(() => {
    if (!salesCycleData) {
      return null
    }

    return salesCycleRemoveIterationsWithoutAssignments(salesCycleData)
  }, [salesCycleData])

  if (isSalesCycleLoading) {
    return <Loading size={"xl"} />
  }

  if (salesCycleError || !salesCycle) {
    return <GenericErrorAlert />
  }

  return <ProspectsLoaded salesCycle={salesCycle as ASaasCompanyProspectsSalesCycle} />
}

const ProspectsLoaded: React.FC<{salesCycle: ASaasCompanyProspectsSalesCycle}> = ({salesCycle}) => {
  const {t} = useTranslation()

  const isUploadingContextValue = IsUploadingContext.useProviderValue(false)
  const editingContextValue = EditingContext.useProviderValue(null)
  const deleteContextValue = DeletingContext.useProviderValue(null)

  const prospectsContextValue = ProspectsContext.useProviderValue(useProspectsContextValue(salesCycle, true))
  const {assignment, iteration, setValue: setProspectsContext} = prospectsContextValue

  const activeIteration = iteration as TSaasCompanyProspectsIteration | undefined
  const activeAssignment = assignment as TSaasCompanyProspectsAssignment | undefined

  const iterationOptions = React.useMemo<string[]>(() => {
    return [...salesCycle.sales_cycle_iterations]
      .sort(
        sortDate<TSaasCompanyProspectsIteration>(iteration => apiDateToJS(iteration.start_date))(EOrderDirection.DESC)
      )
      .map(iteration => iteration.start_date)
  }, [salesCycle.sales_cycle_iterations])

  const handleChangeIteration = React.useCallback(
    (startDate: TPeriodToggleValue) => {
      const newIteration = salesCycle.sales_cycle_iterations.find(iteration => iteration.start_date === startDate)

      setProspectsContext({
        salesCycleId: salesCycle.id,
        iterationId: newIteration?.id,
        assignmentId: newIteration && getActiveAssignment(newIteration, activeAssignment)?.id,
      })
    },
    [activeAssignment, salesCycle.id, salesCycle.sales_cycle_iterations, setProspectsContext]
  )

  if (!activeIteration || !activeAssignment) {
    return (
      <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-9 items-center"}>
        <h2 className={"text-2xl font-semibold"}>{t("Prospects_SaasSalesperson_NoIterations_Title")}</h2>
        <h3 className={"text-cr-grey-50"}>{t("Prospects_SaasSalesperson_NoIterations_Text")}</h3>
      </LayoutBlock>
    )
  }

  return (
    <CombineProviders
      providers={[
        ProspectsContext.combined(prospectsContextValue),
        IsUploadingContext.combined(isUploadingContextValue),
        DeletingContext.combined(deleteContextValue),
        EditingContext.combined(editingContextValue),
      ]}
    >
      <LayoutBlock outerClassName={"py-16"} innerClassName={"flex flex-col gap-9"}>
        <div>
          <PeriodToggle
            periods={iterationOptions}
            value={activeIteration.start_date}
            onChange={handleChangeIteration}
            noLifetime
          />
        </div>

        {activeAssignment.prospects_finished_count > 0 ? (
          <Section
            title={t("Prospects_SaasSalesperson_ProspectsTable_Title", {
              count: activeAssignment.prospects_finished_count,
            })}
            salesCycleId={salesCycle.id}
            assignmentId={activeAssignment.id}
            isAddButtonVisible
            isCRMButtonVisible={data => data?.data.meta?.sync_crm_button ?? false}
            listQuery={useSaasCompanyFinishedTableQuery}
            paginationKey={"prospects saas salesperson finished"}
          />
        ) : (
          <NoProspects />
        )}
      </LayoutBlock>

      <EditingFlyout />
      <DeleteModal />
      <AddProspectsModal enabledFeatures={[EFeature.Single]} />
    </CombineProviders>
  )
}

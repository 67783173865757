import React from "react"
import {Trans, useTranslation} from "react-i18next"
import {PhoneIcon} from "@heroicons/react/24/outline"

import {CheckboxConnected} from "../../../../components/fields/Checkbox.tsx"
import {FieldErrorMessage} from "../../../../components/fields/components.tsx"
import {InputField} from "../../../../components/fields/Input.tsx"
import {DropdownField} from "../../../../components/formElements/Dropdown/Dropdown.tsx"
import {Link} from "../../../../components/Link.tsx"
import {useCountryOptions} from "../../../../utils/hooks.tsx"

export const DetailsPage: React.FC = () => {
  const {t} = useTranslation()
  const countryOptions = useCountryOptions()

  return (
    <>
      <InputField
        label={t("Signup_SaaSSalesperson_AccountCreatePage_FirstName")}
        name={"first_name"}
        variant={"medium"}
      />
      <InputField
        label={t("Signup_SaaSSalesperson_AccountCreatePage_LastName")}
        name={"last_name"}
        variant={"medium"}
      />
      <InputField
        Icon={PhoneIcon}
        label={t("Signup_SaaSSalesperson_AccountCreatePage_PhoneNumber")}
        placeholder={t("Signup_SaaSSalesperson_AccountCreatePage_PhoneNumberPlaceholder")}
        name={"phone_number"}
        variant={"medium"}
      />
      <DropdownField
        name={"countryId"}
        label={t("Signup_SaaSSalesperson_AccountCreatePage_Country")}
        options={countryOptions}
        variant={"medium"}
      />
      <div className={"my-2"}>
        <CheckboxConnected name={"privacyPolicyAgreed"}>
          <Trans
            i18nKey={"Signup_SaaSSalesperson_AccountCreatePage_PrivacyPolicy"}
            components={{
              privacyLink: <Link flipUnderline to={t("Url_PrivacyPolicy")} target={"_blank"} />,
            }}
          />
        </CheckboxConnected>
        <FieldErrorMessage name={"privacyPolicyAgreed"} reserveSpace />
      </div>
    </>
  )
}

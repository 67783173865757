import React from "react"
import {useFormContext} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {useGetFieldVisibleError} from "../../components/fields/components"
import {FieldLabel} from "../../components/fields/FieldLabel"
import {InputConnected} from "../../components/fields/Input"
import {DropdownBase} from "../../components/formElements/Dropdown/Dropdown.tsx"
import {TOption} from "../../components/formElements/Dropdown/types"
import {TDropdownOptionProps} from "../../components/formElements/Dropdown/types.ts"
import {i18n} from "../../i18n"
import {ACommissionTypes, ACommissionValueTypes, AGoals} from "../../services/types.generated"
import {commissionTitle} from "../../utils/goals.ts"
import {enumTranslKey} from "../../utils/i18n"

type TCommissionMeta = {commission_type: ACommissionTypes; commission_value_type: ACommissionValueTypes}

const goalOptions: Array<TOption<AGoals>> = [AGoals.CloseDeal, AGoals.SetupMeeting].map(goal => ({
  value: goal,
  title: i18n.t(enumTranslKey("ProductGoal", goal)),
}))

export const Commissions: React.FC = () => {
  const {t} = useTranslation()
  const {watch, setValue} = useFormContext()

  const error = useGetFieldVisibleError("products_attributes.0.commission")

  const goal = watch("products_attributes.0.goal") as AGoals | undefined
  const commissionType = watch("products_attributes.0.commission_type") as ACommissionTypes | undefined
  const commissionValueType = watch("products_attributes.0.commission_value_type") as ACommissionValueTypes | undefined
  const commissionMeta = React.useMemo<TCommissionMeta | undefined>(() => {
    if (!commissionType || !commissionValueType) {
      return undefined
    }

    return {commission_type: commissionType, commission_value_type: commissionValueType}
  }, [commissionType, commissionValueType])

  const options = React.useMemo(
    () =>
      goal
        ? getOptionsByGoal(goal).map(option => ({
            ...option,
            key: `${option.value.commission_type} - ${option.value.commission_value_type}`,
          }))
        : [],
    [goal]
  )

  React.useEffect(() => {
    if (goalOptions.some(option => option.value === goal)) {
      return
    }

    setValue("products_attributes.0.goal", undefined)
  }, [goal, setValue])

  return (
    <>
      <FieldLabel
        name={"products_attributes.0.goal"}
        label={t("SalesStrategy_Goal definition")}
        legend={t("SalesStrategy_What is the main goal you want to achieve by reaching each prospect?")}
        required
      >
        <DropdownBase
          options={goalOptions}
          value={goal}
          onChange={newGoal => {
            setValue("products_attributes.0.commission", undefined)
            setValue("products_attributes.0.commission_type", undefined)
            setValue("products_attributes.0.commission_value_type", undefined)
            setValue("products_attributes.0.goal", newGoal, {
              shouldTouch: true,
              shouldDirty: true,
              shouldValidate: true,
            })
          }}
          placeholder={t("SalesStrategy_Choose a goal")}
          name={"goal"}
        />
      </FieldLabel>

      {goal && (
        <FieldLabel
          label={t("SalesStrategy_Commission type")}
          name={"products_attributes.0.commission"}
          legend={t("SalesStrategy_Choose how to compensate your sales talents based on their performance.")}
          required
        >
          <div className={twMerge(["input-border flex w-full gap-2 p-1", !!error && "input-border-error"])}>
            <div className={twMerge(["relative min-w-0", commissionType ? "shrink-1" : "grow"])}>
              <DropdownBase
                value={commissionMeta}
                onChange={newValue => {
                  if (!newValue) {
                    return
                  }
                  setValue("products_attributes.0.commission_type", newValue.commission_type, {
                    shouldTouch: true,
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                  setValue("products_attributes.0.commission_value_type", newValue.commission_value_type, {
                    shouldTouch: true,
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
                options={options}
                listWidth={"clamp(150px, 502px, 80vw)"}
                OptionValue={OptionValue}
                placeholder={t("SalesStrategy_Choose commission type")}
                name={"commisionType"}
                mainButtonClassName={"input-border-ghost"}
                listClassName={"-ml-1"}
              />
            </div>
            <InputConnected
              name={"products_attributes.0.commission"}
              ghost
              borderClassName={twMerge(["min-w-0 shrink-0 grow", !commissionMeta && "hidden"])}
              inputClassName={"text-right"}
              options={{valueAsNumber: true}}
            >
              <div className={"pr-2"}>
                {commissionMeta?.commission_value_type === ACommissionValueTypes.Percentage ? "%" : "€"}
              </div>
            </InputConnected>
          </div>
        </FieldLabel>
      )}
    </>
  )
}

export const OptionValue: React.FC<TDropdownOptionProps> = ({option}) => {
  const {watch} = useFormContext()
  const goal = watch("products_attributes.0.goal") as AGoals | undefined

  if (!goal) {
    return null
  }

  const optionValue = option.value as TCommissionMeta

  return (
    <div className={"my-1 flex flex-col gap-2 text-sm"}>
      <strong>{option.title}</strong>
      <div>{commissionDescription[goal][optionValue.commission_type][optionValue.commission_value_type]}</div>
    </div>
  )
}

const commissionDescription = {
  [AGoals.CloseDeal]: {
    [ACommissionTypes.OneOff]: {
      [ACommissionValueTypes.FixedPrice]: i18n.t("CommissionDescription_CloseDeal_OneOff_FixedPrice"),
      [ACommissionValueTypes.Percentage]: i18n.t("CommissionDescription_CloseDeal_OneOff_Percentage"),
    },
    [ACommissionTypes.Recurring]: {
      [ACommissionValueTypes.FixedPrice]: i18n.t("CommissionDescription_CloseDeal_Recurring_FixedPrice"),
      [ACommissionValueTypes.Percentage]: i18n.t("CommissionDescription_CloseDeal_Recurring_Percentage"),
    },
  },
  [AGoals.SetupMeeting]: {
    [ACommissionTypes.OneOff]: {
      [ACommissionValueTypes.FixedPrice]: i18n.t("CommissionDescription_SetupMeeting_OneOff_FixedPrice"),
      [ACommissionValueTypes.Percentage]: "",
    },
    [ACommissionTypes.Recurring]: {
      [ACommissionValueTypes.FixedPrice]: "",
      [ACommissionValueTypes.Percentage]: "",
    },
  },
  [AGoals.Registration]: {
    [ACommissionTypes.OneOff]: {
      [ACommissionValueTypes.FixedPrice]: i18n.t("CommissionDescription_Registration_OneOff_FixedPrice"),
      [ACommissionValueTypes.Percentage]: "",
    },
    [ACommissionTypes.Recurring]: {
      [ACommissionValueTypes.FixedPrice]: "",
      [ACommissionValueTypes.Percentage]: "",
    },
  },
  [AGoals.ContractConclusion]: {
    [ACommissionTypes.OneOff]: {
      [ACommissionValueTypes.FixedPrice]: i18n.t("CommissionDescription_ContractConclusion_OneOff_FixedPrice"),
      [ACommissionValueTypes.Percentage]: i18n.t("CommissionDescription_ContractConclusion_OneOff_Percentage"),
    },
    [ACommissionTypes.Recurring]: {
      [ACommissionValueTypes.FixedPrice]: i18n.t("CommissionDescription_ContractConclusion_Recurring_FixedPrice"),
      [ACommissionValueTypes.Percentage]: i18n.t("CommissionDescription_ContractConclusion_Recurring_Percentage"),
    },
  },
} satisfies {
  [goal in AGoals]: {[commissionType in ACommissionTypes]: {[commissionValueType in ACommissionValueTypes]: string}}
}

const combinationsByGoal = {
  [AGoals.CloseDeal]: {
    commission_types: [ACommissionTypes.OneOff, ACommissionTypes.Recurring],
    commission_value_types: [ACommissionValueTypes.FixedPrice, ACommissionValueTypes.Percentage],
  },
  [AGoals.SetupMeeting]: {
    commission_types: [ACommissionTypes.OneOff],
    commission_value_types: [ACommissionValueTypes.FixedPrice],
  },
  [AGoals.Registration]: {
    commission_types: [ACommissionTypes.OneOff],
    commission_value_types: [ACommissionValueTypes.FixedPrice],
  },
  [AGoals.ContractConclusion]: {
    commission_types: [ACommissionTypes.OneOff, ACommissionTypes.Recurring],
    commission_value_types: [ACommissionValueTypes.FixedPrice, ACommissionValueTypes.Percentage],
  },
} satisfies {
  [goal in AGoals]: {
    commission_types: ACommissionTypes[]
    commission_value_types: ACommissionValueTypes[]
  }
}

const getOptionsByGoal = (goal: AGoals): Array<TOption<TCommissionMeta>> => {
  return combinationsByGoal[goal].commission_types.flatMap(commission_type =>
    combinationsByGoal[goal].commission_value_types.map(commission_value_type => ({
      value: {commission_type, commission_value_type},
      title: commissionTitle[commission_type][commission_value_type],
    }))
  )
}

import {useQuery} from "@tanstack/react-query"

import {usePaginatedQuery} from "../components/Pagination.tsx"
import {EOrderDirection, TOrderBy} from "../components/Table/utils/shared.ts"
import {TSalesPlansTableColumn as TSalesPlansTableColumnAdmin} from "../routes/Admin/Sellings/utils.ts"
import {TSalesPlansFiltering as TSalesPlansFilteringSaaS} from "../routes/DashboardSaaS/utils.ts"
import api, {queryKey} from "../services"
import {
  ASalesPlanQueryStatusValues,
  ASalesPlanSortByValues,
  ASalesPlanSortDirectionValues,
  AUserTypes,
} from "../services/types.generated.ts"
import {useValueByUserType} from "../utils/userTypes.ts"

const salesPlansSortColumnToApiAdmin = (
  column: TSalesPlansTableColumnAdmin | undefined
): ASalesPlanSortByValues | undefined => {
  switch (column) {
    case "current_value":
      return ASalesPlanSortByValues.CurrentValue
    case "created_at":
      return ASalesPlanSortByValues.CreatedAt
    default:
      return undefined
  }
}

const directionToApiAdmin = (direction: EOrderDirection | undefined): ASalesPlanSortDirectionValues | undefined => {
  switch (direction) {
    case EOrderDirection.ASC:
      return ASalesPlanSortDirectionValues.Asc
    case EOrderDirection.DESC:
      return ASalesPlanSortDirectionValues.Desc
    default:
      return undefined
  }
}
export const useAdminSalesPlansQuery = (
  tabCurrentHref: ASalesPlanQueryStatusValues | "all",
  filters: {orderBy: TOrderBy<TSalesPlansTableColumnAdmin>; searchString: string; countryId: number | undefined}
) =>
  usePaginatedQuery(queryKey.salesCycleIterations(JSON.stringify({...filters, tabCurrentHref})), paginationQuery =>
    api.salesPlans.salesPlansList({
      ...paginationQuery,
      search: filters.searchString,
      country_id: filters.countryId,
      sort_by: salesPlansSortColumnToApiAdmin(filters.orderBy?.column),
      sort_direction: directionToApiAdmin(filters.orderBy?.direction),
      status: tabCurrentHref === "all" ? undefined : tabCurrentHref,
    })
  )

export const useSaaSSalesPlansQuery = (filters: Pick<TSalesPlansFilteringSaaS, "startDate">) => {
  const queryFn = useValueByUserType({
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers.salesCyclesList,
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.salesCyclesList,
  })

  return usePaginatedQuery(queryKey.salesCycleIterations(JSON.stringify(filters)), paginationQuery =>
    queryFn({
      ...paginationQuery,
      start_date: filters.startDate,
    })
  )
}

export const useSaaSDashboardQuery = () => {
  const queryFn = useValueByUserType({
    [AUserTypes.SaasCompanyUser]: api.saasCompanyUsers.dashboardList,
    [AUserTypes.SaasSalesPerson]: api.saasSalesPeople.dashboardList,
  })

  return useQuery({
    queryKey: queryKey.saasDashboard,
    queryFn: async () => {
      return (await queryFn()).data.dashboard
    },
  })
}

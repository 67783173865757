import {produce} from "immer"

import {EOrderDirection} from "../../../components/Table/utils/shared.ts"
import {sortDate} from "../../../components/Table/utils/sortFunctions.ts"
import {
  TAdminsProspectsIteration,
  TCompanyProspectsIteration,
  TSaasCompanyProspectsIteration,
  TSalespersonProspectsIteration,
} from "../../../queries/prospects.ts"
import {
  AAdminsProspectsSalesCycle,
  ACompanyProspectsSalesCycle,
  ASaasCompanyProspectsSalesCycle,
  ASalesPersonProspectsSalesCycle,
} from "../../../services/types.generated.ts"
import {apiDateToJS} from "../../../utils/dateArithmetics.ts"

export type TProspectsSalesCycle =
  | ACompanyProspectsSalesCycle
  | AAdminsProspectsSalesCycle
  | ASaasCompanyProspectsSalesCycle
  | ASalesPersonProspectsSalesCycle
export type TProspectsIteration =
  | TCompanyProspectsIteration
  | TAdminsProspectsIteration
  | TSaasCompanyProspectsIteration
  | TSalespersonProspectsIteration

export const salesCycleRemoveIterationsWithoutAssignments = <
  T extends {sales_cycle_iterations: Array<{assignments: any[]}>},
>(
  salesCycle: T
): T => {
  return produce(salesCycle, draft => {
    draft.sales_cycle_iterations = draft.sales_cycle_iterations.filter(iteration => {
      return iteration.assignments.length > 0
    })
  })
}

export function getActiveIteration<T extends TProspectsSalesCycle>(
  salesCycle: T,
  currentIteration?: T["sales_cycle_iterations"][number] | null
): T["sales_cycle_iterations"][number] | null {
  const sameIteration = salesCycle.sales_cycle_iterations.find(
    salesCycleIteration => salesCycleIteration.id === currentIteration?.id
  )

  const latestIteration = [...salesCycle.sales_cycle_iterations].sort(
    sortDate<(typeof salesCycle)["sales_cycle_iterations"][number]>(iteration => apiDateToJS(iteration.start_date))(
      EOrderDirection.DESC
    )
  )[0]

  return sameIteration ?? latestIteration ?? null
}

export function getActiveAssignment<T extends TProspectsIteration>(
  activeIteration: T | null,
  currentAssignment?: T["assignments"][number] | null
): T["assignments"][number] | null {
  if (!activeIteration) {
    return null
  }

  const sameAssignment = activeIteration.assignments.find(a => {
    if (
      currentAssignment &&
      "sales_person" in a &&
      "sales_person" in currentAssignment &&
      a.sales_person &&
      currentAssignment.sales_person
    ) {
      return currentAssignment.sales_person?.id === a.sales_person?.id
    }

    return a.id === currentAssignment?.id
  })

  const firstAssignment = activeIteration.assignments[0]

  return sameAssignment ?? firstAssignment ?? null
}

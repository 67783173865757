import React from "react"
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  MeasuringFrequency,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import type {DragEndEvent} from "@dnd-kit/core/dist/types"
import {restrictToHorizontalAxis, restrictToParentElement} from "@dnd-kit/modifiers"
import {FloatingPortal} from "@floating-ui/react"
import {twMerge} from "tailwind-merge"

import {EMPTY_OBJECT} from "../../utils"
import {CombineProviders} from "../../utils/context.tsx"
import {pointerWithinX} from "../../utils/dnd-kit/pointerWithinX.ts"
import {useControlledGetSetProp} from "../../utils/useControlledProp.ts"
import {Loading} from "../Loading.tsx"
import {getColumnIndex, getColumnMeta, getColumnsFromMeta} from "./utils/columns.ts"
import {RowCheckContext, TRowCheckContext} from "./utils/rowChecking.tsx"
import {
  EOrderDirection,
  TColumnMeta,
  TColumns,
  TColumnsMetaWithEmpty,
  TOrderBy,
  TRowId,
  TTableContext,
  TypedTableContext,
} from "./utils/shared.ts"
import {ColumnSizeContext, useColumnSizes} from "./utils/useColumnSizes.ts"
import {DefaultHeaderCell, HeaderCellWrapper} from "./HeaderCell"
import {DropMarker, PinMarker} from "./Markers.tsx"
import {TableRow} from "./TableRow.tsx"

export function Table<
  TCol extends TColumns,
  TRowData extends Record<string, any> & {id: TRowId},
  TMetadata extends Record<string, any> = Record<string, never>,
>({
  columnsMeta: columnsMetaWithEmptyItems,
  columnsOrder: columnsOrderControlled,
  onColumnsOrderChange: onColumnsOrderChangeControlled,
  data,
  ghost,
  noShadow,
  orderBy,
  onOrder,
  loading,
  className,
  checkedRows,
  onCheckRow,
  grow,
  children,
  pinnedColumn: pinnedColumnControlled,
  onChangePinnedColumn: onChangePinnedColumnControlled,
  metadata = EMPTY_OBJECT as TMetadata,
}: (TMetadata extends Record<string, never> ? {metadata?: never} : {metadata: TMetadata}) & {
  columnsMeta: TColumnsMetaWithEmpty<TCol, TRowData>
  data: TRowData[]
  ghost?: boolean
  noShadow?: boolean
  orderBy?: TOrderBy<TCol>
  onOrder?: (newOrderBy: TOrderBy<TCol>) => void
  loading?: boolean
  className?: string
  onCheckRow?: (rowId: TRowId) => void
  checkedRows?: readonly TRowId[]
  grow?: boolean
  children?: ((args: {data: TRowData[]; pinnedColumn: TCol | null}) => React.ReactNode) | React.ReactNode
} & (
    | {pinnedColumn: TCol | null; onChangePinnedColumn: (column: TCol | null) => void}
    | {pinnedColumn?: never; onChangePinnedColumn?: never}
  ) &
  (
    | {columnsOrder: readonly TCol[]; onColumnsOrderChange: (newOrder: readonly TCol[]) => void}
    | {columnsOrder?: never; onColumnsOrderChange?: never}
  )): React.ReactNode {
  const handleOrder = React.useMemo(() => {
    if (!onOrder) {
      return undefined
    }

    return (column: TCol) => () => {
      if (orderBy?.column !== column) {
        return onOrder({column, direction: EOrderDirection.ASC})
      }

      if (orderBy.direction === EOrderDirection.ASC) {
        return onOrder({column, direction: EOrderDirection.DESC})
      }

      return onOrder(undefined)
    }
  }, [onOrder, orderBy?.column, orderBy?.direction])

  const columnsMeta = React.useMemo(
    () => columnsMetaWithEmptyItems.filter((item): item is TColumnMeta<TCol, TRowData> => !!item),
    [columnsMetaWithEmptyItems]
  )

  const [pinnedColumn, onChangePinnedColumn] = useControlledGetSetProp(
    pinnedColumnControlled,
    onChangePinnedColumnControlled,
    null
  )

  const [columnsOrder, onColumnsOrderChange] = useControlledGetSetProp(
    columnsOrderControlled,
    onColumnsOrderChangeControlled,
    getColumnsFromMeta(columnsMeta)
  )

  const orderedColumnsMeta = React.useMemo(() => {
    return columnsOrder
      ? columnsOrder.map(column => getColumnMeta(columnsMeta, column)).filter(meta => !!meta)
      : columnsMeta
  }, [columnsMeta, columnsOrder])

  const contextValue = React.useMemo<TTableContext<TCol, TRowData, TMetadata>>(
    () => ({
      columnsMeta: orderedColumnsMeta,
      metadata: metadata as TMetadata,
    }),
    [orderedColumnsMeta, metadata]
  )

  const areAllChecked = React.useMemo(() => {
    return !!(checkedRows?.length && data.every(row => checkedRows.some(id => row.id === id)))
  }, [data, checkedRows])

  const handleCheckAll = React.useCallback(() => {
    if (!onCheckRow || !checkedRows) {
      return
    }

    data.forEach(row => {
      const isChecked = checkedRows.includes(row.id)

      if (isChecked === areAllChecked) {
        onCheckRow(row.id)
      }
    })
  }, [areAllChecked, checkedRows, data, onCheckRow])

  const rowCheckContextValue = React.useMemo<TRowCheckContext>(
    () => ({
      onCheck: onCheckRow ?? (() => undefined),
      areAllChecked,
      checked: checkedRows ?? [],
      onCheckAll: handleCheckAll,
    }),
    [areAllChecked, checkedRows, handleCheckAll, onCheckRow]
  )

  const columnSizeContextValue = ColumnSizeContext.useProviderValue(useColumnSizes(orderedColumnsMeta))

  const [draggingColumn, setDraggingColumn] = React.useState<TCol | null>(null)

  const handleDragStart = React.useCallback<(event: DragStartEvent) => void>(e => {
    setDraggingColumn(e.active.id as TCol)
  }, [])

  const pinnedColumnIndex = getColumnIndex(orderedColumnsMeta, pinnedColumn) ?? -Infinity

  const handleDragEnd = React.useCallback<(event: DragEndEvent) => void>(
    e => {
      setDraggingColumn(null)

      const {active, over} = e

      const overColumn = over?.data.current?.column as TCol | undefined
      const position = over?.data.current?.position as "left" | "right" | undefined

      if (!overColumn || active.id === overColumn) {
        return
      }
      const orderWithoutActive = columnsOrder.filter(column => column !== active.id)
      const overIndex = orderWithoutActive.indexOf(overColumn)
      const newActiveIndex = position === "left" ? overIndex : overIndex + 1

      const newOrder = orderWithoutActive.toSpliced(newActiveIndex, 0, active.id as TCol)
      onColumnsOrderChange(newOrder)

      if (overColumn === pinnedColumn && position === "right") {
        // Pin this column if dropped over last pinned column
        onChangePinnedColumn(active.id as TCol)
      }
      if (active.id === pinnedColumn) {
        // If this was the last pinned column, pin the one behind it instead
        const newPinnedColumn = pinnedColumnIndex === 0 ? null : columnsOrder[pinnedColumnIndex - 1]
        onChangePinnedColumn(newPinnedColumn)
      }
    },
    [columnsOrder, onChangePinnedColumn, onColumnsOrderChange, pinnedColumn, pinnedColumnIndex]
  )

  const handleTogglePin = React.useCallback(
    (column: TCol) => {
      const pinnedColumns = columnsOrder.slice(0, pinnedColumnIndex + 1)
      const unpinnedColumns = columnsOrder.slice(pinnedColumnIndex + 1)

      let newPinnedColumns: TCol[]
      let newUnpinnedColumns: TCol[]

      if (pinnedColumns.includes(column)) {
        newPinnedColumns = pinnedColumns.filter(col => col !== column)
        newUnpinnedColumns = [column, ...unpinnedColumns]
      } else {
        newPinnedColumns = [...pinnedColumns, column]
        newUnpinnedColumns = unpinnedColumns.filter(col => col !== column)
      }

      onChangePinnedColumn(newPinnedColumns.at(-1) ?? null)
      onColumnsOrderChange([...newPinnedColumns, ...newUnpinnedColumns])
    },
    [columnsOrder, onChangePinnedColumn, onColumnsOrderChange, pinnedColumnIndex]
  )

  const sensors = useSensors(useSensor(PointerSensor, {activationConstraint: {distance: 5}}))
  const measuring = React.useMemo(() => {
    return {
      droppable: {
        strategy: MeasuringStrategy.Always,
        frequency: draggingColumn ? 500 : MeasuringFrequency.Optimized,
      },
    }
  }, [draggingColumn])

  return (
    <CombineProviders
      providers={[
        RowCheckContext.combined(rowCheckContextValue),
        TypedTableContext<TCol, TRowData, TMetadata>().combined(contextValue),
        ColumnSizeContext.combined(columnSizeContextValue),
      ]}
    >
      <div className={twMerge("relative isolate", grow && "grow")}>
        <div
          className={twMerge(
            "pointer-events-none absolute inset-0 z-20 rounded-lg bg-cr-grey-30/0 opacity-0 transition-all",
            loading && "pointer-events-auto bg-cr-grey-30/40 opacity-100"
          )}
        >
          {loading && <Loading delayShow />}
        </div>
        <div
          className={twMerge(
            "relative z-10 grid h-full w-full overflow-auto",
            "scrollbar-slim max-h-[90vh] rounded-lg border",
            ghost && "ghost group border-transparent",
            !noShadow && !ghost && "cr-shadow",
            noShadow ? "border-cr-blue-light" : "border-cr-blue-super-light",
            className
          )}
          role={"table"}
          style={{
            gridTemplateColumns: orderedColumnsMeta.map(col => col.size ?? "auto").join(" "),
            gridTemplateRows: `repeat(${data.length + 1}, max-content) ${grow ? "auto" : ""}`,
          }}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={pointerWithinX}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            modifiers={[restrictToHorizontalAxis]}
            measuring={measuring}
          >
            {orderedColumnsMeta.map((columnMeta, colIndex) => {
              const isPinned = colIndex <= pinnedColumnIndex
              const orderDirection = orderBy?.column === columnMeta.column ? orderBy.direction : null

              return (
                <HeaderCellWrapper
                  key={columnMeta.column}
                  columnMeta={columnMeta}
                  onOrder={columnMeta.sortFn && handleOrder?.(columnMeta.column)}
                  colIndex={colIndex}
                  isPinned={isPinned}
                  onTogglePin={handleTogglePin}
                  orderDirection={orderDirection}
                />
              )
            })}
            {(() => {
              const columnMeta = getColumnMeta(orderedColumnsMeta, draggingColumn)
              const orderDirection = orderBy && orderBy.column === columnMeta?.column ? orderBy.direction : null

              return (
                <DragOverlayItem
                  columnMeta={getColumnMeta(orderedColumnsMeta, draggingColumn)}
                  orderDirection={orderDirection}
                />
              )
            })()}
            {children
              ? typeof children === "function"
                ? children({data, pinnedColumn})
                : children
              : data.map((row, rowIndex) => (
                  <TableRow key={row.id ?? rowIndex} row={row} rowIndex={rowIndex} pinnedColumn={pinnedColumn} />
                ))}
            {grow && (
              <div className={"contents"} key={"filler"}>
                {orderedColumnsMeta.map((_, index) => (
                  <div style={{gridColumn: index + 1, gridRow: data.length + 2}} key={`filler cell ${index}`} />
                ))}
              </div>
            )}

            <DropMarker columnsMeta={orderedColumnsMeta} />
            <PinMarker columnIndex={getColumnIndex(orderedColumnsMeta, pinnedColumn)} pinnedColumn={pinnedColumn} />
          </DndContext>
        </div>
      </div>
    </CombineProviders>
  )
}

function DragOverlayItem<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  orderDirection,
}: {
  columnMeta?: TColumnMeta<TCol, TRowData>
  orderDirection: NonNullable<TOrderBy<any>>["direction"] | null
}): React.ReactNode {
  const HeaderRenderer = columnMeta?.HeaderCell ?? DefaultHeaderCell

  const hasOrderFn = !!columnMeta?.sortFn

  const dummyOrderFn = React.useMemo(() => {
    return hasOrderFn ? () => undefined : undefined
  }, [hasOrderFn])

  return (
    <FloatingPortal>
      <DragOverlay modifiers={[restrictToParentElement]}>
        {columnMeta && (
          <HeaderRenderer
            columnMeta={columnMeta}
            isDragOverlay
            colIndex={-1}
            onOrder={dummyOrderFn}
            isPinned={false}
            orderDirection={orderDirection}
          />
        )}
      </DragOverlay>
    </FloatingPortal>
  )
}

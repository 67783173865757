import React from "react"
import {FormProvider, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {zodResolver} from "@hookform/resolvers/zod"
import {useQueryClient} from "@tanstack/react-query"
import {isAxiosError} from "axios"
import {z} from "zod"

import Button, {ButtonForm} from "../../../components/Button.tsx"
import {HeroLayout} from "../../../components/HeroLayout.tsx"
import {LayoutCard} from "../../../components/LayoutCard.tsx"
import {Link} from "../../../components/Link.tsx"
import {useVerifyCompanyMutation} from "../../../queries/companies.ts"
import {queryKey} from "../../../services"
import {EGTMEvent, emitGTMEvent} from "../../../utils/gtm.tsx"
import {useParam} from "../../../utils/hooks.tsx"
import {setFormErrorsFromAxios} from "../../../utils/validation.ts"
import {getCreateSellingLink} from "../../CreateSalesCycle/utils.ts"
import {DetailsPage} from "./subpages/DetailsPage.tsx"
import {PasswordPage} from "./subpages/PasswordPage.tsx"
import {EPage, mergedValidationSchema, steps} from "./AccountCreatePage.utils.ts"

export const AccountCreatePage: React.FC = () => {
  const {t} = useTranslation()

  const [page, setPage] = React.useState<EPage>(0)

  const methods = useForm<z.infer<typeof mergedValidationSchema>>({
    mode: "onTouched",
    resolver: zodResolver(mergedValidationSchema),
  })
  const {getValues, setValue, setError} = methods

  const token = useParam("confirmation_token", true)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const verifyCompanyMutation = useVerifyCompanyMutation()

  const handleSubmit = React.useCallback(async () => {
    if (!token) {
      return
    }

    const values = getValues()

    try {
      await verifyCompanyMutation.mutateAsync({
        confirmation_token: token,
        company: {name: values.companyName},
        company_user: {
          password: values.password,
          password_confirmation: values.password,
          phone_number: values.phoneNumber,
          tac_agreement: true,
        },
      })

      emitGTMEvent({
        event: EGTMEvent.COMPANY_SIGNUP_VERIFY,
        company: values.companyName,
        phone: values.phoneNumber,
      })

      await queryClient.refetchQueries({queryKey: queryKey.userSettings})
      navigate(getCreateSellingLink())
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 401) {
        setError("root", {message: t("Signup_Company_AccountCreatePage_NoToken_BadToken")})
        return
      }

      setFormErrorsFromAxios(e, setError)
    }
  }, [getValues, navigate, queryClient, setError, t, token, verifyCompanyMutation])

  const isFirstPage = Number(page) === 0
  const isLastPage = Number(page) === Object.values(steps).length - 1

  const valuesWatch = methods.watch()
  const isStepValid = React.useMemo(() => {
    const step = steps[page]

    return step.validationSchema.safeParse(valuesWatch).success
  }, [valuesWatch, page])

  const goToPreviousPage = React.useCallback(() => {
    setPage(p => p - 1)
  }, [])

  const goToNextPage = React.useCallback(() => {
    const values = getValues()
    const step = steps[page]
    const fields = Object.keys(step.validationSchema.shape) as Array<keyof typeof step.validationSchema.shape>

    for (const field of fields) {
      setValue(field, values[field], {shouldTouch: true, shouldDirty: true, shouldValidate: true})
    }

    if (isStepValid) {
      setPage(p => p + 1)
    }
  }, [getValues, isStepValid, page, setValue])

  if (!token) {
    return (
      <HeroLayout>
        <LayoutCard title={t("Signup_Company_AccountCreatePage_NoToken_Title")}>
          {t("Signup_Company_AccountCreatePage_NoToken_Text")}
        </LayoutCard>
      </HeroLayout>
    )
  }

  return (
    <HeroLayout
      leftAction={
        !isFirstPage && (
          <Link onClick={goToPreviousPage} flipUnderline variant={"neutral"}>
            {t("Signup_Company_AccountCreatePage_PreviousPage")}
          </Link>
        )
      }
    >
      <LayoutCard step={3} total={3} title={t("Signup_Company_AccountCreatePage_Title")}>
        <span>{t("Signup_Company_AccountCreatePage_Text")}</span>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)} className={"flex flex-col gap-2"}>
            {page === EPage.DETAILS && <DetailsPage />}
            {page === EPage.PASSWORD && <PasswordPage />}

            <div className={"flex flex-col gap-4"}>
              {isLastPage ? (
                <ButtonForm fullWidth disabled={!methods.formState.isValid}>
                  {t("Signup_Company_AccountCreatePage_SubmitButton")}
                </ButtonForm>
              ) : (
                <Button fullWidth onClick={goToNextPage} disabled={!isStepValid}>
                  {t("Signup_Company_AccountCreatePage_NextButton")}
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
        <div className={"text-center text-sm"}>
          <Trans i18nKey={"Signup_Login"} components={{loginLink: <Link to={"/log-in"} flipUnderline />}} />
        </div>
      </LayoutCard>
    </HeroLayout>
  )
}

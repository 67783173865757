import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import {Badge, TBadgeColor} from "../../../components/Badge.tsx"
import {ASaasCompanyUserListItem, AUsersListParamsStatusEnum} from "../../../services/types.generated.ts"
import {enumTranslKey} from "../../../utils/i18n.tsx"

const statusToColor = {
  [AUsersListParamsStatusEnum.Active]: "green",
  [AUsersListParamsStatusEnum.Invited]: "yellow",
  [AUsersListParamsStatusEnum.Deactivated]: "red",
} as const satisfies {[status in AUsersListParamsStatusEnum]: TBadgeColor}

export const StatusCell: React.FC<{row: ASaasCompanyUserListItem}> = ({row: {status}}) => {
  const {t} = useTranslation()

  return (
    <Badge color={statusToColor[status]} className={twMerge("py-1 text-xs")}>
      {t(enumTranslKey("SaaSUserStatus", status))}
    </Badge>
  )
}

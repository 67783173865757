import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {ArrowLongLeftIcon, ArrowLongRightIcon} from "@heroicons/react/20/solid"
import {keepPreviousData, QueryFunction, QueryKey, useQuery} from "@tanstack/react-query"
import {twMerge} from "tailwind-merge"

import {AMetaWithPagination} from "../services/types.generated"

export const DEFAULT_PAGE_SIZE = 10

export type TPagination = {
  page: number
  setPage: (fn: (p: number) => number) => void
  pageSize: number
  total: number | undefined
}

export type TPaginationQuery = {
  page: number
  per_page: number
}

// This whole function is hard to read and needs some work
export function usePaginatedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: (
    paginationQueryStringified: string
  ) => Parameters<typeof useQuery<TQueryFnData, TError, TData, TQueryKey>>[0]["queryKey"],
  queryFn: (
    paginationQuery: TPaginationQuery
  ) => ReturnType<NonNullable<QueryFunction<TQueryFnData, TQueryKey, unknown>>>,
  options?: Partial<Parameters<typeof useQuery<TQueryFnData, TError, TData, TQueryKey>>[0]> & {pageSize?: number}
): ReturnType<typeof useQuery<TQueryFnData, TError, TData, TQueryKey>> & {pagination: TPagination} {
  const [page, setPage] = useState<number>(1)
  const pageSize = options?.pageSize ?? DEFAULT_PAGE_SIZE
  const paginationQuery = {page, per_page: pageSize}

  const staticKey = queryKey("").join()
  useEffect(() => setPage(1), [staticKey]) // reset navigation to page 1 on key change

  const query = useQuery({
    queryKey: queryKey(JSON.stringify(paginationQuery)),
    queryFn: () => queryFn(paginationQuery),
    placeholderData: keepPreviousData,
    ...options,
  })

  const apiPagination = ((query.data as any)?.data?.meta as AMetaWithPagination)?.pagination
  const pagination = {page: apiPagination?.current_page ?? page, setPage, pageSize, total: apiPagination?.total_count}

  const currentPage = pagination.page
  const total = pagination.total ?? 0

  React.useEffect(() => {
    setPage(() => 1)
  }, [pageSize, setPage])

  React.useEffect(() => {
    if (currentPage > Math.ceil(total / pageSize)) {
      setPage(() => Math.max(1, Math.ceil(total / pageSize)))
    }
  }, [currentPage, total, setPage, pageSize])

  return {...query, pagination}
}

const PaginationSkeleton = () => (
  <div className={"flex h-12 justify-between border-t border-cr-grey-15 pt-5"}>
    <div className={"h-6 w-24 animate-pulse rounded-full bg-cr-grey-15"}></div>
    <div className={"hidden gap-4 md:flex"}>
      <div className={"h-6 w-6 animate-pulse rounded-full bg-cr-grey-15"}></div>
      <div className={"h-6 w-6 animate-pulse rounded-full bg-cr-grey-15"}></div>
      <div className={"h-6 w-6 animate-pulse rounded-full bg-cr-grey-15"}></div>
      <div className={"h-6 w-6 animate-pulse rounded-full bg-cr-grey-15"}></div>
      <div className={"h-6 w-6 animate-pulse rounded-full bg-cr-grey-15"}></div>
      <div className={"h-6 w-6 animate-pulse rounded-full bg-cr-grey-15"}></div>
      <div className={"h-6 w-6 animate-pulse rounded-full bg-cr-grey-15"}></div>
    </div>
    <div className={"h-6 w-24 animate-pulse rounded-full bg-cr-grey-15"}></div>
  </div>
)

const buildPagesArray = (page: number, pagesTotal: number) => {
  if (pagesTotal <= 7) {
    return Array.from({length: pagesTotal}, (_, i) => i + 1)
  }
  if (page < 3 || page > pagesTotal - 2) {
    return [1, 2, 3, ".", pagesTotal - 2, pagesTotal - 1, pagesTotal]
  }
  if (page === 3) {
    return [1, 2, 3, 4, ".", pagesTotal - 1, pagesTotal]
  }
  if (page === pagesTotal - 2) {
    return [1, 2, ".", pagesTotal - 3, pagesTotal - 2, pagesTotal - 1, pagesTotal]
  }
  if (page === 4) {
    return [1, 2, 3, 4, 5, ".", pagesTotal]
  }
  if (page === pagesTotal - 3) {
    return [1, ".", pagesTotal - 4, pagesTotal - 3, pagesTotal - 2, pagesTotal - 1, pagesTotal]
  }

  return [1, ".", page - 1, page, page + 1, "..", pagesTotal]
}

const Pagination: FC<TPagination & {autoHide?: boolean}> = ({page, setPage, pageSize, total, autoHide}) => {
  const {t} = useTranslation()

  if (total == null) {
    return <PaginationSkeleton />
  }

  const pagesTotal = Math.ceil(total / pageSize)
  const pages = buildPagesArray(page, pagesTotal)

  const hasOnlyOnePage = total <= pageSize

  return (
    <nav
      className={twMerge([
        "@container flex items-center justify-between border-t border-cr-grey-15 px-4 sm:px-0",
        autoHide && hasOnlyOnePage && "hidden",
      ])}
    >
      <div className={"-mt-px flex w-0 flex-1"}>
        {page > 1 && (
          <button
            type={"button"}
            onClick={() => {
              setPage(p => p - 1)
            }}
            className={
              "inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-cr-grey-50 hover:border-cr-grey-15 hover:text-cr-grey-80"
            }
          >
            <ArrowLongLeftIcon className={"mr-3 h-5 w-5"} aria-hidden={"true"} />
            <span className={"hidden @xl:inline"}>{t("T_Previous")}</span>
          </button>
        )}
      </div>
      <div className={"hidden @sm:-mt-px @sm:flex"}>
        {pages.map(p =>
          typeof p === "number" ? (
            <button
              key={p}
              type={"button"}
              onClick={() => setPage(_ => p)}
              className={twMerge([
                "inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium transition-colors",
                p === page
                  ? "border-cr-blue text-cr-blue hover:border-cr-blue-dark hover:text-cr-blue-dark"
                  : "border-transparent text-cr-grey-50 hover:border-cr-grey-15 hover:text-cr-grey-80",
              ])}
            >
              {p}
            </button>
          ) : (
            <span
              key={p}
              className={
                "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-cr-grey-50"
              }
            >
              ...
            </span>
          )
        )}
      </div>
      <div className={"-mt-px flex w-0 flex-1 justify-end"}>
        {page < pagesTotal && (
          <button
            type={"button"}
            onClick={() => setPage(p => p + 1)}
            className={
              "inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-cr-grey-50 hover:border-cr-grey-15 hover:text-cr-grey-80"
            }
          >
            <span className={"hidden @xl:inline"}>{t("T_Next")}</span>
            <ArrowLongRightIcon className={"ml-3 h-5 w-5"} aria-hidden={"true"} />
          </button>
        )}
      </div>
    </nav>
  )
}

export default Pagination

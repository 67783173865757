import React from "react"
import {twMerge} from "tailwind-merge"

import Pin from "../../assets/pin.svg?react"
import Button from "../Button.tsx"
import {TColumnMeta, TColumns} from "./utils/shared.ts"

export function PinButton<TCol extends TColumns, TRowData extends Record<string, any>>({
  columnMeta,
  isPinned,
  onTogglePin,
}: {
  columnMeta: TColumnMeta<TCol, TRowData>
  isPinned: boolean
  onTogglePin: (column: TCol) => void
}): React.ReactNode {
  const handleTogglePin = React.useCallback(() => {
    onTogglePin(columnMeta.column)
  }, [columnMeta.column, onTogglePin])

  return (
    <Button
      onClick={handleTogglePin}
      variant={isPinned ? "light" : "outlined"}
      shape={"circular"}
      color={isPinned ? "blue" : "gray"}
      size={"xs"}
    >
      <Pin className={twMerge("size-4 transition-all", !isPinned && "rotate-45")} />
    </Button>
  )
}

import {skipToken, useMutation, useQuery, useQueryClient} from "@tanstack/react-query"

import {usePaginatedQuery} from "../components/Pagination.tsx"
import {EOrderDirection} from "../components/Table/utils/shared.ts"
import {TColumn} from "../routes/SaaSUserManagement/table/SaaSUserManagementTable.tsx"
import api, {queryKey, saasUserManagementGeneralKey} from "../services"
import {
  AUsersListParamsRoleEnum,
  AUsersListParamsSortByEnum,
  AUsersListParamsSortDirectionEnum,
  AUsersListParamsStatusEnum,
  AUsersTypeEnum,
  AUserTypes,
} from "../services/types.generated.ts"
import {useUserSettingsQuery} from "./user.ts"

export const useSaasUserManagementMetaQuery = () => {
  const userQuery = useUserSettingsQuery()

  return useQuery({
    queryKey: queryKey.saasUserManagementMeta,
    queryFn:
      userQuery.data?.user.type === AUserTypes.SaasCompanyUser
        ? async () => (await api.saasCompanyUsers.usersStatsList()).data.counts
        : skipToken,
  })
}

const columnToApi = (column?: TColumn): AUsersListParamsSortByEnum | undefined => {
  switch (column) {
    case "name":
      return AUsersListParamsSortByEnum.Name
    case "role":
      return AUsersListParamsSortByEnum.Role
    case "joined_at":
      return AUsersListParamsSortByEnum.Joined
    case "status":
      return AUsersListParamsSortByEnum.Status
    default:
      return undefined
  }
}
function directionToApi(direction?: EOrderDirection): AUsersListParamsSortDirectionEnum | undefined {
  switch (direction) {
    case EOrderDirection.ASC:
      return AUsersListParamsSortDirectionEnum.Asc
    case EOrderDirection.DESC:
      return AUsersListParamsSortDirectionEnum.Desc
    case undefined:
      return undefined
  }
}

export const useSaasUserManagementQuery = ({
  pageSize,
  ...filter
}: {
  pageSize: number
  roles: AUsersListParamsRoleEnum[]
  searchString: string | undefined
  statuses: AUsersListParamsStatusEnum[] | undefined
  sort_by?: TColumn
  sort_direction?: EOrderDirection
}) => {
  return usePaginatedQuery(
    queryKey.saasUserManagementData(filter),
    async query => {
      return api.saasCompanyUsers.usersList({
        search: filter.searchString,
        role: filter.roles,
        status: filter.statuses,
        sort_by: columnToApi(filter.sort_by),
        sort_direction: directionToApi(filter.sort_direction),
        ...query,
      })
    },
    {pageSize}
  )
}

export const useInviteUsersMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({emails, role}: {emails: string[]; role: AUsersTypeEnum}) => {
      return api.invitations.invitationsCreate({users: emails.map(email => ({email, type: role}))})
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementGeneralKey,
      })
    },
  })
}

export const useDeactivateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({id}: {id: number}) => {
      return api.saasCompanyUsers.usersDeactivateCreate(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementGeneralKey,
      })
    },
  })
}

export const useReactivateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({id}: {id: number}) => {
      return api.saasCompanyUsers.usersActivateCreate(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementGeneralKey,
      })
    },
  })
}

export const useResendInvitationMutation = () => {
  return useMutation({
    mutationFn: ({id}: {id: number}) => {
      return api.invitations.resendCreate(id)
    },
  })
}

export const useCancelInvitationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({id}: {id: number}) => {
      return api.invitations.cancelCreate(id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: saasUserManagementGeneralKey,
      })
    },
  })
}

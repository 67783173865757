import React from "react"
import {useNavigate} from "react-router"

import {useProspectsSalesCycleQuery} from "../../../queries/prospects.ts"
import {AAdminProspect, ACompanyUserProspect, ASaasCompanyUserProspect} from "../../../services/types.generated"
import {createSimpleContext, createStateContext} from "../../../utils/context.tsx"
import {useNumParam} from "../../../utils/hooks.tsx"
import {getActiveAssignment} from "./utils.ts"

export type TRejectProspect = AAdminProspect | ACompanyUserProspect

export type TProspectContext = {
  prospects: TRejectProspect[] | null
  setProspects: (prospect: TRejectProspect[] | null) => void
  rejectionsLeft: number | null
}

export const RejectContext = createSimpleContext<TProspectContext>("reject")

export const WarningsOnlyContext = createStateContext<boolean>("warnings only")

type TProspectsContextIds = {salesCycleId: number; iterationId: number | undefined; assignmentId: number | undefined}
type TProspectsContextValues = {
  salesCycle: ReturnType<typeof useProspectsSalesCycleQuery>["data"]
  iteration:
    | NonNullable<ReturnType<typeof useProspectsSalesCycleQuery>["data"]>["sales_cycle_iterations"][number]
    | undefined
  assignment:
    | NonNullable<
        ReturnType<typeof useProspectsSalesCycleQuery>["data"]
      >["sales_cycle_iterations"][number]["assignments"][number]
    | undefined
}

export const useProspectsContextValue = (
  salesCycle: NonNullable<TProspectsContextValues["salesCycle"]>,
  shouldAutoPick = false
): TProspectsContextIds &
  TProspectsContextValues & {
    setValue: (value: TProspectsContextIds) => void
  } => {
  const navigate = useNavigate()

  const iterationIdParam = useNumParam("iterationId", true)
  const assignmentIdParam = useNumParam("assignmentId", true)

  const iteration = salesCycle.sales_cycle_iterations.find(i => i.id === iterationIdParam)
  const assignment = iteration?.assignments.find(a => a.id === assignmentIdParam)

  const redirect = React.useCallback(
    (to: TProspectsContextIds) => {
      const url = new URL(document.location.origin)
      to.iterationId != null && url.searchParams.set("iterationId", to.iterationId.toString())
      to.assignmentId != null && url.searchParams.set("assignmentId", to.assignmentId.toString())
      const navigateUrl = document.location.pathname + url.search

      navigate(navigateUrl)
    },
    [navigate]
  )

  React.useEffect(() => {
    if (!shouldAutoPick || (iteration && assignment)) {
      return
    }

    const newIteration = iteration ?? salesCycle.sales_cycle_iterations.at(0)

    redirect({
      salesCycleId: salesCycle.id,
      iterationId: newIteration?.id,
      assignmentId: newIteration && getActiveAssignment(newIteration, assignment)?.id,
    })
  }, [assignment, iteration, salesCycle.id, salesCycle.sales_cycle_iterations, redirect, shouldAutoPick])

  return React.useMemo(
    () => ({
      salesCycleId: salesCycle.id,
      iterationId: iteration?.id,
      assignmentId: assignment?.id,
      salesCycle,
      iteration,
      assignment,
      setValue: redirect,
    }),
    [assignment, iteration, redirect, salesCycle]
  )
}

export const ProspectsContext = createSimpleContext<ReturnType<typeof useProspectsContextValue>>("prospects")

export type TDeletingProspect = AAdminProspect | ASaasCompanyUserProspect

export const DeletingContext = createStateContext<TDeletingProspect | number[] | null>("deleting")

export type TEditingProspect = AAdminProspect | ASaasCompanyUserProspect

export const EditingContext = createStateContext<TEditingProspect | null>("editing")
export const WarningsContext = createStateContext<{[key in keyof TEditingProspect]?: string}>("warnings")
export const IsUploadingContext = createStateContext<boolean>("isUploading")

import React from "react"
import {useTranslation} from "react-i18next"
import {twMerge} from "tailwind-merge"

import callImg from "../../../assets/renata-book-a-call.jpg"
import {GenericErrorAlert} from "../../../components/Alert.tsx"
import {Badge} from "../../../components/Badge.tsx"
import {ButtonLink} from "../../../components/Button.tsx"
import {LayoutBlock} from "../../../components/Layout/LayoutBlock.tsx"
import {Loading} from "../../../components/Loading.tsx"
import {ProfileImage} from "../../../components/ProfileImage.tsx"
import {assignmentToSalesperson, Salespeople} from "../../../components/Salespeople.tsx"
import {SalespeopleShowcase} from "../../../components/SalespeopleShowcase.tsx"
import {useCompanyWelcomeScreenQuery} from "../../../queries/companies.ts"
import {useUserSettingsOrLogout} from "../../../queries/user.ts"
import {AOnboardingStatuses} from "../../../services/types.generated.ts"
import {getCreateSellingLink} from "../../CreateSalesCycle/utils.ts"
import {stepWeb} from "../../SalesStrategy/stepsConfig.ts"
import {EOnboardingStepNum, getStepNum, getStepText, stepDefinitions, stepsTotal} from "./utils.ts"

export const WelcomeScreen: React.FC = () => {
  const {t} = useTranslation()

  const {user} = useUserSettingsOrLogout()

  const {data, error, refetch} = useCompanyWelcomeScreenQuery(user.id)

  const currentStep = getStepNum(data?.onboarding_status)

  return (
    <LayoutBlock outerClassName={"py-16 grow bg-cr-blue-super-light"} innerClassName={"flex flex-col gap-10"}>
      <h1 className={"text-3xl font-bold text-cr-blue"}>{t("WelcomeScreen_Company_Title")}</h1>

      {error ? (
        <GenericErrorAlert retry={refetch} />
      ) : data ? (
        <div className={"grid-cols-auto grid-rows-auto grid gap-4 md:grid-cols-[auto__auto]"}>
          <div className={"flex justify-between font-bold"}>
            <h2>{t("WelcomeScreen_Company_Steps_Title")}</h2>
            <div data-testid={"step-count"}>
              {currentStep}/{stepsTotal}
            </div>
          </div>

          <Step
            step={AOnboardingStatuses.RequestSalesTalents}
            active={currentStep === EOnboardingStepNum.SALES_PEOPLE}
            finished={currentStep > EOnboardingStepNum.SALES_PEOPLE}
            inaccessible={currentStep > EOnboardingStepNum.SALES_PEOPLE}
            action={
              currentStep === EOnboardingStepNum.SALES_PEOPLE ? (
                <ButtonLink size={"xs"} to={getCreateSellingLink({salesCycleId: data.sales_cycle?.id})}>
                  {t("WelcomeScreen_Company_Steps_1_Button")}
                </ButtonLink>
              ) : undefined
            }
            rightContent={
              currentStep > EOnboardingStepNum.SALES_PEOPLE ? (
                <Salespeople
                  salesPeople={data.requested_sales_person_assignments?.map(assignmentToSalesperson) ?? []}
                  showNames
                  max={5}
                />
              ) : undefined
            }
          />
          <Step
            step={AOnboardingStatuses.AddKeyDetails}
            active={currentStep === EOnboardingStepNum.KEY_DETAILS}
            finished={currentStep > EOnboardingStepNum.KEY_DETAILS}
            time={3}
            action={
              <ButtonLink
                size={"xs"}
                to={`/sales-form/${data.sales_cycle?.id}`}
                disabled={currentStep === EOnboardingStepNum.SALES_PEOPLE}
              >
                {data.sales_cycle?.key_details_finalized
                  ? t("WelcomeScreen_Company_Steps_2_Button_Edit")
                  : t("WelcomeScreen_Company_Steps_2_Button_Create")}
              </ButtonLink>
            }
          />
          <Step
            step={AOnboardingStatuses.ShareKnowledge}
            active={currentStep === EOnboardingStepNum.KNOWLEDGE}
            finished={currentStep > EOnboardingStepNum.KNOWLEDGE}
            time={7}
            action={
              <ButtonLink
                size={"xs"}
                to={`/sales-strategy/${data.sales_cycle?.id}/${stepWeb.href}`}
                disabled={!data.sales_cycle?.key_details_finalized}
              >
                {data.sales_cycle?.strategy_touched
                  ? t("WelcomeScreen_Company_Steps_3_Button_Edit")
                  : t("WelcomeScreen_Company_Steps_3_Button_Create")}
              </ButtonLink>
            }
          />

          <div className={"flex flex-col gap-2 card-shadow p-6 md:col-start-2 md:row-[2/5]"}>
            <h2 className={"font-bold"}>{t("WelcomeScreen_Company_Call_Title")}</h2>
            <div>{t("WelcomeScreen_Company_Call_Subtitle")}</div>
            <div className={"flex grow items-center justify-center py-6"}>
              <div className={"relative"}>
                <Badge
                  color={"custom"}
                  className={"absolute top-4 left-4 bg-cr-blue-dark py-1.5 text-xs text-cr-white"}
                >
                  {t("WelcomeScreen_Company_Call_Badge")}
                </Badge>
                <ProfileImage src={callImg} rectangular className={"w-full max-w-96 rounded-lg"} />
              </div>
            </div>
            <ButtonLink to={t("WelcomeScreen_Company_Call_Url")} fullWidth target={"_blank"} variant={"outlined"}>
              {t("WelcomeScreen_Company_Call_Button")}
            </ButtonLink>
          </div>
        </div>
      ) : (
        <Loading size={"xl"} fullSpace />
      )}

      <SalespeopleShowcase salesPeople={data?.sales_people} salesCycleId={data?.sales_cycle?.id} />
    </LayoutBlock>
  )
}

export const Step: React.FC<{
  step: AOnboardingStatuses
  active: boolean
  finished: boolean
  inaccessible?: boolean
  time?: number
  action?: React.ReactNode
  rightContent?: React.ReactNode
}> = ({step, active, finished, inaccessible, action, rightContent}) => {
  const {t} = useTranslation()
  const {time} = stepDefinitions[step]

  return (
    <div
      data-testid={"step"}
      className={twMerge("col-start-1 flex gap-4 card-shadow p-6", inaccessible && "opacity-50")}
    >
      <div className={"flex grow flex-col gap-2"}>
        <div className={"flex items-baseline justify-between gap-4"}>
          <StepTitle active={active} finished={finished} step={step} />

          <div className={"flex items-baseline gap-2"}>
            {time && (
              <div className={"text-sm font-semibold whitespace-nowrap"}>
                {t("WelcomeScreen_Company_Steps_Time", {time})}
              </div>
            )}
            {action}
          </div>
        </div>
        <div>{getStepText(step, finished)}</div>
      </div>

      {rightContent}
    </div>
  )
}

export const StepTitle: React.FC<{active: boolean; finished: boolean; step: AOnboardingStatuses}> = ({
  active,
  finished,
  step,
}) => {
  const {t} = useTranslation()

  const {title} = stepDefinitions[step]

  return (
    <div className={"flex flex-wrap items-baseline gap-2"}>
      <div
        className={twMerge(
          "flex size-6 shrink-0 items-center justify-center rounded-full border border-cr-blue text-xs font-bold",
          finished || active ? "bg-cr-blue text-cr-white" : "bg-cr-white text-cr-blue"
        )}
      >
        {getStepNum(step)}
      </div>

      <h3 className={"font-bold whitespace-nowrap"}>{title}</h3>

      {active && (
        <div className={"ml-4 flex items-center gap-2 text-sm whitespace-nowrap text-cr-blue"}>
          <div className={"size-2 shrink-0 rounded-full bg-cr-blue"} />
          <div>{t("WelcomeScreen_Company_Steps_CurrentStep")}</div>
        </div>
      )}
    </div>
  )
}

import React from "react"
import {twMerge} from "tailwind-merge"

export const LayoutTitle: React.FC<{header: React.ReactNode; subheader: React.ReactNode; light?: boolean}> = ({
  header,
  subheader,
  light,
}) => {
  return (
    <div
      className={twMerge([
        "flex justify-center px-10 py-14",
        light ? "bg-cr-white text-cr-blue" : "bg-cr-blue text-cr-white",
      ])}
    >
      <div className={"flex max-w-(--breakpoint-2xl) grow flex-col gap-6"}>
        <h1 className={"text-5xl font-bold"}>{header}</h1>
        <h2 className={twMerge([light && "text-cr-black"])}>{subheader}</h2>
      </div>
    </div>
  )
}

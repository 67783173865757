import React from "react"
import {faker} from "@faker-js/faker"
import {ClockIcon} from "@heroicons/react/24/outline"
import {twMerge} from "tailwind-merge"

import {useUserSettingsOrLogout} from "../queries/user.ts"
import {AAssignmentStatuses, AUserTypes} from "../services/types.generated.ts"
import {Link} from "./Link"
import {ProfileImage} from "./ProfileImage.tsx"

export type TSalespeopleSalesperson = {status: AAssignmentStatuses} & (
  | {
      type: "salesperson"
      id: number
      pictureUrl?: string
      firstName?: string
    }
  | {
      type: "placeholder"
      reaches?: number
    }
)

export const assignmentToSalesperson = (assignment: {
  status?: string
  reaches: number
  sales_person?: {id?: number; profile_picture_thumbnail_url?: string | null; first_name?: string} | null
}): TSalespeopleSalesperson => {
  return assignment.sales_person
    ? {
        type: "salesperson",
        status: (assignment.status as AAssignmentStatuses) ?? AAssignmentStatuses.Pending,
        id: assignment.sales_person.id ?? faker.number.int(),
        pictureUrl: assignment.sales_person.profile_picture_thumbnail_url ?? undefined,
        firstName: assignment.sales_person.first_name,
      }
    : {
        type: "placeholder",
        status: (assignment.status as AAssignmentStatuses) ?? AAssignmentStatuses.Pending,
        reaches: assignment.reaches,
      }
}

export const Salespeople: React.FC<{
  salesPeople: TSalespeopleSalesperson[]
  max?: number
  small?: boolean
  showReaches?: boolean
  showNames?: boolean
}> = ({salesPeople, max = Infinity, showReaches, showNames, small}) => {
  const shouldTruncate = salesPeople.length > max

  const salesPeopleVisible = React.useMemo(() => {
    return salesPeople.slice(0, shouldTruncate ? max - 1 : max)
  }, [max, salesPeople, shouldTruncate])

  return (
    <div className={"isolate flex -space-x-1"}>
      {salesPeopleVisible.map((salesPerson, index) => {
        return (
          <div
            className={"hover:z-10"}
            key={salesPerson.type === "salesperson" ? salesPerson.id : `placeholder-${index}`}
          >
            <Salesperson salesPerson={salesPerson} showReaches={showReaches} small={small} showName={showNames} />
          </div>
        )
      })}
      {shouldTruncate && (
        <div
          className={twMerge([
            "flex items-center justify-center",
            "relative cursor-default rounded-full bg-cr-grey-5 font-semibold text-cr-grey-50 ring-cr-white",
            small ? "size-8 ring-2" : "size-12 ring-4",
          ])}
        >
          +{salesPeople.length - salesPeopleVisible.length}
        </div>
      )}
    </div>
  )
}

export const Salesperson: React.FC<{
  salesPerson: TSalespeopleSalesperson
  small?: boolean
  showReaches?: boolean
  showName?: boolean
}> = ({salesPerson, showReaches, small, showName}) => {
  const {user} = useUserSettingsOrLogout()
  const isPlaceholder = salesPerson.type === "placeholder"

  return (
    <div
      className={twMerge(
        "group relative flex flex-col items-center gap-1",
        salesPerson.status === AAssignmentStatuses.Canceled && "contrast-50 grayscale"
      )}
    >
      {[AAssignmentStatuses.Pending, AAssignmentStatuses.PreMatched].includes(salesPerson.status) && (
        <div
          className={twMerge(
            "absolute -top-1 -right-1 flex items-center justify-center rounded-full bg-cr-yellow-light",
            small ? "size-4" : "size-5"
          )}
          data-testid={"pending-icon"}
        >
          <ClockIcon className={"size-3 text-cr-yellow"} />
        </div>
      )}
      <Link
        className={twMerge(
          "overflow-hidden rounded-full no-underline ring-cr-white transition-all",
          small ? "size-8 ring-2 hover:ring-4" : "size-12 ring-4 hover:ring-6"
        )}
        to={isPlaceholder || user.type !== AUserTypes.Admin ? undefined : `/salesperson/${salesPerson.id}`}
        target={"_blank"}
      >
        {!isPlaceholder || !showReaches || salesPerson.reaches == null ? (
          <ProfileImage
            src={isPlaceholder ? undefined : salesPerson.pictureUrl}
            className={twMerge("size-full", isPlaceholder && "bg-cr-blue-mid text-cr-white")}
          />
        ) : (
          <div
            className={
              "flex h-full w-full cursor-default items-center justify-center bg-cr-blue-super-light font-semibold text-cr-blue"
            }
          >
            {salesPerson.reaches}
          </div>
        )}
      </Link>
      {!isPlaceholder && showName && (
        <div
          className={twMerge(
            "overflow-hidden text-center text-xs font-bold font-semibold text-ellipsis text-cr-grey-50 transition-all group-hover:text-cr-black",
            small ? "w-8" : "w-12"
          )}
          title={salesPerson.firstName}
        >
          {salesPerson.firstName}
        </div>
      )}
    </div>
  )
}

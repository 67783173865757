import {useNavigate} from "react-router"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {isAxiosError} from "axios"

import api, {queryKey} from "../services"
import requestError from "../services/requestError.tsx"
import {ASettings, Users} from "../services/types.generated.ts"
import {getLSItem, setLSItem} from "../utils/localStorage"
import {tabId} from "../utils/tabId.ts"

export function useUserSettingsQuery() {
  return useQuery({
    queryKey: queryKey.userSettings,
    queryFn: fetchUserSettings,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    throwOnError: false,
    retry: (i, error) => {
      if (isAxiosError(error) && error.response?.status === 401) {
        return false
      }
      return i < 3
    },
  })
}

export function useCountriesQuery() {
  return useQuery({
    queryKey: queryKey.countries,
    queryFn: async () => (await api.countries.countriesList()).data.countries,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retryOnMount: false,
    throwOnError: false,
  })
}

export function useUserSettingsOrLogout() {
  const queryClient = useQueryClient()

  const settings = queryClient.getQueryData<ASettings>(queryKey.userSettings)

  if (!settings) {
    window.location.replace("/log-in")
    throw new Error("User is not logged in")
  }
  return settings
}

export async function fetchUserSettings() {
  const settings = (await api.settings.settingsList()).data.settings

  if (settings && settings.user.id !== getLSItem("currentUserId")?.data.userId) {
    setLSItem("currentUserId", {userId: settings.user.id, tabId})
  }

  return settings
}

export type TLogInValues = NonNullable<Users.SignInCreate.RequestBody["user"]>

export function useLoginMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (user: TLogInValues) => {
      await api.users.signInCreate({user})
      await queryClient.invalidateQueries()
      await queryClient.prefetchQuery({queryKey: queryKey.userSettings, queryFn: fetchUserSettings})
    },
  })
}

export type TPasswordResetRequestValues = NonNullable<Users.PasswordCreate.RequestBody["user"]>

export function usePasswordResetRequestMutation() {
  return useMutation({
    mutationFn: (user: TPasswordResetRequestValues) => api.users.passwordCreate({user}),
  })
}

export type TNewPasswordValues = NonNullable<Users.PasswordUpdate.RequestBody["user"]>

export function useSetNewPasswordMutation() {
  return useMutation({
    mutationFn: (user: TNewPasswordValues) => api.users.passwordUpdate({user}),
  })
}

export function useLogoutMutation() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: () => api.users.signOutDelete(),
    mutationKey: queryKey.logout,
    onSuccess: async () => {
      await queryClient.resetQueries()

      if (getLSItem("currentUserId")?.data?.userId !== null) {
        setLSItem("currentUserId", {userId: null, tabId})
      }

      navigate("/log-in")
    },
    onError: e => {
      requestError(e)
    },
  })
}

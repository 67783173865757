import React from "react"
import {twMerge} from "tailwind-merge"

import {StepCounter} from "./StepCounter.tsx"

export const LayoutCard: React.FC<{
  title?: React.ReactNode
  className?: string
  children: React.ReactNode
  step?: number
  total?: number
}> = ({title, className, step, total, children}) => {
  return (
    <div className={"flex flex-col items-center gap-6"}>
      {step != null && total != null && <StepCounter total={total} step={step} />}

      <div className={twMerge("flex max-w-[470px] flex-col gap-8 card-shadow p-10", className)}>
        <h1 className={"text-3xl font-bold text-cr-blue"}>{title}</h1>

        {children}
      </div>
    </div>
  )
}

import {ClientRect, CollisionDescriptor, CollisionDetection} from "@dnd-kit/core"
import {Coordinates} from "@dnd-kit/utilities"

// --------------------------
// This is @dnd-kit's `pointerWithin` with slight changes to only account for the Y axis.
// --------------------------

/**
 * Returns the rectangles that the pointer is hovering over
 */
export const pointerWithinX: CollisionDetection = ({droppableContainers, droppableRects, pointerCoordinates}) => {
  if (!pointerCoordinates) {
    return []
  }

  const collisions: CollisionDescriptor[] = []

  for (const droppableContainer of droppableContainers) {
    const {id} = droppableContainer
    const rect = droppableRects.get(id)

    if (rect && isPointWithinX(pointerCoordinates, rect)) {
      collisions.push({
        id,
        data: {droppableContainer, value: droppableContainer.data.current?.isPinned ? 0 : 10},
      })
    }
  }

  return collisions.toSorted((A, B) => A.data.value - B.data.value)
}

/**
 * Check if a given point is contained within a bounding rectangle
 */
function isPointWithinX(point: Coordinates, rect: ClientRect): boolean {
  const {left, right} = rect

  return left <= point.x && point.x <= right
}
